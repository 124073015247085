import moment from 'moment';
import { useState, useEffect } from 'react'
import { Button, Collapse, Spin } from 'antd'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { PageTypography } from '../../components/page.typography'
import { CardNav } from '../../components/card.navigation'
import { State, Fetch } from '../../../interfaces'
import { BillsAction } from '../actions';
import { tofixed } from './../../../utils';
import { GiBToGB, MbpsToMBs } from './../../../helpers/capacity.helper'
import { BillStatus, BillsType } from './../enums'
import { GET_BILLS_INFO } from './../constants/fetch.constants'
import { AccountMoreIcon } from './../../components/icons.component'
import './new.bill.detail.container.less'
import './new.order.title.less';

const { Panel } = Collapse;
export const NewBillDetailContainer = () => {
    const history = useHistory();
    const { id } = useParams<Record<string, string>>();
    const dispatch = useDispatch();
    // 是否出账
    const [isAccount, setIsAccount] = useState<boolean>(false);
    // 账单状态
    const { email } = useSelector<State, any>(state => state.session);
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_BILLS_INFO] ?? {} as any);
    const payment_bills_info = useSelector<State, any>(state => state.bills.payment_bills_info ?? {} as any);

    //初始化函数
    const refresh = () => {
        dispatch(BillsAction.getPayBillsInfo(id));
    }
    // 设置小三角旋转
    const setRotate = (isActive: any) => {
        return { transform: isActive ? 'rotate(0deg)' : 'rotate(-90deg)' }
    }
    useEffect(() => {
        refresh()
    }, [])
    useEffect(() => {
        if (Object.getOwnPropertyNames(payment_bills_info).length) {
            setIsAccount(payment_bills_info.status === 'cleared') // 判断是否出账
        }
    }, [payment_bills_info])
    return (
        <div className="bill-detail-container">
            <PageTypography title='费用'>您可在此页面查看您的账单明细。</PageTypography>
            {/* 账单详情 */}
            <CardNav title='账单详情' link='/console/account/bill'>
                <Spin spinning={fetching}>
                    {Object.getOwnPropertyNames(payment_bills_info).length ?
                        <>
                            {/* 基本信息 */}
                            <div className="info-title bill-title">
                                <div className="info-title-item">
                                    <span>出账日期：</span>
                                    <b>{moment(payment_bills_info.createdAt).format('YYYY-MM-DD')}</b>
                                </div>
                                <div className="info-title-item">
                                    {isAccount ?
                                        <span>还款日期：</span>
                                        :
                                        <span className='width98'>最晚还款日期：</span>
                                    }
                                    <b>{
                                        isAccount ?
                                            moment(payment_bills_info.paidAt).format('YYYY-MM-DD')
                                            :
                                            moment(payment_bills_info.billingCycle).add(1, 'M').add(14, 'd').format('YYYY-MM-DD')
                                    }</b>
                                </div>

                                <div className="info-title-item">
                                    <span>状态：</span>
                                    <b>{BillStatus[payment_bills_info.status]}</b>
                                </div>
                                <div className="info-title-item">
                                    <span>账单：</span>
                                    <b>{payment_bills_info.billingCycle}</b>
                                </div>
                                <div className="info-title-item">
                                    <span className={!isAccount ? 'width98' : ''}>账号：</span>
                                    <b>{email}</b>
                                </div>
                            </div>
                            {/* 文件系统 */}
                            <div className='bill-info-collapse'>
                                <Collapse ghost expandIcon={({ isActive }) => <AccountMoreIcon style={setRotate(isActive)} />} defaultActiveKey={['sys']} >
                                    <Panel header='文件系统' key='sys' >
                                        <Collapse ghost expandIcon={({ isActive }) => <AccountMoreIcon style={setRotate(isActive)} />} defaultActiveKey={[0]}>
                                            {payment_bills_info?.details?.map((item, index) => (
                                                <Panel header={
                                                    <div className='bill-info-header'>
                                                        <span>{item.name}</span>
                                                        <span></span>
                                                        <span>
                                                            <b>¥{tofixed(parseFloat(item.size.originalAmount) - parseFloat(item.size.roundAmount) + parseFloat(item.bandwidth.originalAmount) - parseFloat(item.bandwidth.roundAmount))}</b>
                                                            <Link to={`/console/account/dosage/${item.instanceId}/${payment_bills_info.billingCycle}/${item.name}`}>查看用量详情</Link>
                                                           
                                                            {/* <Link to={{pathname:`/console/account/dosage`,search:`?id=${item.instanceId}&billingCycle=${payment_bills_info.billingCycle}&name=${item.name}`}}>查看用量详情</Link> */}
                                                        </span>
                                                    </div>} key={index} className="bill-info-panel">
                                                    <ul className="bill-info-ul">
                                                        <li>{BillsType['size']}</li>
                                                        {/* <li>产品单价：0.48GB/月</li> */}
                                                        <li>产品单价：¥{GiBToGB(item.size.price, '元/GiB/小时', '月')}</li>
                                                        <li>¥{tofixed(parseFloat(item.size.originalAmount) - parseFloat(item.size.roundAmount))}</li>
                                                    </ul>
                                                    {(parseFloat(item.bandwidth.originalAmount) - parseFloat(item.bandwidth.roundAmount)) > 0 ?
                                                        <ul className="bill-info-ul">
                                                            <li>{BillsType['bandwidth']}</li>
                                                            {/* <li>产品单价：0.48GB/月</li> */}
                                                            <li>产品单价：¥{`${MbpsToMBs(item.bandwidth.price, '元/Mbps/小时')}`}</li>
                                                            <li>¥{tofixed(parseFloat(item.bandwidth.originalAmount) - parseFloat(item.bandwidth.roundAmount))}</li>
                                                        </ul>
                                                        :
                                                        null
                                                    }
                                                </Panel>
                                            ))}
                                        </Collapse>
                                    </Panel>
                                </Collapse>
                            </div>

                            <div className='footer-pay'>
                                <div className="footer-pay-price">
                                    <span>消费金额：<b>{tofixed(parseFloat(payment_bills_info.originalAmount) - parseFloat(payment_bills_info.roundAmount))}元</b></span>
                                    {
                                        isAccount ?
                                            <>
                                                <span>代金券抵扣金额：<b>{tofixed(payment_bills_info.voucherAmount)}元</b></span>
                                                <span>实际支付金额：<b>{tofixed(payment_bills_info.amountDue)}元</b></span>
                                            </>
                                            :
                                            <span>欠款金额：<b>{tofixed(payment_bills_info.amountDue)}元</b></span>
                                    }
                                </div>
                                {
                                    isAccount ?
                                        <Button className='receipt-button' onClick={() => history.push('/console/account/invoice')}>开票</Button>
                                        :
                                        <Button type="primary" onClick={() => history.push(`/console/account/payment/${id}`)}>去付款</Button>
                                }
                            </div>
                        </>
                        :
                        null
                    }

                </Spin>
            </CardNav>
        </div>
    )
}