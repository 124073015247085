import React, { useEffect } from 'react';
import { Menu, Layout } from 'antd';
import { PageTypography } from './../../components/page.typography';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CommonLayout } from '../../components/common-layout.component';
import './document.container.less';
import { DocumentAction } from '../actions/document.action';

export const DocumentContainer = (props) => {
    const key = props?.match?.params?.key;
    const dispatch = useDispatch();
    const { content } = useSelector<any, any>(state => state.document.document);
    const menus = useSelector<any, any>(state => state.document.menus);

    useEffect(() => {
        dispatch(DocumentAction.getDocument(key));
    }, [key]);

    return <CommonLayout>
        <div className="document-container">
            <PageTypography title='指南'>指南是指使用本网站的所有介绍，包含并不限于产品介绍，使用场景，操作步骤，费用等。</PageTypography>
            <Layout className="document-layout">
                <Layout.Sider className="document-sider" theme="light">
                    <Menu
                        selectedKeys={[key]}
                        selectable={key && false}
                        className="document-menus"
                        id="menus"
                    >
                        {menus.map(menu => <Menu.Item
                            key={menu.key}
                            className={menu.hasContent ? 'clickable' : 'unclickable'}
                            style={{ marginTop: menu.tab === 'h1' ? 24 : 4 }}
                        >
                            {menu.hasContent ?
                                <Link
                                    to={`/console/documents/document/${menu.key}`}
                                    style={{ fontWeight: menu.tab === 'h1' ? 800 : 400 }}
                                >
                                    {menu.title?.replace(/(·)/g, '')}
                                </Link> :
                                <span style={{ fontWeight: menu.tab === 'h1' ? 800 : 400, fontSize: 16 }}>
                                    {menu.title?.replace(/(·)/g, '')}
                                </span>
                            }
                        </Menu.Item>)}
                    </Menu>
                </Layout.Sider>
                <Layout.Content className="document-content">
                    <div dangerouslySetInnerHTML={{ __html: content?.replace(/(&middot;)/g, '') }} />
                </Layout.Content>
            </Layout>
        </div>
    </CommonLayout>
}
