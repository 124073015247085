import { Dispatch } from 'redux';
import { filterQuery } from '../../../helpers/query.helper';
import { Action, Client, PaginationAction } from '../../../redux';
import { TICKET } from '../constants/action-type.constants';
import { CREATE_TICKET, REPLY_TICKET, CLOSE_TICKET, GET_TICKETS } from '../constants/fetch.constants';

export class TicketAction {
    private static client = new Client()

    public static getTickets(params: any) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { page, size, list, total } = await this.client.get('/apis/tickets', {
                params: filterQuery(params),
                dispatch,
                fetch: GET_TICKETS,
            });
            dispatch(new Action(TICKET).put('tickets', list));
            dispatch(PaginationAction.update('ticket', page, size, total, list.length));
        }
    }

    public static getTicket(ticketId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { ticket } = await this.client.get(`/apis/tickets/${ticketId}`);
            dispatch(new Action(TICKET).put('ticket', ticket));
            window.scrollTo(0, 99999);
        }
    }

    public static createTicket(params, callback) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { list, total, page, size } = state().pagination.ticket;
            await this.client.post('/apis/tickets', {
                ...params,
                smsNotificationType: 1,
                priority: 1,
            }, {
                fetch: CREATE_TICKET,
                dispatch,
            });
            dispatch(this.getTickets({page, size}));
            callback();
        }
    }

    public static closeTicket(ticketId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/apis/tickets/${ticketId}/actions/close`, {}, {
                fetch: CLOSE_TICKET,
                dispatch,
            });
            dispatch(this.getTicket(ticketId));
        }
    }

    public static getCategories() {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { categories } = await this.client.get('/apis/ticket-categories');
            dispatch(new Action(TICKET).put('categories', categories));
        }
    }

    public static getTemplates() {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { templates } = await this.client.get('/apis/ticket-templates');
            const temp = {};
            const options = [] as any[];
            templates.forEach((item) => {
                if (!temp[item.category.name]) {
                    temp[item.category.name] = { value: item.category.id, children: [] };
                }
                temp[item.category.name].children.push({ label: item.name, value: item.id, fields: item.fields });
            })
            Object.keys(temp).forEach((key) => {
                options.push({ label: key, value: temp[key].value, children: temp[key].children });
            });
            dispatch(new Action(TICKET).put('templates', options));
        }
    }

    public static replyTicket(ticketId: string, values: any) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/apis/tickets/${ticketId}/actions/reply`, {
                ...values,
            }, {
                fetch: REPLY_TICKET,
                dispatch,
            });
            dispatch(this.getTicket(ticketId));
        }
    }
}
