import { Form, Input, Popconfirm, Button, Image, Space } from 'antd';
import { PageTypography } from './../../components/page.typography';
import { CardNav } from './../../components/card.navigation'
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import moment from 'moment';
import { TicketAction } from '../actions/ticket.action';
import './ticket-detail.container.less';
import { AdminIcon, DefaultAvatarIcon } from '../../components/icons.component';

export const TicketDetailContainer = (props) => {
    const ticketId = props.match?.params?.ticketId;
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const ticket = useSelector<any, any>(
        state => state.ticket.ticket ?? {} as any,
    );

    useMount(() => {
        dispatch(TicketAction.getTicket(ticketId));
    });

    const submit = (values) => {
        dispatch(TicketAction.replyTicket(ticketId, values));
        form.resetFields();
    };

    return <div className="ticket-container">
        <PageTypography title='工单'>您可以通过工单系统来询问任何问题，我们的工作人员会第一时间为您解决问题。</PageTypography>
        <CardNav title='工单详情' link='/console/tickets'>
            <div className="ticket-chat">
                <Space>
                    {ticket?.status === 'closed' ? <span style={{ fontSize: 14, fontWeight: 400 }}>工单关闭</span> : <Popconfirm
                        onConfirm={() => dispatch(TicketAction.closeTicket(ticketId))}
                        title="确认关闭工单吗？"
                    >
                        <Button className="close-btn">关闭工单</Button>
                    </Popconfirm>}
                </Space>
                <ul className="replies">
                    {
                        <li className="item user-item">
                            <div className="info">
                                <span className="username">我</span>
                                <span className="reply-time">
                                    {moment(ticket?.createdAt).format('YYYY-MM-DD HH:mm')}
                                </span>
                                <DefaultAvatarIcon />
                            </div>
                            <div className="content">
                                <p>{ticket?.content}</p>
                                <Space size={8}>
                                    {
                                        ticket?.attachments?.map((item) =>
                                            <Image
                                                key={item.id}
                                                width={100}
                                                src={`/apis/attachments/${item.url}`}
                                                alt={item.id}
                                            />
                                        )
                                    }
                                </Space>
                            </div>
                        </li>
                    }
                    {
                        <li className="item resolver-item">
                            <div className="info">
                                <AdminIcon className="admin-head" />
                                <span className="username">管理员</span>
                                <span className="reply-time">
                                    {moment(ticket?.createdAt).format('YYYY-MM-DD HH:mm')}
                                </span>
                            </div>
                            <div className="content">您好，已收到您的工单，稍后将由专人联系您，为您解答。</div>
                        </li>
                    }
                    {
                        ticket?.replies?.map((reply) => {
                            return <li key={reply.id ?? reply.createdAt}
                                className={`item ${reply?.accountId ? 'user' : 'resolver'}-item`}>
                                <div className="info">
                                    {reply?.accountId ? null : <AdminIcon className="admin-head" />}
                                    <span className="username">{reply?.accountId ? '我' : '管理员'}</span>
                                    <span className="reply-time">
                                        {moment(reply?.createdAt).format('YYYY-MM-DD HH:mm')}
                                    </span>
                                    {reply?.accountId ? <DefaultAvatarIcon /> : null}
                                </div>
                                <div className="content">
                                    {reply?.content}
                                </div>
                            </li>
                        })
                    }
                </ul>
                {ticket?.status === 'closed' ? null : <Form
                    className="reply-form"
                    form={form}
                    onFinish={(values) => submit(values)}
                >
                    <Form.Item name="content">
                        <Input.TextArea placeholder="请输入回复内容" style={{ height: 120, resize: 'none' }} />
                    </Form.Item>
                    <Button type="primary" style={{ float: 'right', width: 112 }} onClick={() => form.submit()}>发送</Button>
                </Form>}
            </div>
            <div className="ticket-info">
                <p className="title">订单信息</p>
                <p className="label">工单编号</p>
                <p className="value">{ticket?.id}</p>
                <p className="label">工单标题</p>
                <p className="value">{`${ticket?.template?.category?.name}-${ticket?.template?.name}`}</p>
                <p className="label">提交时间</p>
                <p className="value">{moment(ticket?.createdAt).format('YYYY-MM-DD')}</p>
                <p className="label">详细内容</p>
                <p className="value">{ticket?.content}</p>
                <div>
                    {
                        ticket?.attachments?.map((item) =>
                            <Image
                                key={item.id}
                                height={100}
                                width={100}
                                src={`/apis/attachments/${item.url}`}
                                alt={item.id}
                            />
                        )
                    }
                </div>
            </div>
        </CardNav>
    </div>
}
