export enum InstanceStatus {
    creating = '创建中',
    create_error = '创建错误',
    upgrading = '扩容中',
    upgrade_error = '扩容错误',
    deleting = '删除中',
    delete_error = '删除错误',
    deleted = '已删除',
    normal = '正常',
    expired = '已过期',
    paused = '欠费',
    'will-expire' = '即将过期',
    modifying = '变更中',
    modify_error = '变更错误',
    unsubscribe = "已退订"
}