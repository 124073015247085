import { Form, Layout } from 'antd';
import { PageTypography } from './../../components/page.typography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { State, Account } from '../../../interfaces';
import { AccountAction } from '../actions/account.action';
import './setting.container.less';
import { InfoForm } from '../components/info.form';
import { ResetForm } from '../components/reset.form';
import { ChangePhoneForm } from '../components/change-phone.form';
import { Logout } from '../components/logout';

export const SettingContainer = (props) => {

    const dispatch = useDispatch();
    const [page, updatePage] = useState(1);

    useMount(() => {
        dispatch(AccountAction.getAccount());
        dispatch(AccountAction.getInstances(1, 10));
    });
    return <div className="setting-container">
        <PageTypography title='账户设置'>账户设置提供账户信息管理，包含查看、完善账户信息，账户安全及账户注销。</PageTypography>
        <Layout>
            <Layout.Sider className="setting-sider">
                <ul className="sider-nav">
                    <li onClick={() => updatePage(1) } className={page === 1 ? 'selected' : ''}>账户信息</li>
                    <li onClick={() => updatePage(2) } className={page === 2 ? 'selected' : ''}>修改密码</li>
                    <li onClick={() => updatePage(3) } className={page === 3 ? 'selected' : ''}>更改手机号</li>
                    <li onClick={() => updatePage(4) } className={page === 4 ? 'selected' : ''}>注销</li>
                </ul>
            </Layout.Sider>
            <Layout.Content className="setting-content">
                {page === 1 ? <InfoForm /> : null}
                {page === 2 ? <ResetForm /> : null}
                {page === 3 ? <ChangePhoneForm /> : null}
                {page === 4 ? <Logout /> : null}
            </Layout.Content>
        </Layout>
    </div>
}