import { Dispatch } from 'redux';
import { State } from '../../../interfaces';
import { AccountAction } from './index'
import { Client, Action, PaginationAction, ModalAction } from '../../../redux';
import { BILLS } from '../constants/action-types.constants';
import { PAYMENT_TYPE_MODAL, PAYMENT_SUCC_MODAL } from './../constants/modal.constants'; // 付款弹窗
import { GET_BILLS_PAYMENT, GET_BILLS_INVOICE, GET_BILLS_INFO, GET_DOSAGE_INFO, GET_DOSAGE_INFO_LOAD, SET_BILLS_VOUCHER, GET_CODE_URL } from '../constants/fetch.constants';

export class BillsAction {
    private static client = new Client();
    // 账单
    public static getPayBills(page: number, size: number) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            const { list, total } = await this.client.get('/apis/payment-bills', {
                params: { page, size },
                dispatch,
                fetch: GET_BILLS_PAYMENT,
            });
            // console.log(list, total);
            dispatch(new Action(BILLS).put('payment_bills', list));
            dispatch(PaginationAction.update('payBills', page, size, total, list.length));
        }
    }
    // 账单详情
    public static getPayBillsInfo(id: string) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            const data = await this.client.get(`apis/payment-bills/${id}`, {
                dispatch,
                fetch: GET_BILLS_INFO,
            });
            dispatch(new Action(BILLS).put('payment_bills_info', data));
        }
    }
    // 用量详细
    public static getDosageInfo(id: string, params: Record<string, any>) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            const { list, total } = await this.client.get(`/apis/bills/instances/${id}`, {
                params: { ...params },
                dispatch,
                fetch: GET_DOSAGE_INFO,
            });
            dispatch(new Action(BILLS).put('dosage_list', list));
            dispatch(PaginationAction.update('dosage', params.page, params.size, total, list.length));
        }
    }
    // 用量详细
    public static getDosageInfoLoad(id: string, params: Record<string, any>, callback?: (list) => void) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            const { list } = await this.client.get(`/apis/bills/instances/${id}`, {
                params: { page: 1, size: 999999999, ...params },
                dispatch,
                fetch: GET_DOSAGE_INFO_LOAD,
            });
            callback && callback(list);
        }
    }
    // 可开发票列表
    public static getInvoicePayBills(isInvoiced: Boolean, status: string) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            const { list } = await this.client.get('/apis/payment-bills', {
                params: {
                    page: 1,
                    size: 9999,
                    isInvoiced,
                    status,
                },
                dispatch,
                fetch: GET_BILLS_INVOICE,
            });
            dispatch(new Action(BILLS).put('invoice_bills', list));
        }
    }
    // 微信支付二维码
    public static getQrcode(id: string, payType: string) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            // try {
            const { code_url } = await this.client.post(`/apis/bills/${id}/actions/pay`, {
                payMethod: payType,
            }, {
                dispatch,
                fetch: GET_CODE_URL,
            });
            dispatch(new Action(BILLS).put('code_url', code_url));
            // status === 'unpaid' && !code_url //  未支付 && 对公转账
            // status === 'unpaid' &&  code_url //  未支付 && 微信支付
            // status === 'paid' // 已支付 && 代金券抵扣后金额为0


        }
    }
    // 代金券提交
    public static setVoucher(id: string, data: any, callback?: (amountDue) => void) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            await this.client.post(`/apis/discount-bill/${id}`, {
                ...data
            }, {
                dispatch,
                fetch: SET_BILLS_VOUCHER,
            });
            await dispatch(this.getPayBillsInfo(id))
            await dispatch(AccountAction.getVouchers(1, 999999));
            // dispatch(this.getQrcode(id, params.payType))
            const amountDue = state().bills.payment_bills_info.amountDue; // 付款金额
            callback && callback(amountDue)
        }
    }
    // 触发微信回调
    public static setSocketTest(id: any) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            await this.client.post(`/apis/temp/actions/pay/${id}`, {}, {});
            
        }
    }
}
