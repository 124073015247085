import { Button, Form, message, Radio, Space, Spin } from 'antd';
import { useState, useRef, useEffect } from 'react'
import { useUpdateEffect } from 'react-use';
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as _ from 'lodash';
import { EditIcon } from './../../components/icons.component'
import { Fetch, State, Modal as ModalInterface } from '../../../interfaces'
import { InvoiceAction } from '../actions'
import { PageTypography } from '../../components/page.typography';
import { CardNav } from '../../components/card.navigation'
import { ModalAction } from "../../../redux";
import { INVOICE_ADDRESS_MODAL } from '../constants/modal.constants';
import { LIST_INVOICE_ADDRESS, PUT_INVOICE_FORM } from '../constants/fetch.constants';
import { InvoiceForm } from './../components/invoice-form';
import { InvoiceAddress } from './../modals/invoice-addres';
import { defauleInvoiceForm } from './../enums'
import './new.invoice.two.container.less'

export const NewInvoiceTwoContainer = () => {
    const history = useHistory();
    const { bills } = useParams<any>();
    // console.log(JSON.parse(bills));
    const dispatch = useDispatch();
    const invoiceRef = useRef<any>({});
    const [form] = Form.useForm();
    const { visible } = useSelector<State, ModalInterface>((state) => state.modals[INVOICE_ADDRESS_MODAL] ?? {} as any);
    const { invo_info } = useSelector<State, any>(state => state.invoice);
    const { fetching: submitLoading } = useSelector<State, Fetch>((store) => store.fetch[PUT_INVOICE_FORM] ?? {} as any); //提交
    const { fetching } = useSelector<State, Fetch>((store) => store.fetch[LIST_INVOICE_ADDRESS] ?? {} as any); // 邮寄地址
    const address_list = useSelector<State, any>(state => state.invoice.address_list ?? [] as any);
    const [addressId, setAddressId] = useState<number | string>(''); // 邮件地址

    const submit = (values) => {
        const { title_per = '个人', title_ent, agree, ...newValues } = values;
        const { titleType, invoiceType } = invoiceRef.current
        const data = {
            titleType, // 抬头类型
            invoiceType, // 发票类型
            agree: agree ? 1 : 0,
            title: titleType === 'personal' ? title_per : title_ent, // 发票抬头
            ...defauleInvoiceForm,
            ...newValues,
            invoiceAddressId: addressId, // 邮件地址id
            status: 'not_invoiced',
            bills: JSON.parse(bills).map((id: any) => (
                {
                    billMonthId: id
                }
            )),
        }
        // console.log(data, '====>');
        dispatch(InvoiceAction.addInvoiceRecord(data, () => {
            message.success('申请发票成功');
            history.push('/console/account/invoice');
        }))  //提交数据
    }

    useEffect(() => {
        dispatch(InvoiceAction.getInvoiceInfo())
        dispatch(InvoiceAction.getInvoiceAddressList());
    }, [])
    useUpdateEffect(() => {
        if (address_list.length) {
            const index = _.findIndex(address_list, { default: 1 });
            const defIndex = index !== -1 ? index : 0;
            setAddressId(address_list[defIndex].id);
        }
    }, [address_list])

    useEffect(() => {
        form && form.resetFields();
    }, [invo_info])
    return (
        <div className="invoice-two-container">
            <PageTypography title='发票'>您可在此页面申请和管理发票信息。</PageTypography>
            <CardNav title='申请发票' link='/console/account/invoice/list'>
                <div className="invoice-two-box">
                    <p className='two-title'>选择发票抬头</p>
                    <div className="two-box">
                        <Form
                            form={form}
                            labelAlign='left'
                            labelCol={{ span: 5 }}
                            wrapperCol={{ span: 19 }}
                            onFinish={(values) => submit(values)}
                            layout='horizontal'
                            className="invoiceTitle"
                            initialValues={{ ...invo_info }}
                        >
                            <InvoiceForm ref={invoiceRef} form={form} showCheck={true}></InvoiceForm>
                        </Form>
                    </div>
                    <p className='two-title'>选择邮寄地址</p>
                    <div className="two-box">
                        <Spin spinning={fetching}>
                            <Radio.Group onChange={(e) => { setAddressId(e.target.value) }} value={addressId} className="invoice-title">
                                <Space direction="vertical" size={14}>
                                    {
                                        address_list.length ? address_list.map((item) => {
                                            return (
                                                <Radio value={item.id} key={item.id}>
                                                    <div className='radio-wrapper-text'>
                                                        <span>{JSON.parse(item.area).join('') + item.detailedAddress}</span>
                                                        <EditIcon onClick={() => { dispatch(ModalAction.open(INVOICE_ADDRESS_MODAL, { isUpdata: true, ...item })) }} />
                                                    </div>
                                                </Radio>
                                            )
                                        })
                                            :
                                            '暂无邮寄地址'
                                    }
                                </Space>
                            </Radio.Group>
                            <div className="two-add-address">
                                <Button
                                    type='link'
                                    disabled={address_list.length >= 5}
                                    onClick={() => { dispatch(ModalAction.open(INVOICE_ADDRESS_MODAL)) }}
                                >+&nbsp;&nbsp;新增地址</Button>
                            </div>
                        </Spin>
                    </div>
                </div>
            </CardNav>

            <div className="invice-two-footer">
                <Button
                    type="primary"
                    onClick={() => form.submit()}
                    loading={submitLoading}
                    className="btn-submit">
                    确定
                </Button>
            </div>
            {visible && <InvoiceAddress />}
        </div>
    )
}