import React from 'react';
import { ConfigProvider } from 'antd';
import { Redirect, Route } from 'react-router-dom';
import { SocketContainer } from './socket.container';
import { Main } from './components/main.component';
import { GetBaidu } from './components/baidu.statistics';
import { Account } from './account';
import { Instance } from './instance';
import { Notification } from './message';
import { Ticket } from './ticket';
import { Documents } from './document';
import { SignInContainer } from './login/containers/sign-in.container';
import { SignUpContainer } from './login/containers/sign-up.container';
import { ResetPasswordContainer } from './login/containers/reset-password.container';
import { SignUpSuccessContainer } from './login/containers/sign-up-success.container';
import { Experience } from './experience';
import { ScrollToTop } from './components/scroll-top.componnet';
import zhCN from 'antd/lib/locale/zh_CN';
import { Activation } from './login/containers/activation.container';

export const Routes = (props) => {
    return <ConfigProvider locale={zhCN}>
            <Main>
                <ScrollToTop/>
                <GetBaidu>
                <Route path="/" exact={true}>
                    <Redirect to="/console/instances"/>
                </Route>
                <Route
                    exact
                    path="/sign-in"
                    component={SignInContainer}
                />
                <Route
                    exact
                    path="/sign-up"
                    component={SignUpContainer}
                />
                <Route
                    exact
                    path="/reset"
                    component={ResetPasswordContainer}
                />
                <Route
                    exact
                    path="/sign-up/success"
                    component={SignUpSuccessContainer}
                />
                <Route path="/activation/:activationCode" component={Activation}/>
                </GetBaidu>
                <Route path="/console" exact={false}>
                    <SocketContainer>
                        <Route path="/console/account" component={Account}/>
                        <Route path="/console/instances" component={Instance}/>
                        <Route path="/console/message" component={Notification}/>
                        <Route path="/console/tickets" component={Ticket}/>
                        <Route path="/console/documents" component={Documents}/>
                        <Route path="/console/experience" component={Experience}/>
                    </SocketContainer>
                </Route>
            </Main>
    </ConfigProvider>
}
