import React, { useEffect } from 'react';
import './doc.less';

export const PrivacyContainer = (props) => {
    useEffect(() => {
        const plist: any = document.querySelectorAll('.doc-container p');
        for (let i = 0; i < plist.length; i++) {
            const height = parseInt(window.getComputedStyle(plist[i]).height)
            if (height >= 30) {
                plist[i].style.textIndent = '2em'
            } else {
                plist[i].style.textIndent = '0em'
            }
        }
    }, [])
    return <div className="doc-container">
        <h1>隐私政策</h1>
        <p><br /></p>
        <p>您的信任对我们非常重要，我们深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。因此， https://console.yanrongyun.com（以下称为 “网站”）运营及服务提供者“北京焱融科技有限公司（以下简称“我们”）”制定本网页隐私政策并提醒您：我们将仅会使用您的个人信息用于网站改进服务。<strong>使用本网站将表示您同意我们根据本政策使用您的信息。</strong></p>
        <p>在使用网站的各项产品或服务前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用网站的各项产品或服务，即表示您已充分理解并同意本政策。如对本政策内容有任何疑问、意见或建议，您可通过网站提供的各种联系方式与我们联系。</p>
        <p><br /></p>
        <h2>【信息收集和使用】</h2>
        <p>在使用我们的网站时，我们可能会请您向我们提供一些可用于联系或识别您的个人身份的信息，其中会包括您的姓名、电子邮件等。除了上述信息外，我们会收集您的企业基本信息等法律规定收集的信息。</p>
        <p><strong>除经您明确授权同意或法律规定情形外，我们将不会共享、转让、公开披露您的信息，请您放心。</strong></p>
        <p><br /></p>
        <h2>【联络】</h2>
        <p>我们可能会使用您的电子邮件与您联系，提供新闻简报，营销或宣传材料以及其他信息，以帮助您更好地了解和使用我们的产品和服务。</p>
        <p><br /></p>
        <h2>【Cookies】</h2>
        <p>Cookie是包含少量数据的文件，可能包含匿名唯一标识符。Cookies从网站发送到您的浏览器并存储在您计算机的硬盘上。我们会使用 Cookies 来收集您的信息。<strong>您可以指示您的浏览器拒绝所有Cookies或指示何时发送 Cookie。</strong>但是，如果您不接受 Cookie，则可能无法使用我们网站的某些功能、产品或服务。</p>
        <p><br /></p>
        <h2>【安全】</h2>
        <p>您的个人信息的安全性对我们很重要，我们将会采取符合业界标准、合理可行的安全防护措施等方式用自己最大的努力来保护您的个人信息，但同时请您理解，互联网上传输的任何方法或电子存储方法都不会100％安全。在使用网站服务时，我们强烈建议您不要使用非网站推荐的通信方式发送您的个人信息。</p>
        <p><br /></p>
        <h2>【隐私政策变更】</h2>
        <p>本隐私政策自2021年01月01日起生效，并于本网站发布后立即生效。</p>
        <p>我们保留随时更新或更改本网站隐私政策的权利，并会在本网站发布，请您定期查看本隐私政策。<strong>在我们对本隐私政策作出任何修改并发布后，您继续使用本网站将表示您对更新后的隐私政策的确认，即表示您同意遵守新的隐私政策并受其约束。</strong></p>
        <p>如果我们对本隐私政策作出任何重大更改，我们将通过您提供给我们的电子邮件地址通知您，或在我们的网站上发布重要通知。</p>
        <p><br /></p>
        <h2>【联系我们】</h2>
        <p>如果您还有关于隐私政策的问题，请联系我们 sales@yanrongyun.com。我们将于收到您的问题后十个工作日内回复您。</p>
    </div>
}
