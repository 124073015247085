import { Dispatch } from "redux";
import { Action, Client } from '../../../redux';
import { DOCUMENT } from "../constants/action-types.constants";

export class DocumentAction {

    private static client = new Client();

    public static getDocument(key: string) {
        return async (dispatch: Dispatch<any>, State: any) => {
            const { data } = await this.client.post('/apis/documents', {
                query: `
                    query {
                      documents: allDocuments(where: {key: "${key}", publish: true}) {
                        key,
                        title,
                        content,
                        hasContent,
                        publish,
                        sort,
                        tab
                      },
                      menus: allDocuments(orderBy: "sort__ASC") {
                        key,
                        title,
                        tab,
                        hasContent
                      }
                    }
                `
            }, {});
            dispatch(new Action(DOCUMENT).put('document', data?.documents[0]));
            dispatch(new Action(DOCUMENT).put('menus', data?.menus));
        };
    }
}
