import  {useEffect, useState} from 'react';
import {Form, Select, message as Message, Button, Input} from 'antd';
import { CardNav } from './../../components/card.navigation'
import './experience.applying.container.less';
import { useDispatch, useSelector } from 'react-redux';
import { ExperienceAction } from '../actions/experience.action';
import {useMount, useUnmount} from 'react-use';
import {Feedback, Fetch, State} from "../../../interfaces";
import {CREATE_EXPERIENCE_FEEDBACK} from "../constants/fetch.constants";
import {SessionAction} from "../../../redux";

export const ExperienceFeedbackContainer = () => {
    const { accountId } = useSelector<State, any>(state => state.session);
    const { fetching } = useSelector<State, Fetch>(
        (state) => state.fetch[CREATE_EXPERIENCE_FEEDBACK] ?? ({} as any)
    );
    const [isEdit, setIsEdit] = useState('');
    const feedback = useSelector<State, Feedback>(
        state => state.account.feedback,
    );

    const { Option } = Select;
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({ ...feedback });
    }, [feedback?.suggest, feedback?.uiInterface, feedback?.fileSystem, feedback?.requiredFunctions]);

    useMount(() => {
        dispatch(SessionAction.getSession((session) => {
            dispatch(ExperienceAction.getExperienceFeedback(session.accountId + '', (model) => {
                if (model) {
                    setIsEdit(model.id);
                } else {
                    setIsEdit('');
                    form.resetFields();
                }
            }));
        }));
    });
    useUnmount(() => {
        form.resetFields();
    });
    const submit = (values) => {
        const params = {
            ...values,
            accountId,
            stage: 'feedback-ok',
        };
        // dispatch(ExperienceAction.hasExperienceFeedback(accountId, (has) => {
            if (isEdit) {
                dispatch(ExperienceAction.updateExperienceFeedback(isEdit, params, (evt) => {
                    Message.success('您的修改已保存，您可以修改您的反馈信息！', 3);
                    window.location.hash = '/console/instances';
                }));
            } else {
                dispatch(ExperienceAction.createExperienceFeedback(params, (evt) => {
                    Message.success('已经收到您的反馈信息，非常感谢！', 3);
                    window.location.hash = '/console/instances';
                }));
            }
        // }));
    };

    return <div className="experience-submit-container">
        <CardNav title='非常感谢您成为焱融SaaS文件存储的体验官，请您完成以下问卷，赢取100元话费红包！'>
            <Form
                form={form}
                onFinish={(values) => submit(values)}
                layout="vertical"
                colon={false}
                className="form"
            >
                <Form.Item
                    name="uiInterface"
                    label="1.UI界面是否友好"
                    hasFeedback
                    initialValue={feedback?.uiInterface}
                    rules={[{ required: true, message: '请选择UI界面是否友好!' }]}
                >
                    <Select placeholder="请选择是否友好">
                        <Option value="使用流畅">使用流畅</Option>
                        <Option value="浏览器适配不全">浏览器适配不全</Option>
                        <Option value="页面操作流程繁琐">页面操作流程繁琐</Option>
                    </Select>
                    {/*1.网站页面是否友好*/}
                    {/*<Select placeholder="请选择是否友好">*/}
                    {/*    <Option value="使用流畅 / 没有遇到问题">使用流畅 / 没有遇到问题</Option>*/}
                    {/*    <Option value="网站页面打不开 / 网站有不知名报错">网站页面打不开 / 网站有不知名报错</Option>*/}
                    {/*    <Option value="网站操作流程复杂">网站操作流程复杂</Option>*/}
                    {/*</Select>*/}
                </Form.Item>
                <Form.Item
                    name="fileSystem"
                    label="2.文件系统是否方便使用"
                    hasFeedback
                    initialValue={feedback?.fileSystem}
                    rules={[{ required: true, message: '请选择文件系统是否方便使用!' }]}
                >
                    <Select placeholder="请选择是否易用">
                        <Option value="顺利挂载并使用">顺利挂载并使用</Option>
                        <Option value="支持的Region太少，我的默认虚拟机不在相应区域">支持的Region太少，我的默认虚拟机不在相应区域</Option>
                        <Option value="NFS性能太低，无法满足业务要求，需要更高的性能">NFS性能太低，无法满足业务要求，需要更高的性能</Option>
                    </Select>
                    {/*<Select placeholder="请选择是否方便使用">*/}
                    {/*    <Option value="还没有开通使用">还没有开通使用</Option>*/}
                    {/*    <Option value="已经顺利使用，没有遇到问题">已经顺利使用，没有遇到问题</Option>*/}
                    {/*    <Option value="没有我所需要的公有云 / 可用区">没有我所需要的公有云 / 可用区</Option>*/}
                    {/*    <Option value="文件系统不能满足我的业务需求（性能太差、代金券不足够支付）">文件系统不能满足我的业务需求（性能太差、代金券不足够支付）</Option>*/}
                    {/*</Select>*/}
                </Form.Item>
                {/*<Form.Item*/}
                {/*    name="requiredFunctions"*/}
                {/*    label="3.您认为文件系统最需要有的功能特性"*/}
                {/*    hasFeedback*/}
                {/*    initialValue={feedback?.requiredFunctions}*/}
                {/*    rules={[{ required: true, message: '请选择您认为文件系统最需要有的功能特性!' }]}*/}
                {/*>*/}
                {/*    <Select placeholder="请选择是否友好">*/}
                {/*        <Option value="文件系统容量">文件系统容量</Option>*/}
                {/*        <Option value="文件系统性能">文件系统性能</Option>*/}
                {/*        <Option value="跨云访问">跨云访问</Option>*/}
                {/*        <Option value="与对象存储层的打通">与对象存储层的打通</Option>*/}
                {/*        <Option value="高级功能（快照、归档、WORM等）">高级功能（快照、归档、WORM等）</Option>*/}
                {/*    </Select>*/}
                {/*</Form.Item>*/}
                <Form.Item
                    label="3.请输入您最需要的功能"
                    hasFeedback
                    name="requiredFunctions"
                    initialValue={feedback?.requiredFunctions}
                    rules={[{ required: true, message: '请输入您最需要的功能' }]}
                >
                    <Input.TextArea showCount maxLength={600} autoSize={{ minRows: 3, maxRows: 5 }}
                                    placeholder="请输入您最需要的功能项"/>
                </Form.Item>
                <Form.Item
                    label="4.其他建议"
                    name="suggest"
                    hasFeedback
                    initialValue={feedback?.suggest}
                    rules={[{ required: true, message: '请输入其他建议' }]}>
                    <Input.TextArea showCount maxLength={600} autoSize={{ minRows: 3, maxRows: 5 }}
                                    placeholder="请输入其他建议"/>
                </Form.Item>
            </Form>
            <div className="form-footer">
                <Button type="primary" loading={fetching} onClick={() => form.submit()}>体验官反馈</Button>
            </div>
        </CardNav>
    </div>
}
