import React, { useEffect } from 'react';
import './doc.less';
import './terms.container.less';

export const TermsContainer = (props) => {

    // useEffect(() => {
    //     const plist: any = document.querySelectorAll('.doc-container p');
    //     for (let i = 0; i < plist.length; i++) {
    //         const height = parseInt(window.getComputedStyle(plist[i]).height)
    //         if (height >= 30) {
    //             plist[i].style.textIndent = '2em'
    //         } else {
    //             plist[i].style.textIndent = '0em'
    //         }
    //     }
    // }, [])
    return <div className="doc-container">
        <h1>【服务条款】</h1>
        <p><br /></p>
        <p>https://console.yanrongyun.com数据平台由北京焱融科技有限公司（下面简称“焱融科技”）运营。在您使用本数据平台（下面简称“本平台”）之前，请认真阅读以下服务条款。</p>
        <p>当您使用本平台，代表您已经同意并遵守以下条款。这些条款适用于本平台的所有访客与用户。</p>
        <p><strong>使用本平台代表您已充分阅读、理解并同意受以下条款约束。如果您不同意条款中的任意部分，请终止使用本平台。</strong></p>
        <p><br /></p>
        <h2>【账户】</h2>
        <p>1.请您自行妥善保管您在本平台的账号和密码安全。因您的原因造成的账号安全问题带来的损失，本平台不承担责任。</p>
        <p>2.本平台不能被用于违法目的，不能破坏、违反法律。您对您数据的来源及内容负责，提示您谨慎判断数据来源及内容的合法性。您将承担因您数据内容违反法律法规、部门规章或国家政策而造成的相应结果及责任。</p>
        <p>3.您应当确保（1）数据处理（包括但不限于：获取、使用、处理与传输等，以下合称“处理”）的合法性，以及（2）在处理数据时已取得合法、有效、充分且必要的授权，未侵犯任何第三方的合法权益。您应当对用户业务数据以及您处理用户业务数据是否符合法律法规、部门规章和国家政策的规定进行必要审查，并由您承担由此产生的所有责任。您确认并同意，焱融科技不会因为用户因为数据或您处理用户业务数据违反上述约定，而需要承担任何责任。</p>
        <p>4.您理解并同意，中华人民共和国的国家秘密受法律保护，您有保守中华人民共和国的国家秘密的义务；您使用焱融科技服务应遵守相关保密法律法规的要求，并不得危害中华人民共和国国家秘密的安全。</p>
        <p>5.您还应仔细阅读并遵守焱融科技在网站页面上展示的相应服务说明、技术规范、使用流程、操作文档等内容（以上简称“操作指引”），依照相关操作指引进行操作。您将承担违反相关操作指引所的后果；同时，焱融科技郑重提示您，请把我风险谨慎操作。</p>
        <p>6.就用户业务数据，焱融科技除执行您的服务要求外，不进行任何未获授权的使用及披露；但以下情形除外：</p>
        <div>
            <span>(1）在国家有关机关依法查询或调阅用户业务数据时，焱融科技具有按照相关法律法规或政策文件要求提供配合，并向第三方或者行政、司法等机构披露的义务；</span>
            <span>(2）您和焱融科技另行协商一致。</span>
        </div>
        <p>7.用户数据一经删除，即不可恢复；您应承担数据因此被删除所引发的后果和责任，您理解并同意，焱融科技没有继续保留、导出或者返还用户业务数据的义务。</p>
        <p>8.您要提供有效的邮箱地址，和其他必要的注册信息。</p>
        <p>9.您的账号不允许和其他人分享使用。</p>
        <p>10.账号应由自然人创建，由“机器人”注册的账号是不允许的。</p>
        <p>11.注册成功即开通，开通后您获得焱融科技向您发送的登录、使用服务的密钥、口令即可使用服务，服务期限自开通之时起算（而非自您获得登录、使用服务的密钥、口令时起算）</p>
        <p>12.您在使用平台时，您应该向焱融科技提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。您接收站内信、系统消息的会员账号（包括子账号），也作为您的有效联系方式。</p>
        <p>13.焱融科技将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</p>
        <p>14.焱融科技通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账号发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。</p>
        <p>15.您应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，将由您承担由此可能产生的法律后果。</p>
        <p><br /></p>
        <h2>【支付，扩容和续费】</h2>
        <p>1.焱融SaaS文件存储采用按量计费的方式，先使用后付费，您开通后即可使用焱融SaaS文件存储。</p>
        <p>2.当您选择退订，本平台会终止向您提供的服务，您确认知悉，终止服务后本平台将删除您存放的数据，平台不对由此产生的损失负责。</p>
        <p>3.您收到账单后请及时支付费用，如果您在收到账单后14天仍未支付费用，我们将暂时冻结您的账户，冻结期内您无法对数据进行操作，但我们依旧保存您的数据并持续计费。冻结账户14天后如果您仍未支付所欠的服务费用，我们将停止为您提供服务并删除清空您账户内的所有数据；由此产生的损失我们概不负责。</p>
        <p>4.焱融科技保留在您未按照约定支付全部费用之前不向您提供服务，或者终止服务的权利。同时，焱融科技保留对您欠费要求您按日承担万分之五的违约金以及追究其他法律责任的权利。</p>
        <p>5.本平台不包含任何安装及维护服务，如您有安装或者维护服务，请联系北京焱融科技有限公司，联系方式：<a href="mailto:sales@yanrongyun.com">sales@yanrongyun.com</a>。</p>
        <p><br /></p>
        <h2>【取消和终止】</h2>
        <p>1.您完全负责您帐户的正确注销行为。您可以随时通过点击账户设置页面中的注销账号来注销您的帐户。</p>
        <p>2.如果您在当前账单周期结束前取消服务，您仍然要为当前账单周期已产生的使用量付费。</p>
        <p>3.发生下列情形之一的，服务期限提前终止：</p>
        <div>
            <span>(1）双方协商一致提前终止的；</span>
            <span>(2）您严重违反本协议（包括，您严重违反相关法律法规规定，或您严重违反本协议项下之任一承诺内容等），焱融科技有权提前终止服务直至清除您的全部数据；</span>
        </div>
        <p>4.焱融科技可提前30天在网站上通告或给您发网站内通知或书面通知的方式终止本服务服务协议。</p>
        <p>5.您理解并认可，为技术升级、服务体系升级、或因经营策略调整或配合国家重大技术、法规政策等变化，焱融科技不保证永久的提供某种服务，并有权变更所提供服务的形式、规格或其他方面（如服务的价格和计费模式），在终止该种服务或进行此种变更前，焱融科技将尽最大努力且提前以网站公告、站内信、邮件或短信等一种或多种方式进行事先通知。</p>
        <p><br /></p>
        <h2>【免费试用】</h2>
        <p>1.焱融SaaS文件存储新注册用户可免费试用30天（总容量不超过2TB）。试用期结束后按正常价格计费。</p>
        <p>2.如果您在试用期内上传的数据超过2TB，那么试用期仅对超过部分进行计费，试用期结束后对您上传的全部数据按正常价格计费。</p>
        <p>3.免费试用活动仅用于本账户下使用，不得转让或出售，不得折现。</p>
        <p>4.如您在活动中存在隐瞒、虚构、作弊、欺诈或通过其他非正常手段规避活动规则、获取不当利益的行为，包括但不限于作弊领取、恶意套现、网络攻击、虚假交易等，焱融SaaS文件存储有权收回相关权益、取消您的活动参与资格，撤销违规交易，必要时追究您的法律责任。</p>
        <p>5.免费试用仅限于容量型产品，其余产品不参加免费试用活动。</p>
        <p><br /></p>
        <h2>【服务内容和价格变更】</h2>
        <p>1.鉴于网络服务的特殊性，您确认同意平台有权随时变更、中断或终止部分或全部的服务。如变更、中断或终止的网络服务属于免费网络服务，平台无需对任何用户或任何第三方承担任何责任。</p>
        <p>2.您确认理解，本平台需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成收费服务在合理时间内的中断，平台无需为此承担任何责任，但本平台应尽可能事先进行通告。</p>
        <p>3.本平台可在任何时候为任何原因变更服务或删除其部分功能。终止服务后，本平台将尽最大努力通过合理方式通知用户。当您发现无法登录或享受服务时，可以咨询本平台在线客服。一旦服务取消或终止，用户使用本服务的权利立即终止，且在本平台服务过程中储存的任何信息可能无法恢复。</p>
        <p>4.本平台所有服务的价格均可能会发生变化，此类通知会发布到网站。</p>
        <p>5.本平台对您或第三方对本平台的任何修改、价格变更、暂停或终止不承担任何责任。</p>
        <p><br /></p>
        <h2>【版权】</h2>
        <p>本平台保留对以下各项内容、信息完全的、不可分割的所有权及知识产权：</p>
        <p>1.除用户自行导入、上传的内容外，本平台拥有平台相关的所有元素，包括但不限于所有内容、数据、技术、软件、代码、用户界面以及与其相关的任何衍生作品；</p>
        <p>2.平台帐号的所有权归平台所有，用户完成申请注册手续后，仅获得本平台帐号的使用权，且该使用权仅属于初始申请注册人。同时，初始申请注册人不得赠与、借用、租用、转让或售卖本平台帐号或者以其他方式许可非初始申请注册人使用本平台帐号。非初始申请注册人不得通过受赠、继承、承租、受让或者其他任何方式使用本平台帐号。</p>
        <p><br /></p>
        <h2>【知识产权】</h2>
        <p>1.您应保证提交焱融科技的素材、对焱融科技服务的使用及使用焱融科技服务所产生的成果未侵犯任何第三方的合法权益。</p>
        <p>2.如果第三方机构或个人对您使用焱融科技服务所涉及的相关素材的知识产权归属提出质疑或投诉，或对您使用的焱融科技服务的知识产权的归属提出质疑或投诉，您和焱融科技均有责任出具相关知识产权证明材料，并配合对方的相关投诉处理工作。对于因此引起的索赔、诉讼或可能向其提起诉讼,违约方应负责解决，承担费用和损失，以及使另一方免责。</p>
        <p><br /></p>
        <h2>【通用条款】</h2>
        <p>1.您了解本平台使用第三方供应商和托管合作伙伴提供运行此服务所需的必要硬件，软件，网络，存储和相关技术。</p>
        <p>2.您不得修改，改编或破解本数据平台。</p>
        <p>3.未经本平台的明确书面许可，您同意不复制，出售，转售或利用本平台的任何部分使用本平台。</p>
        <p>4.您明确理解并同意，本平台不承担如下原因导致的任何直接的，间接的，附带的，特殊的，相应的或惩罚性的损害赔偿，包括但不限于利润损失，商誉，使用，数据或其他无形损失(即便公司已被告知可能发生此类损害）：（i）使用或无法使用服务;（ii）未经授权访问或更改您的传输或数据;（iii）任何第三方对服务的陈述或行为;（iv）或有关服务的任何其他事宜。</p>
        <p>5.本平台未行使或执行本服务条款的任何权利或规定，并不构成对此类权利或规定的放弃。本服务条款构成您与本平台之间的全部协议，约束您对服务的使用，取代您与本平台之间的任何先前的协议（包括但不限于本服务条款中的任何以前的版本）。</p>
        <p>6.关于服务条款的其他问题请联系 <a href="mailto:sales@yanrongyun.com">sales@yanrongyun.com</a>。</p>
        <p><br /></p>
        <h2>【违约责任】</h2>
        <p>1.您违反本协议中的任一内容，或焱融科技根据其判断认为您的使用行为存在异常的，焱融科技均有权就其情节，根据独立判断并单方采取以下措施中的一种或多种：</p>
        <div>
            <span>(1）限制、中止使用服务；</span>
            <span>(2）终止提供服务，终止本协议；</span>
            <span>(3）追究您的法律责任；</span>
            <span>(4）其他焱融科技认为适合的处理措施。焱融科技依据前述约定采取中止服务、终止服务等措施而造成的用户损失将由您承担。</span>
        </div>
        <p>2.如因您违反有关法律法规或者本协议、相关规则之规定，使焱融科技遭受任何损失、受到其他用户、任何第三方的索赔或任何行政管理部门的处罚，您应对焱融科技、其他用户或相关第三方的实际损失进行全额赔偿，包括合理的律师费用。</p>
        <p>3您理解且同意，鉴于计算机、互联网的特殊性，下述情况不属于焱融科技违约：</p>
        <div>
            <span>(1）焱融科技在进行系统及服务器配置、维护、升级时，需要短时间中断服务；</span>
            <span>(2）由于Internet上的通路阻塞造成您网站访问速度下降。</span>
        </div>
        <p>4.在任何情况下，焱融科技均不对任何间接性、后果性、惩戒性、偶然性、特殊性的损害，包括您使用焱融科技服务而遭受的利润损失承担责任（即使您已被告知该等损失的可能性）。</p>
        <p>5.在法律允许的情况下，焱融科技在本协议项下所承担的损失赔偿责任不超过就该服务过往12个月所缴纳的服务费用的总和。</p>
        <p><br /></p>
        <p>任何新功能，包括新工具和资源的发布，均应遵从此服务条款。在进行任何更改后继续使用本服务即表示您同意此更改。</p>
    </div>
}
