import { Col, Form, Input, message as Message, Row, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State, Fetch } from '../../../interfaces';
import { AccountAction } from '../actions';
import { UPDATE_PHONE } from '../constants/fetch.constants';

export const ChangePhoneForm = (props) => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [remaining, updateRemaining] = useState(60);
    const { phone } = useSelector<State, any>(
        state => state.session ?? {} as any,
    );
    const { fetching } = useSelector<State, Fetch>(
        state => state.fetch[UPDATE_PHONE] ?? {} as any,
    );

    useEffect(() => {
        switch (remaining) {
            case 0:
                updateRemaining(60);
                break;
            case 60:
                break;
            default:
                setTimeout(() => {
                    updateRemaining((remaining: number) => remaining - 1)
                }, 1000);
                break;
        };
    }, [remaining]);

    const submit = (values) => {
        dispatch(AccountAction.updatePhone(values, () => {
            Message.success('修改手机号成功');
        }));
    };
    return <Form
        form={form}
        layout="vertical"
        colon={false}
        style={{ width: 600 }}
        onFinish={(values) => submit(values)}
    >
        <Form.Item
            label="原手机号"
            name="oldPhone"
            initialValue={phone}
        >
            <Input disabled />
        </Form.Item>
        <Row>
            <Col span={19}>
                <Form.Item
                    label="验证码"
                    name="verify"
                    required={false}
                    rules={[{ required: true, message: '请输入验证码' }]}
                >
                    <Input placeholder="请输入验证码" />
                </Form.Item>
            </Col>
            <Col span={5} style={{ paddingLeft: 16 }}>
                <Form.Item
                    label="&nbsp;"
                >
                    <Button 
                        onClick={() => {
                            updateRemaining(59);
                            dispatch(AccountAction.sendResetPhoneSms());
                        }}
                        disabled={remaining !== 60} 
                        style={{ height: 40 }}>
                        {remaining === 60 ? '发送验证码' : `${remaining}秒后重新发送`}
                    </Button>
                </Form.Item>
            </Col>
        </Row>
        <Form.Item
            label="新手机号"
            name="phone"
            rules={[
                () => ({
                    validator(rule, value) {
                        const pattern = /^1[3-9]\d{9}$/;
                        if (pattern.test(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject('请输入正确的手机号');
                    },
                }),
            ]}
        >
            <Input placeholder="请输入新手机号" />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center', margin: '48px 0 120px' }}>
            <Button type="primary" onClick={() => form.submit()} loading={fetching}>修改</Button>
        </Form.Item>
    </Form>
}
