import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Button } from 'antd';
import { LoginLayout } from '../../components/login-layout.component';
import { useDispatch, useSelector } from 'react-redux';
import { LoginAction } from '../actions/login.action';
import './sign-in.container.less';
import { Link } from 'react-router-dom';
import { getQuery } from '../../../helpers/query.helper';
import { State, Fetch } from '../../../interfaces';
import { SIGN_IN } from '../constants/fetch.constants';
import { PasswordIcon, UsernameIcon, VerifyIcon } from '../../components/icons.component';
import { useMount } from 'react-use';
import { InfoCircleFilled } from '@ant-design/icons';
import { UserNameWIcon, PasswordWIcon, VerifyWIcon } from './../../components/icons.component'

export const SignInContainer = (props) => {

    const { hash } = getQuery(props?.location?.search);
    const dispatch = useDispatch();
    const [time, updateTime] = useState(Date.now());
    const captcha = useSelector<any, any>(state => state?.loginCaptcha?.signIn);
    const [form] = Form.useForm();
    const { fetching } = useSelector<State, Fetch>(
        state => state.fetch[SIGN_IN] ?? {} as any,
    );

    const [email, updateEmail] = useState<string>('');
    const isActivated = useSelector<any, any>(state => state?.loginActivate?.isActivated);

    useMount(() => {
        dispatch(LoginAction.getIsNeedCaptcha());
    });

    const pushState = (url: string) => window.location.hash = url;

    const activate = () => {
        pushState(`/sign-up/success?email=${encodeURIComponent(email)}&isActivatePage=1`)
    };

    const submit = (values) => {
        updateEmail(values.email);
        dispatch(LoginAction.signIn(
            values,
            (e) => e ? updateTime(Date.now()) : pushState(hash ?? '/console/instances')),
        );
    };
   
    return <LoginLayout isSignInPage={true}>
        <div className="sign-in-container">
            <p className="title">登录</p>
            <Form
                form={form}
                name="web"
                onFinish={(values) => submit(values)}
                className="sign-in-form"
            >
                <Form.Item name="email" rules={[
                    { required: true, message: '请输入邮箱' },
                    { type: 'email', message: '请输入正确的邮箱' },
                ]}>
                    <Input placeholder="请输入用户名/邮箱" prefix={<UsernameIcon />} />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: '请输入密码' }]}>
                    <Input.Password
                        onKeyDown={(e) => e.key === 'Enter' ? form.submit() : ''}
                        placeholder="请输入密码"
                        prefix={<PasswordIcon />}
                    />
                </Form.Item>
                {captcha?.isNeed ? <Row>
                    <Col span={15}>
                        <Form.Item name="code">
                            <Input placeholder="请输入验证码" prefix={<VerifyIcon />} />
                        </Form.Item>
                    </Col>
                    <Col span={8} offset={1}>
                        <div className="verify-box">
                            <img
                                onKeyDown={(e) => e.key === 'Enter' ? form.submit() : ''}
                                onClick={() => updateTime(Date.now())}
                                src={`/apis/captcha?time=${time}`}
                                alt="验证码"
                            />
                        </div>
                    </Col>
                </Row> : null}
                {isActivated ? null : <div className="go-activate">
                    <InfoCircleFilled className="activate-icon" />
                    <span>您的账户未激活，点击<a onClick={() => activate()}>激活</a></span>
                </div>}
                <Button type="primary" className="form-submit" onClick={() => form.submit()} loading={fetching}>登录</Button>
            </Form>
            <div className="sign-in-tools">
                <p>没有账号？<Link to="/sign-up">立即注册</Link></p>
                <Link to="/reset" style={{ color: '#7F8A94' }}>忘记密码？</Link>
            </div>
        </div>
        <div className="sign-in-container-mobile">
            <p className="ad-aws">比 Amazon EFS 降低<span>75%</span>成本</p>
            <div className="ad-discount">
                <p >新用户免费试用<span>30</span>天</p>
                <span className="ad-voucher" onClick={() => { pushState('/console/documents/sassrule') }}>活动规则&gt;&gt;</span>
            </div>
            <Form
                form={form}
                name="mobile"
                onFinish={(values) => submit(values)}
                className="sign-in-form"
                style={{ marginTop: 40 }}
            >
                <Form.Item name="email" rules={[
                    { required: true, message: '请输入邮箱' },
                    { type: 'email', message: '请输入正确的邮箱' },
                ]}>
                    <Input placeholder="请输入用户名/邮箱" prefix={<UserNameWIcon />} />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: '请输入密码' }]}>
                    <Input.Password
                        onKeyDown={(e) => e.key === 'Enter' ? form.submit() : ''}
                        placeholder="请输入密码"
                        prefix={<PasswordWIcon style={{ fontSize: '12px' }} />}
                    />
                </Form.Item>
                {captcha?.isNeed ? <Row>
                    <Col span={13}>
                        <Form.Item name="code">
                            <Input placeholder="请输入验证码" prefix={<VerifyWIcon />} />
                        </Form.Item>
                    </Col>
                    <Col span={8} offset={1}>
                        <div className="verify-box">
                            <img
                                onKeyDown={(e) => e.key === 'Enter' ? form.submit() : ''}
                                onClick={() => updateTime(Date.now())}
                                src={`/apis/captcha?time=${time}`}
                                alt="验证码"
                            />
                        </div>
                    </Col>
                </Row> : null}
                {isActivated ? null : <div className="go-activate">
                    <InfoCircleFilled className="activate-icon" />
                    <span>您的账户未激活，点击<a onClick={() => activate()}>激活</a></span>
                </div>}
                <Button type="primary" className="form-submit-mobile" onClick={() => form.submit()} loading={fetching}>登录</Button>
            </Form>
            <div className="reset-box">
                <Link to="/reset" className="reset-text">忘记密码？</Link>
            </div>
            <Link to="/sign-up" className="register-btn-mobile">去注册</Link>
        </div>
    </LoginLayout>
}
