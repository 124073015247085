export const GET_INSTANCES = 'get_instances';
export const GET_INSTANCE = 'get_instance';

export const GET_PURCHASE_PRICE = 'get_purchase_PRICE';
export const GET_UPGRADE_PRICE = 'get_upgrade_price';
export const GET_RENEW_PRICE = 'get_renew_price';

export const CREATE_PURCHASE_ORDER = 'create_purchase_order';
export const CREATE_UPGRADE_ORDER = 'create_upgrade_order';
export const CREATE_RENEW_ORDER = 'create_renew_order';
export const UNMOUNT_INSTANCE = 'unmount_instance';

export const GET_MONITOR_DATA = 'get_monitor_data';
export const GET_MODIFY_PRICE = 'get_modify_price';
export const CREATE_MODIFY_ORDER = 'create_modify_order';
export const MODIFY_NETWORK_CONFIG = 'modify_network_config'; // 配置变更
export const MODIFY_NETWORK_QUICKEN = 'modify_nature_quicken'; // 性能加速
export const UPDATE_INSTANCE_NAME = 'update_instance_name';

export const MODIFY_CONFIRM_LOADING = 'modify_confirm_loading'; // 对话框
