import { useState } from 'react';
import { Badge, Layout, Menu, Dropdown, Divider, message as Message } from 'antd';
import moment from 'moment';
import logo from '../../asserts/logo.svg';
import { Link } from 'react-router-dom';
import './common-layout.component.less';
import {
    MessageIcon,
    DefaultAvatarIcon,
    AccountSettingIcon,
    SignOutIcon,
    TicketIcon,
    PlusIcon,
    BalanceIcon,
    OrderIcon,
    VoucherIcon,
    AccountMoreIcon,
} from './icons.component';
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../interfaces";
import { useMount } from "react-use";
import { SessionAction } from "../../redux";
import { MessageAction } from '../message/actions/message.action';
import { ExperienceAction } from "../experience/actions/experience.action";

export const CommonLayout = (props) => {
    const dispatch = useDispatch();
    const { sumUnread, unreadMessages } = useSelector<any, any>(
        state => state.message ?? {} as any,
    );
    const [clicking, setClicking] = useState(true);
    const [btn, setBtn] = useState<string>('申请体验官');

    useMount(() => {
        // dispatch(SessionAction.getSession((session) => {
        //     dispatch(ExperienceAction.hasExperienceFeedback(session.accountId, (has) => {
        //         if (!has) {
        //             setBtn('申请体验官');
        //         } else if (has === 'ok') {
        //             setBtn('体验官反馈');
        //         } else {
        //             setBtn('申请体验官');
        //         }
        //     }));
        // }));
        dispatch(SessionAction.getSession());
        dispatch(MessageAction.getUnreadMessage());
        dispatch(MessageAction.getUnreadMessagesSum());
    });
    const { email, phone, accountId } = useSelector<State, any>(state => state.session);

    const pushState = (url: string) => {
        window.location.hash = url;
    };

    const experienceApplyHandle = (url: string) => {
        if (btn === '申请体验官') {
            // dispatch(ExperienceAction.hasExperienceApply(accountId, (has) => {
            dispatch(ExperienceAction.hasExperienceFeedback(accountId, (has) => {
                if (has === 'apply' || has !== '') {
                    const msg = has === 'apply' ? '感谢申请，请在3个工作日之内查看邮件结果！' : '感谢您申请焱融SaaS文件存储的体验官，您的申请未被通过，如果您有意见或者建议，可以联系我们，谢谢！';
                    Message.success(msg);
                    setClicking(false);
                    // 设置重复点击延迟事件，2秒后将执行
                    setTimeout(function () {
                        setClicking(true);
                    }, 2000);
                } else {
                    pushState(url);
                    // window.open(url,"_blank");
                }
            }));
        } else {
            pushState('/console/experience/feedback');
        }

    };

    return <Layout style={{ minHeight: '100vh' }} className="common-layout">
        <Layout.Header className="header">
            <div className="container">
                <a href="https://www.yanrongyun.com" rel="noopener noreferrer" target="_blank"><img src={logo} alt="logo" className="logo" /></a>
                {/*{btn !== '申请体验官' ? <span className="github" style={{marginRight: '35px'}}  onClick={() => {clicking && experienceApplyHandle('/console/experience')}}>{btn}*/}
                {/*    <span className="menu_new_highlight" style={{ backgroundImage: `url(${menu_new})` }}></span>*/}
                {/*</span> : null}*/}
                <a href="https://github.com/yr-tech/yunzhou" rel="noopener noreferrer" target="_blank" className="github">GitHub社区</a>
                <div className="nav">
                    <ul className="links">
                        <Link className="link" to="/console/instances"><span className="link-content">文件系统</span></Link>
                        <li className="link">
                            <Dropdown
                                placement="bottom"
                                trigger={['hover']}
                                getPopupContainer={node => node.parentElement as HTMLElement}
                                overlay={<div className="link-dropdown">
                                    <Menu>
                                        <Menu.Item key={'创建工单'} onClick={() => pushState('/console/tickets/submit')}><PlusIcon />创建工单</Menu.Item>
                                        <Menu.Item key={'我的工单'} onClick={() => pushState('/console/tickets')}><TicketIcon />我的工单</Menu.Item>
                                    </Menu>
                                </div>}
                            >
                                <span className="link-content">工单</span>
                            </Dropdown>
                        </li>
                        <li className="link">
                            <Dropdown
                                placement="bottom"
                                trigger={['hover']}
                                getPopupContainer={node => node.parentElement as HTMLElement}
                                overlay={<div className="link-dropdown">
                                    <Menu>
                                        {/* <Menu.Item key={'余额'} onClick={() => pushState('/console/account/balance')}><BalanceIcon />余额</Menu.Item>
                                        <Menu.Item key={'费用账单'} onClick={() => pushState('/console/account/billing')}><BillingIcon />费用账单</Menu.Item>
                                        <Menu.Item key={'订单列表'} onClick={() => pushState('/console/account/order')}><OrderIcon />订单列表</Menu.Item>
                                        <Menu.Item key={'代金券管理'} onClick={() => pushState('/console/account/voucher')}><VoucherIcon />代金券管理</Menu.Item> */}
                                        <Menu.Item key={'账单'} onClick={() => pushState('/console/account/bill')}><BalanceIcon />账单</Menu.Item>
                                        {/* <Menu.Item key={'付款'} onClick={() => pushState('/console/account/payment')}><BillingIcon />付款</Menu.Item> */}
                                        <Menu.Item key={'代金券'} onClick={() => pushState('/console/account/voucher')}><VoucherIcon />代金券</Menu.Item>
                                        <Menu.Item key={'发票'} onClick={() => pushState('/console/account/invoice')}><OrderIcon />发票</Menu.Item>
                                    </Menu>
                                </div>}
                            >
                                <span className="link-content">费用</span>
                            </Dropdown>
                        </li>
                        {/* <li className="link"><span className="link-content">论坛</span></li> */}
                        <Link className="link" to="/console/documents/storage"><span className="link-content">文档</span></Link>
                        {/* <li className="link">
                            <Dropdown
                                placement="bottom"
                                trigger={['hover']}
                                getPopupContainer={node => node.parentElement as HTMLElement}
                                overlay={<div className="link-dropdown">
                                    <Menu>
                                        <Menu.Item key={'快速上手'} onClick={() => pushState('/console/documents/document/quick-start')}>
                                            快速上手
                                        </Menu.Item>
                                        <Menu.Item key={'服务指南'} onClick={() => pushState('/console/documents/document/service-intro')}>
                                            服务指南
                                        </Menu.Item>
                                        <Menu.Item key={'常见问题'} onClick={() => pushState('/console/documents/document/faq')}>
                                            常见问题
                                        </Menu.Item>
                                    </Menu>
                                </div>}
                            >
                                <span className="link-content">文档</span>
                            </Dropdown>
                        </li> */}
                        <li className="link link-message">
                            <Dropdown
                                placement="bottom"
                                trigger={['hover']}
                                getPopupContainer={node => node.parentElement as HTMLElement}
                                overlay={
                                    // <List
                                    //     className="link-dropdown-message"
                                    //     header={<div className="link-list-title">
                                    //         <p>共有 {sumUnread} 条未读消息</p>
                                    //         <Link to="/console/message">查看全部</Link>
                                    //     </div>}
                                    //     locale={{
                                    //         emptyText: <div className="empty-container">-暂无未读消息-</div>
                                    //     }}
                                    // >
                                    //     {unreadMessages.map(msg => {
                                    //         return <List.Item className="link-message-item" key={msg.id} onClick={() => pushState(`/console/message/${msg.id}`)}>
                                    //             <p className="title">{msg.title}</p>
                                    //             <p className="time">{moment(msg.createdAt).format('YYYY-MM-DD HH:mm:ss')}</p>
                                    //         </List.Item>
                                    //     })}
                                    // </List>
                                    <div className="link-dropdown-message">
                                        <div className="link-list-title">
                                            <p>共有 {sumUnread} 条未读消息</p>
                                            <Link to="/console/message">查看全部</Link>
                                        </div>
                                        {unreadMessages.length ? '' : <div className="empty-container">-暂无未读消息-</div>}
                                        <Menu>
                                            {unreadMessages.map(msg => {
                                                return <Menu.Item className="link-message-item" key={msg.id} onClick={() => pushState(`/console/message/${msg.id}`)}>
                                                    <p className="title">{msg.title}</p>
                                                    <p className="time">{moment(msg.createdAt).format('YYYY-MM-DD HH:mm:ss')}</p>
                                                </Menu.Item>
                                            })}
                                        </Menu>
                                    </div>
                                }
                            >
                                {sumUnread !== 0 ? <Badge count={sumUnread} size="small" style={{ backgroundColor: '#D0021B', boxShadow: 'none' }} offset={[-4, 4]}>
                                    <MessageIcon className="content-message" />
                                </Badge> : <MessageIcon className="content-message" />}
                            </Dropdown>
                        </li>
                        <Divider type="vertical" style={{ backgroundColor: '#505050', height: 20 }} />
                        <li className="link link-personal" style={{ float: 'right' }}>
                            <Dropdown
                                placement="bottomRight"
                                trigger={['hover']}
                                getPopupContainer={node => node.parentElement as HTMLElement}
                                overlay={
                                    // <List className="link-dropdown"
                                    //     header={
                                    //         <div className="personal-header">
                                    //             <DefaultAvatarIcon />
                                    //             <div className="personal-info">
                                    //                 <p className="email">{email}</p>
                                    //                 <p className="phone">{phone}</p>
                                    //             </div>
                                    //         </div>}
                                    // >
                                    //     <List.Item className="item-personal" onClick={() => pushState('/console/account/setting')}>
                                    //         <AccountSettingIcon />&nbsp;账户设置
                                    //     </List.Item>
                                    //     <List.Item className="item-personal" onClick={() => dispatch(SessionAction.signOut())}>
                                    //         <SignOutIcon />&nbsp;退出登录
                                    //     </List.Item>
                                    // </List>
                                    <div className='link-dropdown'>
                                        <div className="personal-header">
                                            <DefaultAvatarIcon />
                                            <div className="personal-info">
                                                <p className="email">{email}</p>
                                                <p className="phone">{phone}</p>
                                            </div>
                                        </div>
                                        <Menu>
                                            <Menu.Item key={'账户设置'} onClick={() => pushState('/console/account/setting')}>
                                                <AccountSettingIcon />&nbsp;账户设置
                                            </Menu.Item>
                                            <Menu.Item key={'退出登录'} onClick={() => dispatch(SessionAction.signOut())}>
                                                <SignOutIcon />&nbsp;退出登录
                                            </Menu.Item>
                                        </Menu>
                                    </div>

                                }
                            >
                                <div className="link-content content-personal">
                                    <DefaultAvatarIcon className="default-avatar" />
                                    {email}
                                    <AccountMoreIcon style={{ position: 'relative', top: -2, left: 6 }} />
                                </div>
                            </Dropdown>
                        </li>
                    </ul>
                </div>
            </div>
        </Layout.Header>
        <Layout className="body">
            <Layout.Content>
                {props.children}
            </Layout.Content>
        </Layout>
        <Layout.Footer className="footer">
            <p className="copyright">&copy; 2021 Yanrong Tech 版权所有 ｜
                <Link to="/console/documents/privacy" target="_blank">隐私声明</Link> ｜
                <Link to="/console/documents/terms" target="_blank">用户协议</Link>
            </p>
        </Layout.Footer>
    </Layout>
}
