import React, { useEffect, useState } from 'react';
import { Spin, Row, Select, DatePicker } from 'antd';
import { Line as AntLine } from '@ant-design/charts';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Fetch, State } from '../../../interfaces';
import { GET_MONITOR_DATA } from '../constants/fetch.constants';
import { MonitorItemTitles } from '../enums'
import { InstanceAction } from '../actions/instance.action';
import { unitTransition } from '../../../helpers/capacity.helper';
import { CaretDownOutlined } from '@ant-design/icons';

export const Line = (props: { type: any; instanceId: any; }) => {
    const dispatch = useDispatch();
    const [before, updateBefore] = useState(60 * 60);
    const { type, instanceId } = props;
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[`${GET_MONITOR_DATA}_${type}`] ?? {} as any);
    const monitor = useSelector<State, any>(state => state.instance.monitor[type] ?? [] as any);

    useEffect(() => {
        if (type) {
            dispatch(InstanceAction.getMonitorData(instanceId, type, before));
        }
    }, [before, type]);

    const handleRangeChange = (value: number) => {
        updateBefore(value);
    };

    return <Spin spinning={fetching}>
        <div className="chart-box" key={type}>
            <Row className="chart-tools">
                <p className="title">{MonitorItemTitles[type]}</p>
                <div className="tools">
                    <Select
                        defaultValue={60 * 60}
                        className="chart-select"
                        onSelect={(value) => handleRangeChange(Number(value))}
                        suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                    >
                        <Select.Option value={60 * 60}>实时</Select.Option>
                        <Select.Option value={60 * 60 * 24 * 7}>周</Select.Option>
                        <Select.Option value={60 * 60 * 24 * 30}>月</Select.Option>
                    </Select>
                </div>
            </Row>
            <Row>
                <AntLine
                    className="chart"
                    xField="timestamp"
                    yField="value"
                    meta={{
                        'timestamp': {
                            range: [0, 1],
                        }
                    }}
                    yAxis={{
                        label: {
                            autoEllipsis: true,
                            autoHide: true,
                            formatter: (text) => {
                                switch (type) {
                                    case 'capacity':
                                        return unitTransition(Number(text), 'B');
                                    case 'iops':
                                        return `${text} IOPS`;
                                    case 'bandwidth':
                                        return unitTransition(Number(text), 'B/s');
                                    default:
                                        return text;
                                }
                            }
                        },
                        line: {
                            style: {
                                stroke: '#bfbfbf',
                            },
                        },
                        min: 0,
                        tickMethod: type === 'inode' ? 'cat' : 'r-pretty',
                        tickCount: 5,
                    }}
                    point={{
                        size: 0,
                    }}
                    color={['#31A9F6', '#F78326']}
                    data={monitor}
                    xAxis={{
                        label: {
                            formatter: (text) => {
                                const format = before === 60 * 60 ? 'HH:mm' : before === 3600 * 24 * 7 ? 'MM-DD HH:mm' : 'MM-DD';
                                return moment(Number(text)).format(format);
                            }
                        },
                        tickCount: 7,
                    }}
                    tooltip={{
                        title: (value) => {
                            return `时间：${moment(Number(value)).format('YYYY-MM-DD HH:mm')}`
                        },
                        fields: ['timestamp', 'value', 'key'],
                        formatter: (datum) => {
                            let value;
                            switch (type) {
                                case 'capacity':
                                    value = unitTransition(Number(datum.value), 'B');
                                    break;
                                case 'iops':
                                    value = `${datum.value} IOPS`;
                                    break;
                                case 'bandwidth':
                                    value = unitTransition(Number(datum.value), 'B/s')
                                    break;
                                default:
                                    value = datum.value;
                                    break;
                            }
                            return { name: datum.key, value };
                        }
                    }}
                    seriesField="key"
                />
            </Row>
        </div>
    </Spin>
}