import React, { useEffect } from 'react';
import formula from './../../../asserts/formula.jpg';
import './doc.less';
import './terms.container.less';
import './protocol.container.less'

export const ProtocolContainer = (props) => {

    useEffect(() => {
        const plist: any = document.querySelectorAll('.doc-container p');
        for (let i = 0; i < plist.length; i++) {
            const height = parseInt(window.getComputedStyle(plist[i]).height)
            if (height >= 30) {
                plist[i].style.textIndent = '2em'
            } else {
                plist[i].style.textIndent = '0em'
            }
        }
    }, [])
    return <div className="doc-container proticol-box">
        <p>本服务等级协议（ServiceLevelAgreement，以下简称“SLA”）规定了焱融SaaS文件存储向客户提供的文件存储的服务可用性等级指标及赔偿方案。</p>
        <br />
        <p><strong>本协议自2022年2月28日起生效。</strong> </p>
        <br />
        <h2>1.定义</h2>
        <p><strong>服务周期：</strong>一个服务周期为一个自然月，统计的业务单元为单个文件系统，如客户使用单个文件系统不满一个月不计算为一个服务周期。</p>
        <p><strong>服务周期总分钟数：</strong>按照服务周期内的总天数╳24（小时）╳60（分钟）计算。</p>
        <span><strong>服务不可用分钟数：</strong>当某一分钟内，客户所有试图与指定的文件系统建立连接的连续尝试均失败，则视为该分钟内该文件系统实例服务不可用。在一个服务周期内单个文件系统不可用分钟数之和即服务不可用分钟数。</span>
        <p><strong>月度服务费用：</strong>客户在一个自然月中就单个文件系统所支付的服务费用总额。</p>
        <p><br /></p>
        <h2>2.服务可用性</h2>
        <p><strong>2.1服务可用性计算方式</strong></p>
        <p>服务可用性以单个文件系统为维度，按照如下方式计算：</p>
        <img src={formula} alt="" />
        <p><strong>2.2服务可用性承诺</strong></p>
        <p>文件存储服务可用性不低于99.90%，如未达到上述可用性承诺，客户可以根据本协议第3条约定获得赔偿。赔偿范围不包括以下原因所导致的服务不可用：</p>
        <p>（1）焱融SaaS文件存储预先通知客户后进行系统维护所引起的，包括但不限于割接、维修、升级和模拟故障演练等；</p>
        <p>（2）任何焱融SaaS文件存储所属设备以外的网络、设备故障或配置调整引起的；</p>
        <p>（3）客户的应用程序受到黑客攻击而引起的；</p>
        <p>（4）客户自行升级操作系统所引起的；</p>
        <p>（5）客户的应用程序或安装活动所引起的；</p>
        <p>（6）客户维护不当或保密不当致使数据、口令、密码等丢失或泄漏所引起的；</p>
        <p>（7）客户的疏忽或由客户授权的操作所引起的；</p>
        <span>（8）客户未遵循焱融SaaS文件存储实时更新的产品使用文档引起的（使用文档包括但不限于焱融SaaS文件存储官网展示的所有帮助文档、控制台引导说明、邮件通知、站内消息通知、短信通知等），如客户未按照使用限制中推荐的操作系统、挂载方式、网络类型访问文件系统引起的；</span>
        <p>（9）客户未遵循焱融SaaS文件存储在运维和服务过程中的指示引起的；</p>
        <p>（10）不可抗力引起的；</p>
        <p>（11）其他非焱融SaaS文件存储原因所造成的不可用。</p>
        <p><br /></p>
        <h2>3.赔偿方案</h2>
        <p><strong>3.1赔偿标准</strong></p>
        <span>根据每个文件系统实例的月度服务可用性，按照下表中的标准计算赔偿金额，赔偿方式仅限于用于购买焱融SaaS文件存储的代金券，且赔偿总额不超过未达到服务可用性承诺当月客户就该文件系统实例支付的月度服务费用（不含用代金券抵扣的费用）。</span>
        <table>
            <thead>
                <tr>
                    <th>服务可用性</th>
                    <th>赔偿代金券金额</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>低于99.90%但等于或高于99.00%</td>
                    <td>月度服务费用的15%</td>
                </tr>
                <tr>
                    <td>低于99.00%但等于或高于95.00%</td>
                    <td>月度服务费用的30%</td>
                </tr>
                <tr>
                    <td>低于95.00%</td>
                    <td>月度服务费用的100%</td>
                </tr>
            </tbody>
        </table>
        <p><strong>3.2赔偿申请时限</strong></p>
        <span>客户可以在每月第五个工作日后对上个月没有达到可用性的服务提出赔偿申请。赔偿申请必须限于在文件系统实例没有达到可用性的相关月份结束后两个月内提出。超出申请时限的赔偿申请将不被受理。</span>
        <p><br /></p>
        <h2>4、其他</h2>
        <span>焱融SaaS文件存储有权对本SLA条款作出修改。如本SLA条款有任何修改，焱融SaaS文件存储将提前30天以网站公示或发送邮件的方式通知您，生效时间为公示后的下一个服务周期即下一个自然月的第一天。如您不同意焱融SaaS文件存储对SLA所做的修改，您有权停止使用文件存储服务，如您继续使用文件存储服务，则视为您接受修改后的SLA。</span>
    </div>
}
