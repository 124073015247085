import { AccountReducer } from "./account.reducer";
import { OrderReducer } from './order.reducer';
import { InvoiceReducer } from './invoice.reducer';
import { BillsReducer } from './bills.reducer';

export const reducers = {
    account: new AccountReducer(),
    order: new OrderReducer(),
    invoice: new InvoiceReducer(),
    bills: new BillsReducer(),
}