import { Dispatch } from 'redux';
import { encrypt } from '../../../helpers/password.helper';
import { Client } from '../../../redux/client';
import { PasswordVariable } from '../../../enums/password-variable.enum';
import { Action } from '../../../redux/action';
import { ACTIVATE, CAPTCHA, LOGIN } from '../constants/action-types.constants';
import {
    ACTIVATE_ACCOUNT,
    SIGN_IN,
    SIGN_UP,
    SIGN_UP_SMS,
    ACTIVATE_ACCOUNT_EMAIL,
    RESET_PASSWORD_SMS,
    RESET_PASSWORD,
} from '../constants/fetch.constants';
import { CreateAccount, ResetPassword } from '../interfaces/login.interface';

export class LoginAction {

    private static client = new Client();

    public static signIn(values, callback: (isError: boolean) => void) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const ciphertext = encrypt(values.password, PasswordVariable.KEY, PasswordVariable.IV);
            // try {
            //     await this.client.post('/apis/auth/sign-in', {
            //         ...values,
            //         password: ciphertext,
            //     }, {
            //         dispatch,
            //         fetch: SIGN_IN,
            //     });
            //     callback(false);
            // } catch (e) {
            //     if (e?.response?.data?.captcha?.isNeed) {
            //         dispatch(new Action(CAPTCHA).put('signIn', { isNeed: e?.response?.data?.captcha?.isNeed }));
            //     }
            //     callback(true);
            // }
            try {
                await this.client.post('/apis/auth/sign-in', {
                    ...values,
                    password: ciphertext,
                }, {
                    dispatch,
                    fetch: SIGN_IN,
                });
                callback(false);
            } catch (e: any) {
                console.log(e?.response?.data)
                if (!e?.response?.data?.isActived && e?.response?.data?.isActived !== undefined) {
                    dispatch(new Action(ACTIVATE).put('isActivated', e?.response?.data?.isActived ));
                    callback(true);
                    return false;
                }
                dispatch(LoginAction.getIsNeedCaptcha());
                callback(true);
            }
        };
    }

    public static getIsNeedCaptcha() {
        return async (dispatch: Dispatch<any>, state: any) => {
            const captcha = await this.client.get('/apis/auth/captcha');
            dispatch(new Action(CAPTCHA).put('signIn', captcha));
        }
    }

    public static signUp(values: CreateAccount, callback: () => void) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const ciphertext = encrypt(values.password, PasswordVariable.KEY, PasswordVariable.IV);
            await this.client.post('/apis/auth/sign-up', {
                ...values,
                password: ciphertext,
                role: 'user',
            }, {
                dispatch,
                fetch: SIGN_UP,
            });
            callback();
        };
    }

    public static sendSignUpSms(phone: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post('/apis/auth/sms/registrar', {
                phone,
            }, {
                dispatch,
                fetch: SIGN_UP_SMS,
            });
        };
    }

    public static sendActivateEmail(email: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post('/apis/auth/email/activation', {
                email,
            }, {
                dispatch,
                fetch: ACTIVATE_ACCOUNT_EMAIL,
            });
        };
    }

    public static activateAccount(code: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post('/apis/auth/account-activate', { code }, {
                dispatch,
                fetch: ACTIVATE_ACCOUNT,
            });
        };
    }

    public static sendResetSms(email: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post('/apis/auth/sms/reset', {
                email,
            }, {
                dispatch,
                fetch: RESET_PASSWORD_SMS,
            });
        };
    }

    public static resetPassword(values: ResetPassword, callback: () => void) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const ciphertext = encrypt(values.password, PasswordVariable.KEY, PasswordVariable.IV);
            await this.client.put('/apis/auth/reset-password', {
                ...values,
                password: ciphertext,
            }, {
                dispatch,
                fetch: RESET_PASSWORD,
            });
            callback();
        };
    }

    public static getAccount(id) {
        return async (dispatch, state) => {
            // const client = new AxiosClient();
            // const body = await client.get(`/apis/accounts/${id}`);
            // console.log(body);
        }
    }

    public static signOut() {
        return async (dispatch, state) => {
            // const client = new AxiosClient();
            // const body = await client.post('/apis/auth/sign-out');
            // console.log(body);
        }
    }

    public static room(id) {
        return async (dispatch, state) => {
            // const client = new AxiosClient();
            // const body = await client.post(`/apis/notification/${id}`, {
            //     data: 'message',
            // });
            // console.log(body)
        }
    }

    public static rooms() {
        return async (dispatch, state) => {
            // const client = new AxiosClient();
            // const body = await client.post('/apis/notification', {
            //     data: 'broadcast',
            // });
            // console.log(body)
        }
    }
}
