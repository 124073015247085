import React from 'react';
import './main.component.less';

export const Main = (props) => {
    return (
        <div className="main-container">
            { props.children }
        </div>
    )
}

