import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PrivacyContainer } from './containers/privacy.container';
import { TermsContainer } from './containers/terms.container';
import { InvoiceContainer } from './containers/invoice.container';
import { ProtocolContainer } from './containers/protocol.container';
import { DocumentContainer } from './containers/document.container';
import { DocumentStorage } from './containers/document.storage';
import { GetBaidu } from '../components/baidu.statistics';
import { SassRuleMobale } from './sass-login/sass.rule'

export const Documents = (props) => {
    return <>
        <GetBaidu>
            <Route path="/console/documents/privacy" component={PrivacyContainer} />
            <Route path="/console/documents/terms" component={TermsContainer} />
            <Route path="/console/documents/invoice" component={InvoiceContainer} />
            <Route path="/console/documents/protocol" component={ProtocolContainer} />
            <Route path="/console/documents/sassrule" component={SassRuleMobale} />
            <Route path="/console/documents/storage" component={DocumentStorage} />

            <Route path="/console/documents/document" exact={true}>
                <Redirect to="/console/documents/document/service-introduction" />
            </Route>
            <Route exact={true} {...props} path="/console/documents/document/:key" component={DocumentContainer} />
        </GetBaidu>
    </>
}
