import { MapReducer } from "../../../redux/reducers/map.reducer";
import { BILLS } from "../constants/action-types.constants";

export class BillsReducer extends MapReducer {
    private static defaults = {
        payment_bills: [], //账单列表 list，
        payment_bills_info: {}, //账单列表 详情
        dosage_list:[], // 用量详情
        invoice_bills: [], // 可开发票账单列表
        code_url:'', // 支付二维码
        
    }
    constructor() {
        super(BILLS, BillsReducer.defaults);
    }
}