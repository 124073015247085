export const UPDATE_ACCOUNT = 'update_account';
export const ACTIVATE_VOUCHER = 'activate_voucher';
export const GET_ORDER = 'get_order';
export const GET_ORDERS = 'get_orders';
export const USE_VOUCHER = 'use_voucher';
export const PAY_ORDER = 'pay_order';
export const GET_BILLS = 'get_bills';
export const GET_VOUCHERS = 'get_vouchers'; // 代金券
export const UPDATE_PHONE = 'update_phone';
export const GET_LATEST_BILLS = 'get_latest_bills';
export const GET_INVOICE_MAKE = 'get_invoice_make'; // 可开发票金额
export const GET_INVOICE_INFO = 'get_invoice_info'; // 发票信息
export const PUT_INVOICE_INFO = 'get_invoice_info'; // 发票信息 提交/修改
export const GET_INVOICE_ADDRESS = 'get_invoice_address'; // 默认邮寄地址
export const LIST_INVOICE_ADDRESS = 'list_invoice_address'; // 默认邮寄地址List
export const SET_INVOICE_ADDRESS = 'set_invoice_address'; // 添加修改邮寄地址
export const GET_INVOICE = 'get_invoice'; // 开票记录
export const PUT_INVOICE_FORM = 'put_invoice_form'; // 提交开票记录
export const GET_BILLS_PAYMENT = 'get_bills_payment'; // 账单
export const GET_BILLS_INFO = 'get_bills_info'; // 账单详情
export const GET_DOSAGE_INFO = 'get_dosage_info'; // 用量详情
export const GET_DOSAGE_INFO_LOAD = 'get_dosage_info_load'; // 用量详情下载
export const GET_BILLS_INVOICE = 'get_bills_invoice'; // 可开发票账单
export const SET_BILLS_VOUCHER = 'set_bills_voucher'; // 使用代金劵
export const GET_CODE_URL = 'get_code_url'; // 微信支付