import { Form, Input, Button, message as Message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom";
import { PasswordIcon, UsernameIcon, VerifyIcon } from "../../components/icons.component";
import { LoginLayout } from "../../components/login-layout.component";
import { LoginAction } from "../actions/login.action";
import './reset-password.container.less';

export const ResetPasswordContainer = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [remaining, updateRemaining] = useState(60);

    useEffect(() => {
        switch (remaining) {
            case 0:
                updateRemaining(60);
                break;
            case 60:
                break;
            default:
                setTimeout(() => {
                    updateRemaining((remaining: number) => remaining - 1)
                }, 1000);
                break;
        };
    }, [remaining]);

    const submit = (values) => {
        dispatch(LoginAction.resetPassword(values, () => {
            Message.success('修改密码成功');
            pushState('/sign-in');
        }));
    };
    const sendSms = () => {
        form.validateFields(['email'])
            .then(values => {
                dispatch(LoginAction.sendResetSms(values.email));
                updateRemaining(59);
            })
            .catch(info => {
                console.log('validate error: ', info);
            });
    };
    const pushState = (url: string) => {
        window.location.hash = url;
    };

    return <LoginLayout>
        <div className="reset-password-container">
            <p className="title">重置密码</p>
            <Form
                form={form}
                onFinish={(values) => submit(values)}
                className="reset-password-form"
            >
                <Form.Item
                    name="email"
                    rules={[
                        { required: true, message: '请输入邮箱' },
                        { type: 'email', message: '请输入正确的邮箱' },
                    ]}
                >
                    <Input placeholder="请输入账号/邮箱" prefix={<UsernameIcon />} />
                </Form.Item>
                <Form.Item
                    name="verify"
                    rules={[{ required: true, message: '请输入验证码' }]}
                    className="verify-item"
                >
                    <Input
                        addonAfter={<Button
                            onClick={() => sendSms()}
                            disabled={remaining !== 60}
                        >
                            {remaining === 60 ? '发送验证码' : `${remaining}秒后重新发送`}
                        </Button>}
                        placeholder="验证码"
                        prefix={<VerifyIcon />}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: '请输入密码' }]}
                >
                    <Input.Password placeholder="请输入密码" prefix={<PasswordIcon />} />
                </Form.Item>
                <Form.Item
                    name="repassword"
                    rules={[
                        { required: true, message: '请再次输入密码' },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('两次输入的密码不一致');
                            }
                        })
                    ]}
                >
                    <Input.Password placeholder="确认密码" prefix={<PasswordIcon />} />
                </Form.Item>
                <Button type="primary" onClick={() => form.submit()} style={{ marginTop: 24 }} className="btn-reset">重置密码</Button>
            </Form>
            <div className="sign-in-tools">
                <p>已有账号？<Link to="/sign-in">立即登录</Link></p>
            </div>
            <div className="mobile-btn-box">
                <Button type="primary" onClick={() => form.submit()} className="btn-reset-mobile">重置密码</Button>
                <Link to="/sign-in" className="btn-login-mobile">立即登录</Link>
            </div>
        </div>
    </LoginLayout>
}
