import { MapReducer } from "./map.reducer";
import { PAGINATION } from "../constants/action-types.constants";

export class PaginationReducer extends MapReducer {
    constructor() {
        super(PAGINATION, {
            order: { total: 0, size: 10, page: 1, length: 0 },
            ticket: { total: 0, size: 10, page: 1, length: 0 },
            instance: { total: 0, size: 10, page: 1, length: 0 },
            voucher: { total: 0, size: 12, page: 1, length: 0 },
            message: { total: 0, size: 10, page: 1, length: 0 },
            invoice: { total: 0, size: 10, page: 1, length: 0 }, // 开票记录
            payBills:{ total: 0, size: 10, page: 1, length: 0 }, // 账单列表
            dosage:{ total: 0, size: 10, page: 1, length: 0 }, // 用量详情
        });
    }
}
