import { CopyOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React, { useState } from "react";
import awsPeeringImg from '../../../asserts/mount/mount_aws_peering.jpeg';
import awsRouteImg from '../../../asserts/mount/mount_aws_route.jpeg';
import { CloseModalIcon, CopyIcon } from "../../components/icons.component";

export const MountStepAwsPerformanceContainer = ({ account, cidr, copyContent, vip, clientId, instanceId }) => {

    const [tip, updateTip] = useState({ visible: false, title: '', pic: '' });

    return <td>
        <p>1、文件系统创建完成后，需要建立对等网络连接</p>
        <p className="content-secondary">
            a)打开<a href="https://cn-northwest-1.signin.amazonaws.cn" target="_blank">AWS</a>
            ——相应区域——VPC控制面板——对等连接。接受平台AWS账户（{account}）发送的对等连接申请。对等连接状态由“正在处理接受”，变为“活动”即可。
        </p>
        <p className="content-secondary">
            b)VPC控制面板——路由表——选中需要挂载文件系统的EC2所在VPC的路由表，在“路由”选项中点击“编辑路由”
            <InfoCircleOutlined onClick={() => updateTip({ visible: true, title: '获取路由', pic: awsRouteImg })} />
            ，添加一条
            <span id="content_cidr">{cidr}</span>
            <a onClick={() => copyContent('cidr')} className="copy-button" title="复制至剪贴板">
                <CopyIcon />
            </a>
            ，指向peering connection（该对等连接）的路由&nbsp;
            <InfoCircleOutlined onClick={() => updateTip({ visible: true, title: '获取对等连接', pic: awsPeeringImg })} />
            。
        </p>
        <p className="content-secondary">c)增加安全组入站规则：UDP&nbsp;&nbsp;7004 - 7014&nbsp;&nbsp;{cidr}</p>
        <p className="content-primary" style={{ marginTop: 10 }}>2、挂载性能型文件系统</p>
        {/* <p className="content-secondary">a)准备工作</p>
        <p className="content-three-level">i.执行以下命令检查kernel-devel以及kernel-headers包版本和kernel版本的一致性</p>
        <code>
            <p>#&nbsp;uname -r</p>
            <p>#&nbsp;rpm -qa | grep kernel</p>
            <p>#&nbsp;rpm -qa | grep "^gcc"</p>
        </code>
        <p className="content-three-level">ii.若缺少以上包，请您下载并执行以下命令安装软件依赖包</p>
        <code>
            <p>下载gcc，kernel-header，kernel-devel</p>
            <p>#yum install gcc</p>
            <p>#yum install kernel-headers-$(uname -r) kernel-devel-$(uname -r)</p>
        </code> */}
        <p className="content-secondary">a)挂载文件系统</p>
        <code>
            <p>
                #&nbsp;
                <span id="content_mount">
                    curl -SL http://{vip}/yr-install.sh | bash -s /{`${instanceId} ${clientId}` as string}
                </span>
                <a onClick={() => copyContent('mount')} className="copy-button" title="复制至剪贴板">
                    <CopyIcon />
                </a>
            </p>
            <p>#&nbsp;df -h</p>
        </code>
        <Modal
            title={tip.title}
            footer={null}
            onCancel={() => updateTip({ visible: false, title: '', pic: '' })}
            visible={tip.visible}
            width={1200}
            closeIcon={<CloseModalIcon />}
            centered={true}
        >
            <img alt={tip.title} style={{ width: '100%', padding: 24 }} src={tip.pic} />
        </Modal>
    </td>;
}
