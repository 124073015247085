
export const StorageIssue = () => {
    return (
        <div>
            <h2>常见问题</h2>
            <b>1、创建文件系统，配置错误怎么处理？</b>
            <p>创建文件系统的时候，需要输入文件系统信息和您AWS的ARN（账户ID）信息，如果您创建文件系统时输入的AWS信息有误系统会提示配置错误。您只需要点击配置错误，在输入框中填入正确的ARN信息。</p>
            <b>2、文件系统退订后还能使用吗？</b>
            <p>用户点击退订后文件系统将不能使用，同时也将停止计费。文件系统退订后无法恢复，请谨慎选择，避免造成损失。</p>
            <b>3、什么是性能加速服务？</b>
            <p>性能加速是焱融SaaS文件存储为用户提供的一项增值服务，开启性能加速后系统将不再对您的文件系统性能做限制，您可解锁文件系统的极限性能。您最高可获得1000MB/s的吞吐量来支撑您的工作负载。 性能加速服务将产生额外的费用，请您谨慎开启。</p>
            <b>4、性能加速服务可以随用随开吗？</b>
            <p>性能加速服务可以随用随开，在需要高性能的时间段开启性能加速服务，在不需要的时间段关系性能加速服务，降低成本。</p>
            <b>5、焱融SaaS文件存储目前支持哪些区域？</b>
            <p>目前焱融SaaS文件存储支持的区域有AWS（cn-northwest-1c）。如果您需要其他区域的服务，请联系我们。</p>
            <b>6、怎么联系我们？</b>
            <p>电话客服：400-090-8865</p>
            <p>提交工单：网站上方——工单——创建工单。</p>
        </div>
    )
}