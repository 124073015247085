import { Modal } from 'antd'
import { State, Modal as ModalInterface, Fetch } from '../../../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { MODIFY_CONFIRM_MODAL } from '../constants/modal.constants';
import { ModalAction } from './../../../redux';
import { MODIFY_CONFIRM_LOADING } from './../constants/fetch.constants'
import './modify-confirm.modal.less';
import { CloseModalIcon } from '../../components/icons.component';

export const ModifyConfirmModal = () => {
    const { visible, ...record } = useSelector<State, ModalInterface>((store) => store.modals[MODIFY_CONFIRM_MODAL] ?? {} as any)
    const { fetching } = useSelector<State, Fetch>((store) => store.fetch[MODIFY_CONFIRM_LOADING] ?? {} as any)
    const dispatch = useDispatch()
    const closeModal = () => {
        dispatch(ModalAction.close(MODIFY_CONFIRM_MODAL))
    }
    const { okText, modalText, modalTitle, onOk = () => closeModal, ...params } = record;
    return <Modal
        visible={visible}
        centered={true}
        wrapClassName='show-footer-first'
        className="confirm-modal"
        onCancel={closeModal}
        title={modalTitle || '对话框'}
        width={600}
        onOk={()=>onOk(params)}
        okText={okText ?? '确定'}
        destroyOnClose={true}
        closeIcon={<CloseModalIcon />}
        confirmLoading={fetching}
    >
        <p>{modalText || '请输入提示文案'}</p>
    </Modal>
}