export enum invoiceType {
    'vat_invoice' = '增值税普通发票',
    'commercial_invoice' = '增值税专用发票',
}
export enum InvoiceTitleType {
    'personal' = '个人',
    'enterprise' = '企业',
    'institutions' = '事业单位',
}
export const defauleInvoiceForm = {
    code: '',  // 统一社会信用代码
    bank: '', // 开户银行名称
    account: '', // 基本开户账号
    registeredAddress: '', // 注册场所地址
    registeredTelephone: '', // 注册固定电话
}