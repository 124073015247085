import {
  Table,
  Button,
  Space,
  Dropdown,
  Menu,
  Tooltip,
  Switch,
} from "antd";
import { PageTypography } from './../../components/page.typography';
import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";
import { NormalIcon, ExitIcon, AnnotaIcon } from './../../components/icons.component'
import { State, File, Fetch, Pagination } from "../../../interfaces";
import { ModalAction } from "../../../redux";
import { InstanceAction } from "../actions/instance.action";
import {
  // UNMOUNT_INSTANCE_MODAL, // 卸载
  MODIFY_CONFIG_MODAL,  // 配置变更
  MODIFY_QUICKEN_MODAL, // 性能加速
  UPDATE_NAME_MODAL, // 更改名称
  MODIFY_CONFIRM_MODAL, // 对话框
} from "../constants/modal.constants";
import "./instances.container.less";
import {
  BillingModeType,
  InstanceStatus,
  NetworkStatus,
  amountSize,
  // ProductType,
} from "../enums";
import { GET_INSTANCES } from "../constants/fetch.constants";
// import { ModifyTypeModal } from "../modals/modify-type.modal";// 变更
import { ModifyConfigModal } from "../modals/modify-config.modal"; // 配置变更
import { ModifyQuickenModal } from "../modals/modify-quicken.modal"; // 性能加速
import { UpdateNameModal } from "../modals/update-name.modal"; // 更改名称
// import { UnmountInstanceModal } from "../modals/unmount-instance.modal"; // 卸载
import { ModifyConfirmModal } from '../modals/modify-confirm.modal'; // 对话框
import { CreateIcon, SettingIcon } from "../../components/icons.component";
import { RefreshButton } from "../../components/refresh-button.component";
import { DefaultTable } from "../../components/default-table.component";
import { Link, useHistory } from "react-router-dom";


export const InstancesContainer = (props) => {
  const history = useHistory()
  const dispatch = useDispatch();
  const [sorter, updateSorter] = useState<{ key?: string, order?: string }>({});
  const instances = useSelector<State, File[]>(
    (state) => state.instance.instances ?? ([] as any)
  );
  const { pagination } = useSelector<State, { pagination: Pagination }>(
    (state) => ({ pagination: state.pagination.instance ?? ({} as any) })
  );
  const { fetching } = useSelector<State, Fetch>(
    (state) => state.fetch[GET_INSTANCES] ?? ({} as any)
  );

  useMount(() => {
    refresh();
  });
  useEffect(() => {
    refresh();
  }, [sorter.key, sorter.order])

  const pushState = (url: string) => {
    window.location.hash = url;
  };
  const refresh = (page: number = pagination.page, size: number = pagination.size) => {
    const params = {
      page,
      size,
      sorterKey: sorter?.key ? sorter.key : undefined,
      sorterOrder: sorter?.order === 'ascend' ? 'ASC' : sorter?.order === 'descend' ? 'DESC' : undefined,
    };
    dispatch(InstanceAction.getAccountInstances({ ...params }));
  };

  const onPageChange = (page: number, size: number) => {
    refresh(page, size);
  }
  // 性能加速switch
  const touchSwitchShift = (checked: boolean, record: any, index: number) => {
    if (checked) { // 开启性能加速
      dispatch(
        ModalAction.open(MODIFY_QUICKEN_MODAL, {
          checked,
          index,
          instanceId: record.id
        }))
    } else { // 关闭性能加速
      dispatch(
        ModalAction.open(MODIFY_CONFIRM_MODAL,
          {
            okText: '关闭性能加速',
            modalTitle: '关闭性能加速',
            modalText: '您是否要关闭性能加速，关闭后将不再计费。',
            onOk: () => { dispatch(InstanceAction.setPerformanceAtion({ checked, index, instanceId: record.id })) }
          })
      )
    }
  }
  // 退订
  const closeExit = (id) => {
    console.log('退订----->');
    dispatch(InstanceAction.unInstance(id));
    // dispatch(ModalAction.close(MODIFY_CONFIRM_MODAL));
  }
  // 删除
  const closeRemove = (id) => {
    console.log('删除----->');
    dispatch(InstanceAction.deleteInstance(id));
    // dispatch(ModalAction.close(MODIFY_CONFIRM_MODAL));
  }
  return (
    <div className="instances-container">
      <PageTypography title='文件系统'>焱融SaaS文件存储，是构建在公有云基础架构上的NAS共享的数据服务平台，为用户提供更快，更便宜，更灵活的数据共享服务。可选POSIX文件接口或者标准NFS共享接口，满足不同应用场景下的多样化存储需求。</PageTypography>
      <div className="instances">
        <Space>
          <Button
            className="btn-primary-icon"
            type="primary"
            icon={<CreateIcon />}
            onClick={() => pushState("/console/instances/create-instance")}
          >
            创建文件系统
          </Button>
          <RefreshButton onClick={() => refresh()} />
        </Space>
        <Table
          dataSource={instances}
          className="table"
          loading={fetching}
          rowKey={(record) => record.id}
          pagination={{
            total: pagination.total,
            defaultPageSize: pagination.size,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100"],
            current: pagination.page,
            onChange: onPageChange as any,
          }}
          locale={{
            emptyText: () => <DefaultTable />,
          }}
          onChange={(_pagination, _filters, sorter: any) => {
            updateSorter({ key: sorter?.column ? sorter?.columnKey : undefined, order: sorter?.order });
          }}
        >
          <Table.Column
            title="名称"
            key="name"
            render={(record) => {
              const { status, name, id } = record;
              const text = name.length > 10 ? `${name.slice(0, 10)}...` : name;
              return status === 'unsubscribe' ?
                <span>{text}</span>
                :
                <Link to={`/console/instances/detail/${id}`}>{text}</Link>
            }}
          />
          <Table.Column
            title="服务器区域"
            key="region"
            render={(record) =>
              `${record?.region?.platformName}/${record?.region?.name}`
            }
          />
          {/* <Table.Column
            title="存储类型"
            key="type"
            dataIndex="type"
            render={(text) => {
              return ProductType[text];
            }}
          /> */}
          <Table.Column
            title="状态"
            key="status"
            dataIndex="status"
            render={(text, record: any) => {
              return (
                <span className='table-label-status'>
                  {(text.includes('normal') || text.includes('creating')) ?
                    <NormalIcon /> // 正常
                    :
                    text.includes("unsubscribe") ?
                      <ExitIcon /> // 以退订
                      :
                      <Tooltip title={<div className="tooltip-setconfig">
                        状态异常，请您通过工单联系工作人员解决，<span onClick={() =>
                          history.push('/console/tickets/submit')
                        }>去提工单</span>
                      </div>}
                        color="rgba(5, 30, 50, .7)">
                        <AnnotaIcon />
                      </Tooltip> // 异常

                  }
                  {InstanceStatus[text] ?? text}
                </span>
              );
            }}
          />
          <Table.Column
            title="配置状态"
            key="networkStatus"
            dataIndex="networkStatus"
            render={(text, record) => {
              return (
                <span className={`table-label ${text}-label`}>
                  {text ? NetworkStatus[text] : "配置中"}&nbsp;
                  {text === "error" ? (
                    <Tooltip
                      title={<div className="tooltip-setconfig">
                        配置状态有误，<span onClick={() =>
                          dispatch(
                            ModalAction.open(MODIFY_CONFIG_MODAL, record)
                          )
                        }>去修改</span>
                      </div>}
                      color="rgba(5, 30, 50, .7)"
                    >
                      <div className="annotation-icon"></div>
                    </Tooltip>
                  ) : null}
                </span>
              );
            }}
          />
          <Table.Column
            title="创建时间"
            key="createdAt"
            dataIndex="createdAt"
            sorter={true}
            render={(text) => {
              return moment(text).format("YYYY-MM-DD");
            }}
          />
          {/* <Table.Column
            title="过期时间"
            key="expireTime"
            dataIndex="expireTime"
            sorter={true}
            render={(text) => {
              if (!text) {
                return "--";
              }
              let status = "";
              const expireTime = moment(text).unix();
              if (expireTime - now < 24 * 60 * 60 * 3) {
                status = "will-expire";
              }
              return (
                <span className={`table-label ${status}-label`}>
                  {moment(text).format("YYYY-MM-DD")}
                </span>
              );
            }}
          /> */}
          <Table.Column
            title="付费方式"
            key="billingMode"

            render={(record) => {
              return `${BillingModeType[record.billingMode]}`;
            }}
          />
          <Table.Column
            title="性能加速"
            key="amount"
            dataIndex='amount'
            align="center"
            render={(_o, record: any, index) => {
              const amount = record.items[0].amount > amountSize.close
              const { status } = record;
              return <Switch size='small' disabled={!['normal', 'upgrade_error', 'upgrading'].includes(status)} checked={amount} onClick={(checked) => { touchSwitchShift(checked, record, index) }} />
            }}
          />
          <Table.Column
            className="column-operation"
            title="操作"
            key="operation"
            render={(record) => {
              const { status, id } = record;
              // const { billingMode, expireTime, type, networkStatus } = record;
              // const expire = moment(expireTime).valueOf();
              return (
                <Dropdown
                  placement="bottom"
                  overlay={() => (
                    <Menu style={{ cursor: "pointer" }}>
                      {status !== 'unsubscribe' &&
                        <Menu.Item
                          key={'更改名称'}
                          onClick={() =>
                            dispatch(ModalAction.open(UPDATE_NAME_MODAL, record))
                          }
                        >更改名称</Menu.Item>
                      }
                      {status !== 'unsubscribe' &&
                        <Menu.Item
                          key={'退订'}
                          onClick={() =>
                            dispatch(
                              ModalAction.open(MODIFY_CONFIRM_MODAL,
                                {
                                  okText: '退订',
                                  modalTitle: '退订',
                                  modalText: '退订后将不在为您保存数据，请您谨慎选择是否退订',
                                  onOk: () => { closeExit(id) }
                                })
                            )
                          }
                        >退订</Menu.Item>
                      }
                      {status === 'unsubscribe' &&
                        <Menu.Item
                          key={'删除'}
                          onClick={() =>
                            dispatch(ModalAction.open(MODIFY_CONFIRM_MODAL,
                              {
                                okText: '删除',
                                modalTitle: '删除',
                                modalText: '您是否确定要删除该文件系统',
                                onOk: () => { closeRemove(id) }
                              }
                            ))
                          }
                        >删除</Menu.Item>
                      }
                      {/* {expire > now ? (
                        <Menu.Item
                          onClick={() =>
                            pushState(
                              `/console/instances/upgrade-capacity/${record.id}`
                            )
                          }
                        >扩容</Menu.Item>
                      ) : null} */}

                      {/* {type !== "fs.capacity" && expire && expire > now ? (
                        <Menu.Item
                          onClick={() =>
                            pushState(
                              `/console/instances/upgrade-bandwidth/${record.id}`
                            )
                          }
                        >扩容带宽</Menu.Item>
                      ) : null} */}
                      {/* {billingMode === "prepay" ? (
                        <Menu.Item
                          onClick={() =>
                            pushState(
                              `/console/instances/renew-instance/${record.id}`
                            )
                          }
                        >续费</Menu.Item>
                      ) : null} */}

                      {/* {record?.region?.platform === 'aws' ? <Menu.Item
                        onClick={() =>
                          // dispatch(ModalAction.open(MODIFY_TYPE_MODAL, record))
                          pushState(
                            `/console/instances/modify-type/${record.id}`
                          )
                        }
                      >变更</Menu.Item> : null} */}
                      {/* {billingMode === "afterpay" ||
                        (billingMode === "prepay" && expire > now) ? null : (
                        <Menu.Item
                          onClick={() =>
                            dispatch(
                              ModalAction.open(UNMOUNT_INSTANCE_MODAL, record)
                            )
                          }
                        >卸载</Menu.Item>
                      )} */}
                    </Menu>
                  )}
                >
                  <SettingIcon />
                </Dropdown>
              );
            }}
          />
        </Table>
      </div>
      {/* 变更 */}
      {/* <ModifyTypeModal /> */}
      {/* 配置变更 */}
      <ModifyConfigModal />
      {/* 性能加速 */}
      <ModifyQuickenModal />
      {/* 更改名称 */}
      <UpdateNameModal />
      {/* 卸载 */}
      {/* <UnmountInstanceModal />  */}
      {/* 对话框 */}
      <ModifyConfirmModal />
    </div>
  );
};
