// import { useState } from 'react';
import { Form, Radio, Select, message as Message,  Button } from 'antd';
// import { PageTypography } from './../../components/page.typography';
import { CardNav } from './../../components/card.navigation'
import './experience.applying.container.less';
import { useDispatch, useSelector } from 'react-redux';
import { ExperienceAction } from '../actions/experience.action';
import { useUnmount } from 'react-use';
import {Fetch, State} from "../../../interfaces";
import {CREATE_EXPERIENCE_APPLY} from "../constants/fetch.constants";

export const ExperienceContainer = () => {
    // const [hasCloudResource, updateHasCloudResource] = useState();
    // const [useCloudStorage, updateUseCloudStorage] = useState();
    // const [career, updateCareer] = useState<string>('');
    // const [usage, updateUsage] = useState<string>('');
    // const [isLongTerm, updateIsLongTerm] = useState<string>('');
    const { accountId } = useSelector<State, any>(state => state.session);
    const { fetching } = useSelector<State, Fetch>(
        (state) => state.fetch[CREATE_EXPERIENCE_APPLY] ?? ({} as any)
    );
    const { Option } = Select;
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useUnmount(() => {
        form.resetFields();
    });
    const submit = (values) => {
        const params = {
            ...values,
            accountId,
            stage: 'apply-ok',
        };
        dispatch(ExperienceAction.hasExperienceApply(accountId, (has) => {
            if (has) {
                Message.success('已经申请完成，请在3个工作日之内查看邮件结果！');
            } else {
                dispatch(ExperienceAction.createExperienceApply(params, (evt) => {
                    Message.success('感谢申请，请在3个工作日之内查看邮件结果！', 3);
                    window.location.hash = '/console/instances';
                }));
            }
        }));
    };

    // const pushState = (url: string) => window.location.hash = url;

    return <div className="experience-submit-container">
        {/* <PageTypography title='申请成为体验官'>欢迎申请成为焱融体验官；之后，焱融科技将进行审核申请，请关注邮箱内容。</PageTypography> */}
        {/*<Button icon={<LeftOutlined />} type="link" className="card-back" onClick={() => pushState('/console/experience')} />*/}
        <CardNav title='欢迎申请成为焱融SaaS文件存储体验官；3个工作日内，焱融科技将进行审核申请，请关注邮箱内容。'>
            <Form
                form={form}
                onFinish={(values) => submit(values)}
                layout="vertical"
                colon={false}
                className="form"
            >
                <Form.Item className="form-item" hasFeedback name="hasCloudResource" label="1.您是否使用过AWS/阿里云资源"
                           rules={[{ required: true, message: '请选择是否使用过!' }]}>
                    <Radio.Group>
                        <Radio value={true}>是</Radio>
                        <Radio value={false}>否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item hasFeedback name="useCloudStorage" label="2.您是否使用过云盘/NAS/对象存储"
                           rules={[{ required: true, message: '请选择是否使用过!' }]}>
                    <Radio.Group>
                        <Radio value={true}>是</Radio>
                        <Radio value={false}>否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="career"
                    label="3.您的职业"
                    hasFeedback
                    rules={[{ required: true, message: '请选择您的职业!' }]}
                >
                    <Select placeholder="请选择您的职业">
                        <Option value="开发测试工程师">开发测试工程师</Option>
                        <Option value="运维工程师">运维工程师</Option>
                        <Option value="项目/产品经理">项目/产品经理</Option>
                        <Option value="企业管理层">企业管理层</Option>
                        <Option value="事业单位/公务员/政府工作人员">事业单位/公务员/政府工作人员</Option>
                        <Option value="学生">学生</Option>
                        <Option value="其它">其它</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="usage"
                    label="4.您所使用的公有云资源用途"
                    hasFeedback
                    rules={[{ required: true, message: '请选择您所使用的公有云资源用途!' }]}
                >
                    <Select placeholder="请选择您所使用的公有云资源用途">
                        <Option value="DEV/OPS">DEV/OPS</Option>
                        <Option value="网站">网站</Option>
                        <Option value="OA">OA</Option>
                        <Option value="备份">备份</Option>
                        <Option value="公司应用">公司应用</Option>
                        <Option value="其它">其它</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="isLongTerm" label="5.您是否愿意成为SaaS数据服务长期体验官"
                           hasFeedback
                           rules={[{ required: true, message: '请选择是否愿意!' }]}>
                    <Radio.Group>
                        <Radio value={true}>是</Radio>
                        <Radio value={false}>否</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
            <div className="form-footer">
                <Button type="primary" loading={fetching} onClick={() => form.submit()}>申请成为体验官</Button>
            </div>
        </CardNav>
    </div>
}
