import { Typography } from 'antd';

interface Iprops {
    title: string;
    children?: string | any;
}

export const PageTypography = (props: Iprops) => {
    const { title, children } = props;
    return (
        <Typography className="page-header">
            <Typography.Title className="caption">{title}</Typography.Title>
            <Typography.Paragraph className="description">{children}</Typography.Paragraph>
        </Typography>
    )
}