import { combineReducers } from "../combine-reducers";
import { ModalReducer } from './modal.reducer';
import { FetchReducer } from './fetch.reducer';
import { PaginationReducer } from './pagination.reducer';
import { reducers as loginReducers } from '../../modules/login/reducers';
import { reducers as accountReducers } from '../../modules/account/reducers';
import { reducers as instanceReducers } from '../../modules/instance/reducers';
import { reducers as ticketReducers } from '../../modules/ticket/reducers';
import { reducers as messageReducers } from '../../modules/message/reducers';
import { reducers as documentReducer } from '../../modules/document/reducers';
import { SessionReducer } from "./session.reducer";

export default combineReducers({
    modals: new ModalReducer(),
    fetch: new FetchReducer(),
    pagination: new PaginationReducer(),
    session: new SessionReducer(),
    ...loginReducers,
    ...accountReducers,
    ...instanceReducers,
    ...ticketReducers,
    ...messageReducers,
    ...documentReducer,
});
