import { Dispatch } from 'redux';
import { Action, Client, ModalAction, PaginationAction, SessionAction } from '../../../redux';
import { State } from '../../../interfaces';
import { ACCOUNT, INSTANCE } from '../constants/action-types.constants';
import { UPDATE_ACCOUNT, ACTIVATE_VOUCHER, GET_BILLS, GET_VOUCHERS, UPDATE_PHONE, GET_LATEST_BILLS } from '../constants/fetch.constants';
import { ACTIVATE_VOUCHER_MODAL, UPDATE_PHONE_MODAL } from '../constants/modal.constants';

export class AccountAction {
    private static client = new Client();

    public static getAccount() {
        return async (dispatch: Dispatch<any>, state: State) => {
            const account = await this.client.get('/apis/account');
            dispatch(new Action(ACCOUNT).put('info', account));
        };
    }

    public static updateAccount(values, callback: () => void) {
        return async (dispatch: Dispatch<any>, state: State) => {
            await this.client.put('/apis/update-account', {
                ...values
            }, {
                fetch: UPDATE_ACCOUNT,
                dispatch,
            });
            dispatch(this.getAccount());
            callback();
        };
    }

    // public static removeAccount() {
    //     return async (dispatch: Dispatch<any>, state: State) => {
    //         await this.client.delete('/apis/accounts');
    //     }
    // }

    public static getBalance() {
        return async (dispatch: Dispatch<any>, state: State) => {
            const { balance } = await this.client.get('/apis/wallet/balances');
            dispatch(new Action(ACCOUNT).put('balance', balance.balance));
        };
    }
    // 回掉函数自己添加-----
    public static getBills(params = {}, callback?: (total) => void) {
        return async (dispatch: Dispatch<any>, state: State) => {
            const { bills, total } = await this.client.get('/apis/bills', {
                params,
                dispatch,
                fetch: GET_BILLS,
            });
            dispatch(new Action(ACCOUNT).put('bills', bills));
            callback && callback(total)
        }
    }

    public static getLatestBills() {
        return async (dispatch: Dispatch<any>, state: State) => {
            const { bills } = await this.client.get('/apis/latest-bills', {
                dispatch,
                fetch: GET_LATEST_BILLS,
            });
            dispatch(new Action(ACCOUNT).put('latestBills', bills))
        }
    }

    public static getVouchers(page?: number, size?: number, status?: string) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            const pagination = state().pagination['voucher'];
            page = page ?? pagination.page;
            size = size ?? pagination.size;
            const { list, total } = await this.client.get('/apis/vouchers', {
                params: { page, size, status },
                dispatch,
                fetch: GET_VOUCHERS,
            });
            dispatch(new Action(ACCOUNT).put('vouchers', list));
            dispatch(PaginationAction.update('voucher', page, size, total, list.length));
        }
    }

    public static getOrderVouchers(orderId: string) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            const { vouchers } = await this.client.get(`/apis/orders/${orderId}/vouchers`, {
                dispatch,
                fetch: GET_VOUCHERS,
            });
            dispatch(new Action(ACCOUNT).put('vouchers', vouchers));
        }
    }

    public static activateVoucher(voucherId: string) {
        return async (dispatch: Dispatch<any>, state: State) => {
            await this.client.put(`/apis/vouchers/${voucherId}/actions/activate`, {}, {
                dispatch,
                fetch: ACTIVATE_VOUCHER,
            });
            dispatch(this.getVouchers(1));
            dispatch(ModalAction.close(ACTIVATE_VOUCHER_MODAL));
        }
    }

    public static sendResetPhoneSms() {
        return async (dispatch: Dispatch<any>, state: any) => {
            const accountId = state().session.accountId ?? {} as any;
            await this.client.post('/apis/auth/sms/update-phone', {
                accountId
            }, {});
        }
    }

    public static updatePhone(values, callback) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const accountId = state().session.accountId ?? {} as any;
            await this.client.put('/apis/auth/update-phone', {
                accountId,
                ...values,
            }, {
                dispatch,
                fetch: UPDATE_PHONE,
            });
            callback();
            dispatch(ModalAction.close(UPDATE_PHONE_MODAL));
            dispatch(AccountAction.getAccount());
            dispatch(SessionAction.getSession());
        };
    }

    public static sendRemoveAccountSms() {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post('/apis/auth/sms/account-destroy', {}, {});
        };
    }

    public static removeAccount(values: { code: string }, callback: () => void) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post('/apis/destroy-account', {
                ...values,
            }, {});
            callback();
        };
    }

    public static getInstances(page: number, size: number) {
        return async (dispatch: Dispatch<any>, state: State) => {
            const { list, total } = await this.client.get('/apis/instances', {
                params: { page, size },
            });
            dispatch(new Action(INSTANCE).put('instances', list));
            dispatch(PaginationAction.update('instance', page, size, total, list.length));
        };
    }
}
