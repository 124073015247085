import { Button, Pagination, Spin, Space } from 'antd';
import { PageTypography } from './../../components/page.typography';
import { CardNav } from './../../components/card.navigation'
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import moment from 'moment';
import { Fetch, Pagination as PaginationInterface, State } from '../../../interfaces';
import { ModalAction } from '../../../redux';
import { AccountAction } from '../actions';
import { ACTIVATE_VOUCHER_MODAL } from '../constants/modal.constants';
import { ActivateVoucherModal } from '../modals/activate-voucher.modal';
import './voucher.container.less';
import { GET_VOUCHERS } from "../constants/fetch.constants";
import { RefreshButton } from '../../components/refresh-button.component';
import { DefaultTableText } from '../../components/default-table.component';
import { LightIcon } from '../../components/icons.component';
import { ProductType } from '../enums';

export const VoucherContainer = (props) => {
    const dispatch = useDispatch();

    const vouchers = useSelector<State, any>(
        state => state.account.vouchers ?? [] as any,
    );
    const { pagination } = useSelector<State, { pagination: PaginationInterface }>(
        state => ({ pagination: state.pagination.voucher ?? {} as any }),
    );
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_VOUCHERS] ?? {});
    useMount(() => refresh());

    const refresh = () => dispatch(AccountAction.getVouchers(pagination.page, pagination.size));
    const onPageChange = (page: number, size: number) => dispatch(AccountAction.getVouchers(page, size));

    return <div className="voucher-container">
        <PageTypography title='费用'>您可以在费用页面查看您的账户余额和代金券剩余情况，还可查看费用账单和订单信息。</PageTypography>
        <CardNav title='代金券' link={-1}>
            <div className="vouchers">
                <Space>
                    <Button className="btn-primary-icon" type="primary" icon={<LightIcon />} onClick={() => dispatch(ModalAction.open(ACTIVATE_VOUCHER_MODAL))}>激活代金券</Button>
                    <RefreshButton onClick={() => refresh()} style={{ marginLeft: 8 }} />
                </Space>
                {vouchers.length === 0 ? <DefaultTableText type="voucher" /> : <Spin spinning={fetching}>
                    <ul>
                        {vouchers.map((voucher) => {
                            console.log(Number(voucher?.balance) !== 0)
                            return <li className="voucher" key={voucher?.id}>
                                <div className="voucher-header">
                                    <p className="available">{voucher?.balance}</p>
                                    <p className="available-label">可用额度</p>
                                    <p className="total">{`总额度：${voucher?.amount}`}</p>
                                    <p className="status">
                                        {(voucher?.status === 'available' ||
                                            (voucher?.status === 'used' && voucher?.canUsage === -1))
                                            && Number(voucher?.balance) !== 0
                                            ? '可用' : '不可用'}
                                    </p>
                                </div>
                                <div className="voucher-content">
                                    <p>{`代金券编号：${voucher?.id}`}</p>
                                    <p>{`有效期至：${moment(voucher?.endTime).format('YYYY-MM-DD')}`}</p>
                                    <p>
                                        {voucher?.product ? `限制${ProductType[voucher?.product]}文件存储使用` : ''}
                                        {voucher?.product && voucher?.canUsage === 1 ? '，' : ''}
                                        {voucher?.canUsage === 1 ? '只能使用1次' : ''}
                                        {!voucher?.product && voucher?.canUsage === -1 ? '无限制' : ''}
                                    </p>
                                </div>
                            </li>
                        })}
                    </ul>
                </Spin>}
            </div>
            {vouchers.length === 0 ? null : <Pagination
                total={pagination.total}
                defaultPageSize={pagination.size}
                current={pagination.page}
                onChange={onPageChange as any}
                style={{ float: 'right' }}
            />}
        </CardNav>
        <ActivateVoucherModal />
    </div>
}
