import { Dispatch } from 'redux';
import { Client } from '../../../redux';
import { InstanceAction } from '../../instance/actions/instance.action';
import { USE_VOUCHER, PAY_ORDER } from '../constants/fetch.constants';
import { OrderAction } from './order.action';

export class PaymentAction {

    private static client = new Client();

    public static toUseVoucher(orderId: string, voucherIds: string[], callback) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/apis/orders/${orderId}/actions/discount`, {
                voucherIds,
            }, {
                dispatch,
                fetch: USE_VOUCHER,
            });
            dispatch(OrderAction.getOrder(orderId));
            callback();
        }
    }

    public static payOrder(orderId: string, callback) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/apis/orders/${orderId}/actions/pay`, {}, {
                fetch: PAY_ORDER,
                dispatch,
            });
            callback();
            const pagination = state().pagination.instance ?? {} as any;
            dispatch(InstanceAction.getInstances(pagination.page, pagination.size));
        }
    }
}