import { MODAL } from '../constants/resource.constants';
import { Action } from '../action';

export class ModalAction {
    public static open<T extends any>(modal: string | number, data?: T) {
        return new Action(MODAL).put(modal, { visible: true, ...(data as object ?? {}) });
    }

    public static close(modal: string | number) {
        return new Action(MODAL).put(modal, { visible: false });
    }
}