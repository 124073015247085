import { useState, useEffect } from 'react'
import { useMount } from 'react-use';
import { Table, Button } from 'antd'
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { PageTypography } from '../../components/page.typography'
import { CardNav } from '../../components/card.navigation'
import { State, Pagination, Fetch } from './../../../interfaces'
import { BillsAction } from './../actions'
import { BillStatus } from './../enums'
import { tofixed } from './../../../utils';
import { GET_BILLS_PAYMENT } from './../constants/fetch.constants'
import { DefaultTable } from '../../components/default-table.component';
import './new.bill.container.less'
import './new.order.title.less';

export const NewBillContainer = () => {
    const history = useHistory();
    const dispatch = useDispatch()
    const { pagination } = useSelector<State, { pagination: Pagination }>(
        (state) => ({ pagination: state.pagination.payBills ?? ({} as any) }))
    const { email } = useSelector<State, any>(state => state.session);
    const payment_bills = useSelector<State, any>(state => state.bills.payment_bills ?? [] as any);
    const [historyList, setHistoryList] = useState([]); // 历史账单
    const [outstandingList, setOutstandingList] = useState([]); // 代付款账单
    // 账单状态
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_BILLS_PAYMENT] ?? {} as any);
    // 分页
    const onPageChange = (page: number, size: number) => {
        refresh(page, size);
    }
    //初始化函数
    const refresh = (page: number = pagination.page, size: number = pagination.size) => {
        // 账单
        dispatch(BillsAction.getPayBills(page, size));
    }
    // 初始化
    useMount(() => {
        refresh();
    })
    // 
    useEffect(() => {
        if (payment_bills.length) {
            setHistoryList(payment_bills.filter(item => item.status === "cleared"));
            setOutstandingList(payment_bills.filter(item => ['unsettled', 'unclear'].includes(item.status)));
        }
    }, [payment_bills])
    return (
        <div className="bill-container">
            <PageTypography title='费用'>您可在此页面查看您的账单明细。</PageTypography>
            {/* 代付款账单 */}
            <CardNav title='待付账单' className={outstandingList.length ? '' : 'show-bills'}>
                {
                    outstandingList.map((item: any) => (
                        <div key={item.id} className="outstanding-box">
                            <div className="info-title box order-title">
                                <div className="info-title-item">
                                    <span>账单：</span>
                                    <Link to={`/console/account/bill/${item.id}`}>{item.billingCycle}</Link>
                                </div>
                                <div className="info-title-item">
                                    <span className='width98'>账号：</span>
                                    <b>{email}</b>
                                </div>
                                <div className="info-title-item">
                                    <span>出账日期：</span>
                                    <b>{moment(item.createdAt).format('YYYY-MM-DD')}</b>
                                </div>
                                <div className="info-title-item">
                                    <span className='width98'>最晚还款日期：</span>
                                    <b>{moment(item.billingCycle).add(1, 'M').add(14, 'd').format('YYYY-MM-DD')}</b>
                                </div>
                                <div className="info-title-item">
                                    <span>状态：</span>
                                    <b>{BillStatus[item.status]}</b>
                                </div>
                            </div>
                            <div className='footer-pay'>
                                <div className="footer-pay-price">
                                    <span>消费金额：<b>{tofixed(parseFloat(item.originalAmount) - parseFloat(item.roundAmount))}元</b></span>
                                    <span>欠费金额：<b>{tofixed(item.amountDue)}元</b></span>
                                </div>
                                <Button
                                    type="primary"
                                    onClick={() => { history.push(`/console/account/payment/${item.id}`) }}
                                    className="btn-submit">
                                    去付款
                                </Button>
                            </div>
                        </div>
                    ))
                }
            </CardNav>
            {/* 历史账单 */}
            <CardNav title='历史账单' className="bill-history">
                <Table
                    dataSource={historyList}
                    rowKey='id'
                    className="bill-table"
                    loading={fetching}
                    pagination={{
                        total: pagination.total,
                        defaultPageSize: pagination.size,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '50', '100'],
                        current: pagination.page,
                        onChange: onPageChange as any
                    }}
                    locale={{
                        emptyText: () => <DefaultTable />,
                    }}
                >
                    <Table.Column title="账期" dataIndex="billingCycle" render={(text, record: Record<string, any>) => (
                        <Link to={`/console/account/bill/${record.id}`}>{text}</Link>
                    )} />
                    <Table.Column title="账号" render={() => email} />
                    <Table.Column title="出账日期" dataIndex="createdAt" render={(text) => moment(text).format('YYYY-MM-DD')} />
                    <Table.Column title="消费金额" dataIndex="originalAmount" render={(text, record: any) => tofixed(parseFloat(text) - parseFloat(record.roundAmount))} />
                    <Table.Column title="应付金额" dataIndex="amountDue" render={(text) => tofixed(text)} />
                    <Table.Column title="还款日期" dataIndex="paidAt" render={(text) => moment(text).format('YYYY-MM-DD')} />
                    <Table.Column title="状态" dataIndex="status" render={(text) => BillStatus[text]} />
                </Table>
            </CardNav>
        </div>
    )
}