import { Button, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMount, useUnmount } from 'react-use';
import successIcon from '../../../asserts/success-icon.svg';
import { Fetch, State } from '../../../interfaces';
import { LoginLayout } from '../../components/login-layout.component';
import { LoginAction } from '../actions/login.action';
import { ACTIVATE_ACCOUNT } from '../constants/fetch.constants';
import logo from '../../../asserts/logo_black.png';
import './activation.container.less';

let timer;

export const Activation = (props) => {
    const dispatch = useDispatch();
    const activationCode = props?.match?.params?.activationCode;
    const [remaining, updateRemaining] = useState(10);

    const { fetching } = useSelector<State, Fetch>(
        state => state.fetch[ACTIVATE_ACCOUNT] ?? {} as any,
    );

    useMount(() => {
        dispatch(LoginAction.activateAccount(activationCode));
    });
    useUnmount(() => {
        clearTimeout(timer);
    });
    useEffect(() => {
        if (!fetching) {
            updateRemaining(9);
        }
    }, [fetching]);
    useEffect(() => {
        switch (remaining) {
            case 0:
                updateRemaining(10);
                // pushState('/sign-in');
                break;
            case 10:
                break;
            default:
                setTimeout(() => {
                    updateRemaining((remaining: number) => remaining - 1);
                }, 1000);
                break;
        }
    }, [remaining]);

    const pushState = (url: string) => {
        window.location.hash = url;
    };

    return <div className="activation-container">
        <div className="header">
            <img src={logo} alt="logo" />
            <p>已有账号？<Link to="/sign-in">立即登录</Link></p>
        </div>
        <div className="success-box">
            <div className="success-icon"></div>
            <p className="success-content">账户激活成功！</p>
            <p className="activate-content">{remaining}s后跳转至登录页 </p>
            <Button
                onClick={() => pushState('/sign-in')}
                type="primary"
                className="success-btn"
            >
                去登录
            </Button>
        </div>
    </div>

    return <LoginLayout className="activation-container">
        <Spin spinning={fetching} style={{ width: '100%', height: '100%' }}>
            <div className="success-icon">
                <img src={successIcon} alt="success"/>
            </div>
            <p className="success-content">账户激活成功！</p>
            <p className="success-activation">{remaining}s后跳转至登录页</p>
            <Link
                to="/sign-in"
                className="link-button"
            >
                去登录 &gt;
            </Link>
        </Spin>
    </LoginLayout>
}
