import { ADD, DELETE, INIT, PUT } from "../constants/verb.constants";
import { Action } from "../../interfaces/action.interface";
import { set } from 'lodash';

export class MapReducer<M = any, A = any> {
    constructor(
        public readonly type: string,
        public readonly model: M,
    ) {
    }

    [INIT](state: M = this.model, action: Action<A>) {
        const newState: any = { ...state, ...action.value };
        return newState;
    }

    [ADD](state: M = this.model, action: Action<A>) {

    }

    [PUT](state: M = this.model, action: Action<A>) {
        const newState: any = { ...state };
        // @ts-ignore
        set(newState, action.key, action.value);
        return newState;
    }

    [DELETE](state: M = this.model, action: Action<A>) {
        const newState: any = { ...state };
        // @ts-ignore
        set(newState, action.key, undefined);
        return newState;
    }
}
