import { MapReducer } from '../../../redux/reducers/map.reducer';
import { MESSAGE } from '../constants/action-types.constants';

export class MessageReducer extends MapReducer {
    private static defaults = {
        messages: [],
        message: {},
        unreadMessages: [],
        sumUnread: 0,
    }

    constructor() {
        super(MESSAGE, MessageReducer.defaults);
    }
}