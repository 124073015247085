import { message as Message, Skeleton, Radio, Layout, Tooltip } from 'antd';
import { PageTypography } from './../../components/page.typography'
import { CardNav } from './../../components/card.navigation'
import * as _ from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { InstanceAction } from '../actions/instance.action';
import { Fetch, State } from "../../../interfaces";
import { GET_INSTANCE } from "../constants/fetch.constants";
import { Line } from '../components/line.component';
import { amountSize } from './../enums';
import './instance-detail.container.less';
import { MountStepAwsPerformanceContainer } from "./mount-step-aws-performance.container";
import { MountStepAwsCapacityContainer } from "./mount-step-aws-capacity.container";
import { MountStepAwsFusedContainer } from "./mount-step-aws-fused.container";
import { MountStepTencentPerformanceContainer } from "./mount-step-tencent-performance.container";
import { MountStepAliyunCapacityContainer } from "./mount-step-aliyun-capacity.container";

// const sizeItems = ['fs.capacity.size', 'fs.performance.size', 'fs.fused.size'];
const monitorTypes = {
    'capacity': ['capacity', 'inode'],
    'performance': ['bandwidth', 'iops'],
};

export const InstanceDetailContainer = (props) => {
    const instanceId = props?.match?.params?.instanceId;
    const dispatch = useDispatch();

    const instance = useSelector<any, any>(
        state => state.instance.instance ?? {} as any,
    );
    const privateLink = _.find(instance?.cluster?.metas, { key: 'private_link' } ?? {})?.value;
    const instanceClientId = (_.find(instance?.metadata, { key: 'client_id' }) ?? {}).value ?? '';
    const platform = (_.find(instance?.metadata, { key: 'vendor' }) ?? {}).value ?? '';
    const instanceAwsAccount = (_.find(instance?.cluster?.metas, { key: 'aws_account' }) ?? {}).value ?? '';
    const instanceMountVip = (_.find(instance?.cluster?.metas, { key: 'mount_vip' }) ?? {}).value ?? '';
    // const instanceSizeItem = instance?.items?.filter(item => sizeItems.includes(item.type))[0];
    const isAmountClose = (_.find(instance?.items, { type: 'fs.capacity.bandwidth' }) ?? {}).amount ?? ''; // 是否开启性能加速 1000 /50
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_INSTANCE] ?? {});
    const [monitorType, updateMonitorType] = useState('capacity');
    const [page, updatePage] = useState(1);

    useMount(() => {
        dispatch(InstanceAction.getInstance(instanceId));
    });

    const copyContent = (id) => {
        const codeTarget = document.getElementById(`content_${id}`);
        const range = document.createRange();

        window.getSelection()?.removeAllRanges();
        range.selectNode(codeTarget as Node);
        window.getSelection()?.addRange(range);

        const success = document.execCommand('copy');

        if (success) {
            Message.success('复制成功');
        }
        window.getSelection()?.removeAllRanges();
    };

    const getDetail = () => {
        return <div>
            <Skeleton
                className="list"
                active={true}
                title={false}
                paragraph={{ rows: 6, width: [500, 500, 500, 500, 500, 500] }}
                loading={fetching}>
                <ul className="list">
                    <li className="item">
                        <span className="label">文件系统名称：</span>
                        <span className="value">
                            {instance?.name && instance?.name.length >= 10 ?
                                <Tooltip title={instance?.name}>
                                    <span>{instance?.name.slice(0, 10)}...</span>
                                </Tooltip>
                                :
                                instance?.name ?? '--'
                            }
                        </span>
                    </li>
                    <li className="item">
                        <span className="label">性能加速服务：</span>
                        <span className="value">{isAmountClose > amountSize.close ? '已开启' : '未开启'}</span>
                    </li>
                    {/* <li className="item">
                        <span className="label">文件系统ID</span>
                        <span className="value">{instance?.id ?? '--'}</span>
                    </li>
                    <li className="item">
                        <span className="label">文件系统类型</span>
                        <span className="value">{ProductType[instance?.type] ?? '--'}</span>
                    </li>
                    <li className="item">
                        <span className="label">文件系统容量</span>
                        {
                            instance?.billingMode === 'prepay' ?
                                <span className="progress">
                                    <Progress
                                        percent={capacityPercent(
                                            instance?.extras?.space_used,
                                            instanceSizeItem?.amount ?? 0,
                                            instanceSizeItem?.unit ?? 'B',
                                        )}
                                        strokeColor="#0172BB"
                                    />
                                    <span className="value">
                                        {unitTransition(instance?.extras?.space_used ?? 0, 'B')}
                                        {' / '}
                                        {unitTransition(instanceSizeItem?.amount ?? 0, instanceSizeItem?.unit ?? 'B')}
                                    </span>
                                </span> :
                                <span className="value">
                                    {unitTransition(instance?.extras?.space_used ?? 0, 'B')}
                                </span>
                        }
                    </li> */}
                    <li className="item">
                        <span className="label">inode数量：</span>
                        <span className="value">{instance?.extras?.inode_used ?? '暂无'}</span>
                    </li>
                    {/* {
                        instance.type === 'fs.performance' ? <li className="item">
                            <span className="label">预置性能</span>
                            <span className="value">
                                {(_.find(instance?.metadata, { key: 'rawbps' }) ?? {} as any).value}B
                            </span>
                        </li> : null
                    }
                    <li className="item">
                        <span className="label">到期时间</span>
                        {
                            instance?.billingMode === 'prepay' ?
                                <span className="value">
                                    {instance?.expireTime && (moment(instance?.expireTime).format('YYYY-MM-DD') ?? '--')}
                                </span> :
                                <span className="value">按量付费</span>
                        }
                    </li> */}
                    {
                        instance.type === 'fs.capacity' ?
                            <li className="item">
                                <span className="label">{platform === 'aliyun' ? '阿里云账号ID：' : 'ARN：'}</span>
                                <span className="value">
                                    {(_.find(instance?.metadata, { key: 'arn' }) ?? { value: '暂无' } as any).value}
                                </span>
                            </li> : null
                    }
                    {/* {
                        instance.type === 'fs.performance' ?
                            <li className="item">
                                <span className="label">您的账户ID</span>
                                <span className="value">
                                    {(_.find(instance.metadata, { key: 'aws_account_id' }) ?? {}).value}
                                </span>
                            </li> : null
                    }
                    {
                        instance.type === 'fs.performance' ?
                            <li className="item">
                                <span className="label">您的VPC ID</span>
                                <span className="value">
                                    {(_.find(instance.metadata, { key: 'aws_vpc_id' }) ?? {}).value}
                                </span>
                            </li> : null
                    } */}
                </ul>
            </Skeleton>
            <Radio.Group
                value={monitorType}
                onChange={(e) => {
                    updateMonitorType(e.target.value);
                }}
                className="radio-group">
                <Radio.Button value="capacity" className="radio-button">容量</Radio.Button>
                <Radio.Button value="performance" className="radio-button">性能</Radio.Button>
            </Radio.Group>
            <div className="charts">
                {
                    monitorTypes[monitorType].map((type) => {
                        return <Line type={type} instanceId={instanceId} key={type} />
                    })
                }
            </div>
        </div>
    };

    const getMountStep = () => {
        if (platform === 'aws') {
            switch (instance?.type) {
                case 'fs.performance':
                    return <MountStepAwsPerformanceContainer
                        account={instanceAwsAccount}
                        cidr={instance?.cluster?.cidr}
                        copyContent={copyContent}
                        vip={instanceMountVip}
                        clientId={instanceClientId}
                        instanceId={instance?.id}
                    />;
                case 'fs.capacity':
                    return <MountStepAwsCapacityContainer
                        privateLink={privateLink}
                        copyContent={copyContent}
                        instanceId={instance?.id}
                    />;
                case 'fs.fused':
                    return <MountStepAwsFusedContainer
                        cidr={instance?.cluster?.cidr}
                        copyContent={copyContent}
                        vip={instanceMountVip}
                        clientId={instanceClientId}
                        instanceId={instance?.id}
                    />;
            }
        }

        if (platform === 'tencent') {
            if (instance?.type === 'fs.performance') {
                return <MountStepTencentPerformanceContainer
                    account={instanceAwsAccount}
                    cidr={instance?.cluster?.cidr}
                    copyContent={copyContent}
                    vip={instanceMountVip}
                    clientId={instanceClientId}
                    instanceId={instance?.id}
                />;
            }
        }

        if (platform === 'aliyun') {
            if (instance?.type === 'fs.capacity') {
                return <MountStepAliyunCapacityContainer
                    privateLink={privateLink}
                    copyContent={copyContent}
                    instanceId={instance?.id}
                />;
            }
        }
    };
    const getMount = () => {
        return <div className="mount-step">
            <p className="title">文件系统名称</p>
            <p style={{ marginBottom: 24 }}>{instance?.name}</p>
            <p className="title">文件系统安装步骤</p>
            {getMountStep()}
        </div>;
    };
    return <div className="instance-detail-container">
        <PageTypography title='文件系统'>焱融SaaS文件存储平台，是构建在公有云基础架构上的共享NAS数据服务平台，为用户提供更快、更经济、更灵活的数据共享服务。用户可选择POSIX文件接口或者标准NFS共享接口访问数据，满足不同应用场景下多样化的存储需求。</PageTypography>
        <CardNav title='文件系统详情' link='/console/instances'>
            <Layout>
                <Layout.Sider className="detail-sider">
                    <ul className="sider-nav">
                        <li onClick={() => updatePage(1)} className={page === 1 ? 'selected' : ''}>使用情况</li>
                        <li onClick={() => updatePage(2)} className={page === 2 ? 'selected' : ''}>挂载步骤</li>
                    </ul>
                </Layout.Sider>
                <Layout.Content className="detail-content">
                    {page === 1 ? getDetail() : null}
                    {page === 2 ? getMount() : null}
                </Layout.Content>
            </Layout>
        </CardNav>
    </div>
};
