import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { CommonLayout } from '../components/common-layout.component';
import { MessageContainer } from './containers/message.container';
import { MessageDetailContainer } from './containers/message-detail.container';
import { GetBaidu } from '../components/baidu.statistics';

export const Notification = (props) => {
    return <CommonLayout path={props.location.pathname}>
        <GetBaidu>
        <Route path="/console/message" exact><Redirect to="/console/message/all"/></Route>
        <Route path="/console/message/:type" exact component={MessageContainer}/>
        {/* <Route path="/console/message/:messageId" exact component={MessageDetailContainer}/> */}
        </GetBaidu>
    </CommonLayout>
}
