import * as io from 'socket.io-client';
import { useMount, useUnmount } from 'react-use';
import { notification } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { MessageAction } from './message/actions/message.action';
import { SocketAction, ModalAction } from "../redux";
import { InstanceAction } from "./instance/actions/instance.action";
import { PAYMENT_SUCC_MODAL, PAYMENT_TYPE_MODAL } from './account/constants/modal.constants';
import { useEffect } from 'react';

let socket;

export const SocketContainer = (props) => {
    const dispatch = useDispatch();
    const { accountId } = useSelector<any, any>(state => state.session ?? {});
    useMount(() => {
        socketConnect();
    });
    useUnmount(() => {
        socket?.disconnect();
    });
    // useEffect(() => {
    //     socket = null;
    //     if (accountId) {
    //         socketConnect();
    //     }
    // }, [accountId]);

    const socketConnect = () => {
        socket = io.connect('/events', { transports: ['websocket'], query: {} });
        socket.on('connect', () => {
            console.log('websocket connected');
        });
        socket.on('disconnect', () => {
            console.log('websocket disconnected');
        });
        socket.on('error', (e) => {
            console.log('websocket error: ', e.message);
        });
        socket.on('connect_error', (e) => {
            console.log('websocket connect error: ', e.message);
        });
        socket.on('message', (e) => {
            console.log(e.type);
            switch (e.type) {
                case 'instance_created_message':
                    dispatch(InstanceAction.getInstances(1));
                    break;
                case 'ticket_new_reply_message':
                    dispatch(SocketAction.refreshTicketReplies(e));
                    break;
                case 'message':
                    dispatch(MessageAction.getUnreadMessage());
                    dispatch(MessageAction.getUnreadMessagesSum());
                    break;
                case 'pay_bill_success_message': // 微信付款成功
                    dispatch(ModalAction.close(PAYMENT_TYPE_MODAL)); // 关闭支付modal
                    dispatch(ModalAction.open(PAYMENT_SUCC_MODAL, { socket: true })); // 打开付款成功modal
                    break;
            }

            if (e.content) {
                notification.info({ message: e.title ?? '系统通知', description: e.content });
            }
        });
    }
    return props.children;
}
