/* eslint-disable jsx-a11y/anchor-is-valid */
import {message as Message} from 'antd';
import createfile from './../../../../asserts/createfile.png';
import { CopyIcon } from "../../../components/icons.component";
export const StorageBegin = () => {
    const copyContent = (id) => {
        const codeTarget = document.getElementById(`content_${id}`);
        const range = document.createRange();

        window.getSelection()?.removeAllRanges();
        range.selectNode(codeTarget as Node);
        window.getSelection()?.addRange(range);

        const success = document.execCommand('copy');

        if (success) {
            Message.success('复制成功');
        }
        window.getSelection()?.removeAllRanges();
    };
    return (
        <div>
            <h2>快速上手</h2>
            <b>1、准备</b>
            <p><strong>操作系统：</strong>焱融SaaS文件存储支持大多数 Linux 发行版本。</p>
            <p><strong>公有云区域：</strong>焱融SaaS文件存储是构建在公有云平台之上的数据服务，焱融SaaS文件存储和您的应用服务（交换机）需要在同一区域，才能通过公有云提供的网络技术打通网络。目前焱融SaaS文件存储支持的区域有AWS（cn-northwest-1c）。如果您需要其他区域的服务，请联系我们。</p>
            <p><strong>账户信息：</strong>焱融SaaS文件存储需要打通不同VPC之间的连接，需要提前准备您公有云账户的ARN（账户ID）信息。</p>
            <b>2、创建文件系统</b>
            <p>焱融SaaS文件存储需要通过官方网站控制台创建文件系统，新用户请先在焱融SaaS文件存储官网上完成注册，登录到控制台，点击“创建文件系统”按钮。</p>
            <img src={createfile} alt="" />
            <p>每个文件系统分配50MBps的吞吐量，如您需要更高的吞吐量，可以选择开启性能加速服务。性能加速是焱融SaaS文件存储为用户提供的一项增值服务，开启性能加速后系统将不再对您的文件系统性能做限制，您可解锁文件系统的极限性能。您最高可获得1000MB/s的吞吐量来支撑您的工作负载。性能加速服务将产生额外的费用，请您谨慎开启。</p>
            <b>注：</b>
            <p><strong>服务器区域：</strong>用来指定你的应用服务器连接交换机所在的服务商及区域。后续文件系统挂载操作时，需要让您的VPC与焱融SaaS文件存储VPC之间建立连通性，需要正确填写。 如果你要设置的云服务商或区域不在列表中，请联系我们。</p>
            <p><strong>ARN（账户ID）：</strong>用来连接网络时候的验证，请正确填写。</p>
            <b>3、挂载文件系统</b>
            <p>文件系统创建完成，返回至文件系统列表页，点击该文件系统，按照挂载步骤操作，通过网络连接及挂载执行，即可将新创建的文件系统挂载到你的服务器。</p>
            <p>具体步骤：</p>
            <p>（1）打开AWS——相应区域——VPC控制面板——终端节点。目前焱融SaaS文件存储支持区域有AWS（cn-northwest-1c）</p>
            <p>（2）点击“创建终端节点”；服务类别选择“其他终端节点服务服务”，类别选择“按名称查找服务”；在服务名称处输入“终端节点”名称；点击 “验证”按钮。</p>
            <p>（3） 在VPC输入框中，输入需要挂载文件系统的EC2所在的VPC、子网及可用区；选择文件系统挂载服务器所用安全组；点击“创建终端节点”。</p>
            <p>（4） 在该安全组添加：入站规则 TCP  30001-30004；TCP  111；TCP  2049。注意：创建终端节点完成之后，终端节点列表中相应的终端节点状态变成“待接受”，平台会在10分钟内接受请求，等终端节点状态变成“可用”之后，执行下一步操作。</p>
            <p>（5） 挂载文件系统</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;a)获取DNS: 打开AWS——相应区域——VPC控制面板——终端节点——详细信息，获取DNS名称，复制DNS名称 。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;b)登录到需要挂载文件系统的虚拟机，挂载文件系统。</p>
            <code>
                <p>
                    #&nbsp;
                    (Centos)&nbsp;<span id="content_nfsutils">yum install nfs-utils</span>
                    <a onClick={() => copyContent('nfsutils')} className="copy-button" title="复制至剪贴板">
                        <CopyIcon />
                    </a>
                </p>
                <p>
                    #&nbsp;
                    (Ubuntu)&nbsp;<span id="content_nfscommon">apt install nfs-common</span>
                    <a onClick={() => copyContent('nfscommon')} className="copy-button" title="复制至剪贴板">
                        <CopyIcon />
                    </a>
                </p>
                <p>
                    #&nbsp;
                    <span id="content_mkdir">mkdir -p /mnt/yrfs</span>
                    <a onClick={() => copyContent('mkdir')} className="copy-button" title="复制至剪贴板">
                        <CopyIcon />
                    </a>
                </p>
                <p>
                    #&nbsp;
                    <span id="content_mount">
                        mount -t nfs -o tcp,nolock,noac,nfsvers=3 <i>DNS名称</i>:/mnt/yrfs/实例ID详见文件系统详情挂载步骤 /mnt/yrfs
                    </span>
                    <a onClick={() => copyContent('mount')} className="copy-button" title="复制至剪贴板">
                        <CopyIcon />
                    </a>
                </p>
                <p>
                    #&nbsp;
                    <span id="content_df">df -h</span>
                    <a onClick={() => copyContent('df')} className="copy-button" title="复制至剪贴板">
                        <CopyIcon />
                    </a>
                </p>
            </code>
            <b>注：焱融SaaS文件存储通过公有云提供的网络技术进行VPC之间的互联。公有云终端节点服务，需要您提供账户ID来添加终端节点的白名单。建立连接时，需要您正确选择您的服务器所用的交换机，并为您的服务器添加相关端口的入站规则。</b>
        </div>
    )
}