import { CopyOutlined, InfoCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import aliyunCreateTerminal from '../../../asserts/mount/mount_aliyun_terminal_create.png';
import aliyunTerminal from '../../../asserts/mount/mount_aliyun_terminal.png';
import aliyunConfig from '../../../asserts/mount/mount_aliyun_config_rule.png';
import aliyunDomain from '../../../asserts/mount/mount_aliyun_domainname.png';
import aliyunSecurity from '../../../asserts/mount/mount_aliyun_security.png';
import aliyunConnectState from '../../../asserts/mount/mount_aliyun_connect_state.png';
import { Modal } from "antd";
import { CloseModalIcon, CopyIcon } from "../../components/icons.component";


export const MountStepAliyunCapacityContainer = ({ privateLink, copyContent, instanceId }) => {
    const [tip, updateTip] = useState({ visible: false, title: '', pic: '' });
    return <td>
        <p>1、建立阿里云Privatelink连接</p>
        <p className="content-secondary">
            a)打开
            <a href="https://homenew.console.aliyun.com/home/dashboard/ProductAndService" target="_blank">阿里云控制台</a>
            ——专有网络——相应区域——终端节点&nbsp;<InfoCircleOutlined onClick={() => updateTip({ visible: true, title: '创建终端节点', pic: aliyunCreateTerminal })} />。
        </p>
        <p className="content-secondary">
            b)点击“创建终端节点”；输入“节点名称”（自定义）；终端节点服务选择“通过服务名称添加”；在服务名称处输入“
            <span id="content_private_link">
                {privateLink}
            </span>
            ”
            <a onClick={() => copyContent('private_link')} className="copy-button" title="复制至剪贴板">
                <CopyIcon />
            </a>
            &nbsp;在“专有网络”处输入需要挂载文件系统的ECS所在的网络；点击“确定创建”。
        </p>
        <p className="content-secondary">
            c)在该安全组&nbsp;
            <InfoCircleOutlined onClick={() => updateTip({ visible: true, title: '获取安全组', pic: aliyunConfig })} />
            &nbsp;添加：入方向 TCP  30001-30004；TCP 111；TCP 2049&nbsp;
            <InfoCircleOutlined onClick={() => updateTip({ visible: true, title: '配置入方向', pic: aliyunSecurity })} />。
        </p>
        <p>
            <strong>注意：</strong>
            创建终端节点完成之后，终端节点列表中相应的终端节点状态变成“已断开”，平台会在10分钟内接受请求，等终端节点状态变成“已连接”之后，执行下一步操作&nbsp;
            <InfoCircleOutlined onClick={() => updateTip({ visible: true, title: '终端节点状态', pic: aliyunConnectState })} />。
        </p>
        <p>2、挂载文件系统</p>
        <p className="content-secondary">
            a)获取终端节点域名: 打开
            <a href="https://homenew.console.aliyun.com/home/dashboard/ProductAndService" target="_blank">阿里云控制台</a>
            ——专有网络——相应区域——终端节点——点击终端节点名称&nbsp;
            <InfoCircleOutlined onClick={() => updateTip({ visible: true, title: '获取终端节点', pic: aliyunTerminal })} />
            ，获取<i>终端节点域名</i>，复制DNS名称&nbsp;
            <InfoCircleOutlined onClick={() => updateTip({ visible: true, title: '获取DNS', pic: aliyunDomain })} />
            。
        </p>
        <p className="content-secondary">b)登录到需要挂载文件系统的虚拟机，执行以下命令，挂载文件系统。</p>
        <code>
            <p>
                #&nbsp;
                (Centos)&nbsp;<span id="content_nfsutils">yum install nfs-utils</span>
                <a onClick={() => copyContent('nfsutils')} className="copy-button" title="复制至剪贴板">
                    <CopyIcon />
                </a>
            </p>
            <p>
                #&nbsp;
                (Ubuntu)&nbsp;<span id="content_nfscommon">apt install nfs-common</span>
                <a onClick={() => copyContent('nfscommon')} className="copy-button" title="复制至剪贴板">
                    <CopyIcon />
                </a>
            </p>
            <p>
                #&nbsp;
                <span id="content_mkdir">mkdir -p /mnt/yrfs</span>
                <a onClick={() => copyContent('mkdir')} className="copy-button" title="复制至剪贴板">
                    <CopyIcon />
                </a>
            </p>
            <p>
                #&nbsp;
                <span id="content_mount">
                    mount -t nfs -o tcp,nolock,noac,nfsvers=3 <i>DNS名称</i>:/mnt/yrfs/{instanceId} /mnt/yrfs
                </span>
                <a onClick={() => copyContent('mount')} className="copy-button" title="复制至剪贴板">
                    <CopyIcon />
                </a>
            </p>
            <p>
                #&nbsp;
                <span id="content_df">df -h</span>
                <a onClick={() => copyContent('df')} className="copy-button" title="复制至剪贴板">
                    <CopyIcon />
                </a>
            </p>
        </code>
        <Modal
            title={tip.title}
            footer={null}
            onCancel={() => updateTip({ visible: false, title: '', pic: '' })}
            visible={tip.visible}
            width={1200}
            closeIcon={<CloseModalIcon />}
            centered={true}
        >
            <img alt={tip.title} style={{ width: '100%', padding: 24 }} src={tip.pic} />
        </Modal>
    </td>;
}
