import { useHistory } from 'react-router-dom';
import { LoginLayout } from '../../components/login-layout.component';
import './sass.rule.less';

export const SassRule = () => {
    return (
        <div className="sass-rule">
            <p>1、活动对象</p>
            <p>焱融 SaaS 文件存储官网注册的<span>新用户。</span></p>
            <div className='sass-br'></div>
            <p>2、活动时间</p>
            <p>长期有效</p>
            <div className='sass-br'></div>
            <p>3、活动规则</p>
            <p>（1）焱融 SaaS 文件存储新注册用户可免费试用 <span>30天不超过2TB</span> 的容量。试用期结束后按正常价格计费。</p>
            <p>（2）如果您在试用期内上传的数据超过 2TB，那么试用期内仅对超过部分进行计费，试用期结束后对您上传的全部数据按正常价格计费。</p>
            <p>（3）优惠活动仅限于本账户用户使用，不得转让或出售</p>
            <p>（4）为保证活动的公平公正，焱融 SaaS 文件存储有权对恶意刷抢（如通过程序等技术手段）活动资源，长期资源闲置，利用资源从事违法违规行为的用户收回免费套餐使用资格。</p>
            <p>（5）如用户在活动中存在隐瞒、虚构、作弊、欺诈或通过其他非正常手段规避活动规则、获取不当利益的行为，例如：作弊领取、恶意套现、网络攻击、虚假交易等，焱融 SaaS 数据服务有权收回相关权益、取消用户的活动参与资格，撤销违规交易，必要时追究违规用户的法律责任。</p>
        </div>
    )
}

export const SassRuleMobale = () => {
    const history = useHistory();
    return (
        <LoginLayout>
            <div className='sass-mobile'>
                <b>新用户免费试用活动规则</b>
                <SassRule />
                <div className='know-footer'>
                    <span className='know' onClick={() => { history.push('/sign-in') }}>我了解了</span>
                </div>
            </div>
        </LoginLayout>

    )
}