import { MapReducer } from '../../../redux/reducers/map.reducer';
import { ACTIVATE } from '../constants/action-types.constants';

export class ActivateReducer extends MapReducer {
    private static readonly defaults = {
        isActivated: true,
    }

    constructor() {
        super(ACTIVATE, ActivateReducer.defaults)
    }
}

