import { MapReducer } from "../../../redux/reducers/map.reducer";
import { ORDER } from "../constants/action-types.constants";

export class OrderReducer extends MapReducer {
    private static defaults = {
        orders: [],
        order: {},
    }
    constructor() {
        super(ORDER, OrderReducer.defaults);
    }
}