import { Dispatch } from 'redux';
import { State } from '../../../interfaces';
import { Client, Action, PaginationAction } from '../../../redux';
import { ORDER } from '../constants/action-types.constants';
import { GET_ORDER, GET_ORDERS } from '../constants/fetch.constants';

export class OrderAction {
    private static client = new Client();

    public static getOrders(page: number, size: number) {
        return async (dispatch: Dispatch<any>, state: State) => {
            const { list, total } = await this.client.get('/apis/orders', {
                params: { page, size },
                dispatch,
                fetch: GET_ORDERS,
            });
            dispatch(new Action(ORDER).put('orders', list));
            dispatch(PaginationAction.update('order', page, size, total, list.length));
        }
    }

    public static getOrder(orderId: string) {
        return async (dispatch: Dispatch<any>, state: State) => {
            const { order } = await this.client.get(`/apis/orders/${orderId}`, {
                dispatch,
                fetch: GET_ORDER,
            })
            dispatch(new Action(ORDER).put('order', order));
        }
    }

    public static cancelOrder(orderId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const pagination = state().pagination.order ?? {} as any;
            await this.client.put(`/apis/orders/${orderId}/actions/cancel`, {}, {});
            dispatch(this.getOrders(pagination.page, pagination.size));
        }
    }
}
