/**
 * @Description: baidu.statistics.tsx 文件内容百度统计
 * @Author: hailiang
 * @Date: 2021-09-29 17:32:42
 * @LastEditTime: 2021-10-11 17:01:43
 * @LastEditors: hailiang
 */
export const GetBaidu = props => {
    let children = props.children;
    var _hmt = _hmt || [];
    if (window.location.hostname.startsWith('console')) {
        (function() {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?1079970b4d09c60490d59fcf12f7a36b";
            var s = document.getElementsByTagName("script")[0];
            // @ts-ignore
            s.parentNode.insertBefore(hm, s);
        })();
    }
    return children;
};