import { Modal, Form, message } from 'antd'
import { useRef, useLayoutEffect } from 'react'
import { State, Modal as ModalInterface, Fetch } from '../../../interfaces'
import { useDispatch, useSelector } from 'react-redux';
import { ModalAction } from '../../../redux'
import { InvoiceForm } from '../components/invoice-form'
import { INVOICE_NEWS_MODAL } from '../constants/modal.constants'
import { PUT_INVOICE_INFO } from '../constants/fetch.constants'
import { InvoiceAction } from '../actions/invoice.action'
import { defauleInvoiceForm } from '../enums'
import { CloseModalIcon } from '../../components/icons.component';

export const InvoiceNews = () => {
    const invoiceRef = useRef<any>({});
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { invo_info } = useSelector<State, any>(state => state.invoice);
    const { fetching } = useSelector<State, Fetch>((store) => store.fetch[PUT_INVOICE_INFO] ?? {} as any);
    const { visible } = useSelector<State, ModalInterface>((state) => state.modals[INVOICE_NEWS_MODAL] ?? {} as any);
    const submit = (values) => {
        const { title_per = "个人", title_ent, agree, ...newValues } = values;
        const { titleType, invoiceType } = invoiceRef.current
        const data = {
            ...defauleInvoiceForm,
            titleType, // 抬头类型
            invoiceType, // 发票类型
            title: titleType === 'personal' ? title_per : title_ent, // 发票抬头
            ...newValues,
        }
        console.log(data, '====>');
        if (invo_info?.id) {
            dispatch(InvoiceAction.getInvoiceInfoUpdata(invo_info.id, data, () => {
                message.success('添加成功');
                dispatch(ModalAction.close(INVOICE_NEWS_MODAL));
            }));
        } else {
            dispatch(InvoiceAction.getInvoiceInfoAdd(data, () => {
                message.success('更新成功');
                dispatch(ModalAction.close(INVOICE_NEWS_MODAL));
            }));
        }
    }
    useLayoutEffect(() => {
        form && form.resetFields();
    }, [invo_info])
    return (
        <Modal
            visible={visible}
            onCancel={() => { dispatch(ModalAction.close(INVOICE_NEWS_MODAL)) }}
            title='管理发票抬头'
            width={700}
            onOk={() => { form.submit() }}
            okText='确定'
            centered={true}
            confirmLoading={fetching}
            closeIcon={<CloseModalIcon />}
        >
            <Form
                form={form}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 16 }}
                onFinish={(values) => submit(values)}
                layout='horizontal'
                className="invoiceTitle"
                initialValues={{ ...invo_info }}
            >
                <InvoiceForm ref={invoiceRef} form={form} showCheck={false}></InvoiceForm>
            </Form>
        </Modal>
    )
}