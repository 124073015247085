import { MapReducer } from "../../../redux/reducers/map.reducer";
import { INVOICE } from "../constants/action-types.constants";

export class InvoiceReducer extends MapReducer {
    private static defaults = {
        invoices: [], //开票记录 list，
        invo_amount:{
            available: 0, 
            used: 0
        }, // 开票金额
        invo_info:{}, //发票信息
        invo_address:{}, //默认邮寄地址
        address_list:[], // 邮寄地址
    }
    constructor() {
        super(INVOICE, InvoiceReducer.defaults);
    }
}