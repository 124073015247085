import { Dispatch } from 'redux';
import { Client } from "../client";
import { State } from "../../interfaces";
import { Action } from "../action";
import { SESSION } from "../constants/action-types.constants";

export class SessionAction {
    private static client = new Client();

    public static getSession(callback: any = false) {
        return async (dispatch: Dispatch<any>, state: State) => {
            const session = await this.client.get('/apis/sessions', {
                dispatch,
            });
            dispatch(new Action(SESSION).init(session as any));
            callback && callback(session);
        };
    }

    public static signOut() {
        return async (dispatch: Dispatch<any>, state: State) => {
            await this.client.post('/apis/auth/sign-out', null, {
                dispatch,
            });
            window.location.hash = '/sign-in';
            dispatch(new Action(SESSION).init({} as any));
        };
    }
}
