
export const StorageCharging = () => {
    return (
        <div>
            <h2>产品计费</h2>
            <p>1、计费方式：按量计费</p>
            <p>2、计费公式：</p>
            <p>2.1容量型产品的计费公式=用户产生的费用=每小时使用的存储容量×每小时单价。注：仅创建文件系统不会产生费用，当用户写入数据时开始计费。</p>
            <p>2.2性能加速服务的计费公式=（每小时使用的平均性能-50MB/s）×每小时单价。注：如果用户每小时使用的平局性能低于50MB/s时，费用记为0。用户关闭性能加速服务后将停止性能加速服务的计费，同时文件系统的吞吐量限制为50MB/s</p>
            <p>3、产品单价</p>
            <p>容量型产品     0.48元/GB/月</p>
            <p>性能加速服务   0.5元/MB/s/小时</p>
            <p>4、计费周期：以小时为周期。</p>
            <p>系统每小时会统计前一个小时（整点区间，如18:00~19:00）的实际用量，根据实际用量产生账单并结算扣费。</p>
            <p>费用结算的最小时长为1小时，与具体的上传时刻无关。例如在结算18:00~19:00的存储费用时，无论是在18:00~19:00之间哪个使用的容量，都以存储时长为1小时结算。</p>
            <p>5、账单周期：以月为周期</p>
            <p>系统每月1日统计上一个自然月用户所产生的所有费用，并将账单发给用户，提醒用户打款。用户需在接到账单的14天内向焱融SaaS文件存储的账户打款，否则视为逾期。</p>
            <p>6、逾期： </p>
            <p>发生逾期后将冻结用户的文件系统并持续向用户发送短信提醒。冻结后用户不能对数据进行操作，但依旧会对文件系统内的数据计费。如果用户在逾期后的14天内补足欠款则自动恢复服务，否则视为用户主动放弃使用焱融SaaS文件存储，将不再为用户保存数据，因此带来的损失由用户自己负责。</p>
            <p>7、退订:</p>
            <p>用户如不想再使用文件系统可以点击退订按钮。退订后文件系统将不再产生费用，也不再为用户保存数据。用户点击退订后无法恢复。</p>
        </div>
    )
}