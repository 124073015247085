import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Form, Button, message as Message, Input, Slider, Checkbox } from 'antd';
import { PageTypography } from './../../components/page.typography';
import { CardNav } from './../../components/card.navigation'
import { find } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMount } from 'react-use';
import { Fetch, State } from '../../../interfaces';
import { Fee } from '../../../interfaces/fee.interface';
import { FetchAction } from '../../../redux';
import { RightGreenIcon } from '../../components/icons.component';
import { InstanceAction } from '../actions/instance.action';
import { CREATE_MODIFY_ORDER, GET_MODIFY_PRICE } from '../constants/fetch.constants';
import { ProductType } from '../enums';
import './modify-type.container.less';

const types = ['fs.capacity', 'fs.performance'];
let timer;
const cidrReg = /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/;
const bandwidthReg = /fs\.\w+\.bandwidth/;
const capacityReg = /fs\.\w+\.size/;

export const ModifyTypeContainer = (props) => {
    const instanceId = props?.match?.params?.instanceId;

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [ipValidation, updateIpValidation] = useState<{ passed: boolean, message: string | undefined }>({
        passed: false,
        message: undefined,
    });
    const [isAgree, updateIsAgree] = useState(false);
    const [product, updateProduct] = useState('');

    const { fetching } = useSelector<State, Fetch>(state => state.fetch[CREATE_MODIFY_ORDER] ?? {} as any);
    const { fetching: feeFetching } = useSelector<State, Fetch>(state => state.fetch[GET_MODIFY_PRICE] ?? {} as any);
    const instance = useSelector<State, any>(
        state => state.instance?.instance ?? {} as any,
    );
    const { modify } = useSelector<State, Fee>(state => state.fee ?? {} as any);
    const instanceIp = (find(instance?.metadata, { key: 'ip' }) ?? {}).value;
    const instanceArn = (find(instance?.metadata, { key: 'arn' }) ?? {}).value;
    const instanceBandwidth = (find(instance?.items, { type: `${instance?.type}.bandwidth` }) ?? {}).amount;
    const instanceSize = (find(instance?.items, { type: `${instance?.type}.size` }) ?? {}).amount;
    const instanceSizeUnit = (find(instance?.items, { type: `${instance?.type}.size` }) ?? {}).unit;
    const instanceAwsAccountId = (find(instance?.metadata, { key: 'aws_account_id' }) ?? {}).value;
    const instanceAwsVpcId = (find(instance?.metadata, { key: 'aws_vpc_id' }) ?? {}).value;

    const products = useSelector<State, any>(state => state.instance?.products);
    const productInst = products?.filter(item => item.type === product)[0];
    const capacityBillingItem = productInst?.billingItems.filter(item => capacityReg.test(item.type))[0];
    const bandwidthBillingItem = productInst?.billingItems.filter(item => bandwidthReg.test(item.type))[0];
    const [bandwidth, setBandwidth] = useState(bandwidthBillingItem?.min ?? 0);
    const platform = instance?.region?.platform;
    const newInstanceSize = Math.max(capacityBillingItem?.min ?? instanceSize, instanceSize);
    useMount(() => {
        dispatch(InstanceAction.getInstance(instanceId));
    });

    useEffect(() => {
        if (instance?.type) {
            dispatch(InstanceAction.getProducts(instance?.regionId, instance?.billingMode));
            const defaultProduct = types.filter((type) => type !== instance?.type)[0];
            updateProduct(defaultProduct);
        }
    }, [instance?.type]);

    useEffect(() => {
        if (products) {
            const product = products.filter(item => !item.soldOut && item.type !== instance?.type)[0];
            if (product) {
                form.setFieldsValue({ type: product.type });
                updateProduct(product.type);
                handleFormChange({});
            } else {
                form.setFieldsValue({ type: null });
                updateProduct('');
            }
        }
    }, [products]);

    const submit = async (values) => {
        let { bandwidth, ip, arn, awsAccountId, awsVpcId } = form.getFieldsValue();
        const type = instance?.type === 'fs.capacity' ? 'fs.performance' : 'fs.capacity';
        if (!ipValidation.passed && platform === 'aws' && product !== 'fs.capacity') {
            try {
                await validIp();
            } catch (e) {
                console.log(e);
                return;
            }
        }
        if (!arn) {
            arn = instanceArn;
        }
        if (!ip) {
            ip = instanceIp;
        }
        if (!awsVpcId) {
            awsVpcId = instanceAwsVpcId;
        }
        if (!awsAccountId) {
            awsAccountId = instanceAwsAccountId;
        }
        const params = {
            awsAccountId,
            awsVpcId,
            ip,
            arn,
            orderType: 'modify',
            instanceId: instance?.id,
            product: type,
            item: {
                [`${type}.size`]: newInstanceSize,
                [`${type}.bandwidth`]: bandwidth ? Number(bandwidth) : undefined,
            },
        };
        dispatch(InstanceAction.createModify(params, (orderId) => {
            Message.success('变更成功');
            pushState(`/console/account/payment/${orderId}`);
        }));
    };
    const handleFormChange = (changed) => {
        const { ip, arn, awsVpcId, awsAccountId } = changed;

        if (ip !== undefined || arn !== undefined || awsVpcId !== undefined || awsAccountId !== undefined) {
            return;
        }

        getModifyPrice();
    };
    const getModifyPrice = async () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            dispatch(FetchAction.fetching(GET_MODIFY_PRICE));
            const { bandwidth } = form.getFieldsValue();
            const type = instance?.type === 'fs.capacity' ? 'fs.performance' : 'fs.capacity';
            const params = {
                orderType: 'modify',
                instanceId: instance?.id,
                product: type,
                item: {
                    [`${type}.size`]: newInstanceSize,
                    [`${type}.bandwidth`]: bandwidth ? Number(bandwidth) : undefined,
                },
            };
            dispatch(InstanceAction.getModifyPrice(params));
        }, 100);
    };

    const validIp = async () => {
        const regionId = instance?.region?.id;
        await form.validateFields(['ip', 'awsAccountId', 'awsVpcId']);
        const { ip, awsAccountId, awsVpcId } = form.getFieldsValue(['ip', 'awsAccountId', 'awsVpcId']);

        return new Promise((resolve, reject) => {
            dispatch(InstanceAction.validIP(
                regionId,
                ip,
                awsAccountId,
                awsVpcId,
                (message) => {
                    if (message) {
                        reject(message);
                        updateIpValidation({ passed: false, message });
                    } else {
                        resolve(true);
                        updateIpValidation({ passed: true, message: undefined });
                    }

                    form.validateFields(['ip']);
                }
            ));
        });
    };
    const calculatePrice = () => {
        if (feeFetching) {
            return <span className="loading">加载中</span>;
        }
        return <p>
            <span>配置金额：</span>
            <span className="amount">{modify?.originalAmount === undefined ? '--' : `¥ ${modify.originalAmount}`}</span>
        </p>;
    };
    const pushState = (url: string) => window.location.hash = url;
    console.log(ipValidation);
    return <div className="modify-type-container">
        <PageTypography title='文件系统'>焱融SaaS文件存储平台，是构建在公有云基础架构上的共享NAS数据服务平台，为用户提供更快、更经济、更灵活的数据共享服务。用户可选择POSIX文件接口或者标准NFS共享接口访问数据，满足不同应用场景下多样化的存储需求。</PageTypography>
        <CardNav title='创建文件系统' link='/console/instances'>
            <Form
                className="form"
                layout="vertical"
                colon={false}
                form={form}
                onFinish={(values) => submit(values)}
                onValuesChange={handleFormChange}
            >
                <Form.Item label="原文件系统类别">
                    <div className="system-type">
                        <span>{ProductType[instance?.type]}</span>
                        <RightGreenIcon className="modify-icon" />
                        <span>变更为</span>
                        <span className="new-type">
                            {instance?.type === 'fs.capacity' ? ProductType['fs.performance'] : ProductType['fs.capacity']}
                        </span>
                    </div>
                </Form.Item>
                {
                    ['aliyun', 'aws'].includes(platform) && ['fs.capacity', 'fs.fused'].includes(instance?.type) ?
                        <Form.Item
                            name="arn"
                            label={platform === 'aws' ? 'ARN' : '阿里云账户ID'}
                            required={false}
                            rules={[{ required: true, message: `请输入${platform === 'aws' ? 'ARN' : '阿里云账户ID'}` }]}
                            initialValue={instanceArn}
                        >
                            <Input placeholder={`请输入${platform === 'aws' ? 'ARN' : '阿里云账户ID'}`} />
                        </Form.Item> : null
                }
                {
                    ['tencent', 'aws'].includes(platform) && ['fs.capacity', 'fs.fused'].includes(instance?.type) ?
                        <>
                            <Form.Item
                                label="您的账户ID"
                                name="awsAccountId"
                                required={false}
                                rules={[{ required: true, message: '请输入您的账户ID（12位）' }]}
                                initialValue={instanceAwsAccountId}>
                                <Input maxLength={12} placeholder="请输入您的账户ID" />
                            </Form.Item>
                            <Form.Item
                                label="您的VPC ID"
                                name="awsVpcId"
                                required={false}
                                rules={[{ required: true, message: '请输入您的VPC ID' }]}
                                initialValue={instanceAwsVpcId}>
                                <Input placeholder="请输入您的VPC ID" />
                            </Form.Item>
                        </> : null
                }
                {
                    ['tencent', 'aws'].includes(platform) && ['fs.capacity', 'fs.fused'].includes(instance?.type) ?
                        <Form.Item
                            label="您的子网CIDR"
                            name="ip"
                            required={false}
                            rules={[
                                {
                                    validator: (rule, value) => {
                                        if (!cidrReg.test(value)) {
                                            return Promise.reject('请输入正确的CIDR');
                                        }
                                        return Promise.resolve();
                                    }
                                },
                                {
                                    validator: (rule, value) => {
                                        if (ipValidation.message) {
                                            return Promise.reject(ipValidation.message ?? '请输入正确的CIDR');
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ]}
                            initialValue={instanceIp}
                        >
                            <Input
                                addonAfter={platform === 'aws' ? <span>
                                    {ipValidation.passed ? <CheckCircleOutlined style={{ marginRight: 12, color: 'green' }} /> : null}
                                    {ipValidation.message ? <CloseCircleOutlined style={{ marginRight: 12, color: 'red'}} /> : null}
                                    <Button onClick={() => validIp()}>验证</Button>
                                </span> : null}
                                placeholder="请输入您的子网CIDR"
                                onChange={() => {
                                    if (ipValidation.message || ipValidation.passed) {
                                        updateIpValidation({ passed: false, message: undefined });
                                    }
                                }}
                            />
                        </Form.Item> : null
                }
                {
                    instance?.billingMode === 'prepay' ?
                        <Form.Item label="容量">
                            {`${newInstanceSize} ${instanceSizeUnit}`}
                        </Form.Item> : null
                }
                {
                    (instance?.type === 'fs.capacity' || instance?.type === 'fs.fused') && bandwidthBillingItem ?
                        <Form.Item
                            label="预置吞吐量"
                            name="bandwidth"
                            required
                            initialValue={bandwidthBillingItem?.min}
                        >
                            <Slider
                                getTooltipPopupContainer={node => node.parentNode as HTMLElement}
                                max={bandwidthBillingItem?.max}
                                min={bandwidthBillingItem?.min}
                                step={10}
                                marks={{
                                    [bandwidthBillingItem?.min]: `${bandwidthBillingItem?.min ?? 0}MB`,
                                    [bandwidthBillingItem?.max]: `${bandwidthBillingItem?.max ?? 0}MB`,
                                }}
                                tooltipPlacement="bottom"
                                tipFormatter={value => `${value}MB`}
                                tooltipVisible={true}
                                className="storage-slider"
                            />
                        </Form.Item> : null
                }
                {
                    instance?.billingMode === 'prepay' ?
                        <Form.Item label="到期时间">
                            {moment(instance?.expireTime).format('YYYY-MM-DD')}
                        </Form.Item> : null
                }
                <Checkbox onChange={(e) => updateIsAgree(e.target.checked)}>
                    同意<Link to="/console/documents/terms" target="_blank">用户协议</Link>
                </Checkbox>
            </Form>
            <div className="form-footer">
                {calculatePrice()}
                <Button
                    loading={fetching}
                    type="primary"
                    disabled={!isAgree}
                    onClick={() => form.submit()}
                    className="btn-submit">
                    提交订单
                </Button>
            </div>
        </CardNav>
    </div>
}
