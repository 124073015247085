import { Button, DatePicker, Form, Input, Space, Table } from 'antd';
import { PageTypography } from './../../components/page.typography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import moment from 'moment';
import { TicketAction } from '../actions/ticket.action';
import { TicketStatus } from '../enums/ticket-status.enum';
import './tickets.container.less'
import { Fetch, State } from "../../../interfaces";
import { GET_TICKETS } from "../constants/fetch.constants";
import { SwapRightOutlined } from "@ant-design/icons";
import { RefreshButton } from '../../components/refresh-button.component';
import { CreateIcon } from '../../components/icons.component';
import { Link } from 'react-router-dom';
import { DefaultTable } from '../../components/default-table.component';

let timer;

export const TicketsContainer = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [query, updateQuery] = useState<any>({
        ticketId: undefined,
        keyword: undefined,
        startTime: undefined,
        endTime: undefined,
    });

    const tickets = useSelector<any, any>(
        state => state.ticket.tickets ?? [] as any,
    );
    const { pagination } = useSelector<any, any>(state => ({
        pagination: state.pagination.ticket ?? {} as any,
    }));
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_TICKETS] ?? {});

    useMount(() => refresh());
    useEffect(() => {
        refresh();
    }, [query.ticketId, query.keyword, query.startTime, query.endTime]);

    const refresh = () => {
        const params = {
            page: pagination.page,
            size: pagination.size,
            ...query,
        };
        dispatch(TicketAction.getTickets(params));
    };
    const pushState = (url: string) => window.location.hash = url;

    const onPageChange = (page: number, size: number) => {
        const params = {
            page,
            size,
            ...query,
        };
        dispatch(TicketAction.getTickets(params));
    };
    const disabledStartTime = (startTime) => {
        if (!startTime || !query.endTime) {
            return false;
        }
        return startTime.valueOf() > moment(query.endTime).valueOf();
    };
    const disabledEndTime = (endTime) => {
        if (!query.startTime || !endTime) {
            return false;
        }
        return endTime.valueOf() < moment(query.startTime).valueOf() || endTime.valueOf() > (new Date()).valueOf();
    };

    return <div className="tickets-container">
        <PageTypography title='工单'>您可以通过工单系统来询问任何问题，我们的工作人员会第一时间为您解决问题。</PageTypography>
        <div className="tickets">
            <div className="tickets-tools">
                <Space size={8}>
                    <Button
                        className="btn-primary-icon"
                        icon={<CreateIcon />}
                        onClick={() => pushState('/console/tickets/submit')}
                        type="primary">创建工单</Button>
                    <RefreshButton onClick={() => refresh()} />
                </Space>
                <div className="tools">
                    <span style={{ marginRight: 8 }} className="label">工单编号</span>
                    <Input
                        style={{ width: 160, height: 36, borderRadius: 4, marginRight: 16 }}
                        placeholder="请输入准确的编号"
                        onChange={(e) => {
                            clearTimeout(timer);
                            timer = setTimeout(() => {
                                updateQuery({ ...query, ticketId: e.target.value })
                            }, 500);
                        }} />
                    <span style={{ marginRight: 8 }} className="label">时间范围</span>
                    <DatePicker
                        style={{ width: 160, height: 36, borderRadius: 4, marginRight: 8 }}
                        placeholder="请选择开始时间"
                        disabledDate={disabledStartTime}
                        onChange={(startTime) => updateQuery({ ...query, startTime: moment(startTime).format('YYYY-MM-DD') })} />
                    <SwapRightOutlined />
                    <DatePicker
                        style={{ width: 160, height: 36, borderRadius: 4, marginRight: 16, marginLeft: 8 }}
                        placeholder="请选择结束时间"
                        disabledDate={disabledEndTime}
                        onChange={(endTime) => updateQuery({ ...query, endTime: moment(endTime).format('YYYY-MM-DD') })} />
                    <span style={{ marginRight: 8 }} className="label">关键字</span>
                    <Input
                        style={{ width: 160, height: 36, borderRadius: 4 }}
                        placeholder="请输入关键字"
                        onChange={(e) => {
                            clearTimeout(timer)
                            timer = setTimeout(() => {
                                updateQuery({ ...query, keyword: e.target.value })
                            }, 500);
                        }} />
                </div>
            </div>
            <Table
                className="table"
                dataSource={tickets}
                loading={fetching}
                rowKey={record => record.id}
                pagination={{
                    total: pagination.total,
                    defaultPageSize: pagination.size,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '50', '100'],
                    current: pagination.page,
                    onChange: onPageChange as any,
                }}
                locale={{
                    emptyText: () => <DefaultTable />,
                }}
            >
                <Table.Column title="工单编号" key="id" dataIndex="id" render={(id) => <Link to={`/console/tickets/ticket/${id}`}>{id}</Link>} />
                <Table.Column
                    title="问题内容"
                    key="content"
                    dataIndex="content"
                    render={(content) => content.length > 36 ? `${content.slice(0, 36)}...` : content}
                />
                <Table.Column
                    title="提交时间"
                    key="createdAt"
                    dataIndex="createdAt"
                    render={(text) => moment(text).format('YYYY-MM-DD')} />
                <Table.Column title="状态" key="status" dataIndex="status" render={(text) => TicketStatus[text]} />
            </Table>
        </div>
    </div>
}
