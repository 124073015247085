import { Modal, Button } from 'antd';
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { State, Modal as ModalInterface } from '../../../interfaces';
import { ModalAction } from '../../../redux';
import { BillsAction } from './../actions';
import { PAYMENT_SUCC_MODAL } from '../constants/modal.constants';
import payImg from './../../../asserts/icon_payment_succ.png';
import './payment-success.modal.less';
import { CloseModalIcon } from '../../components/icons.component';

export const PaymentSuccessModal = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { visible, ...record } = useSelector<State, ModalInterface>(
        state => state.modals[PAYMENT_SUCC_MODAL] ?? {} as any,
    );

    const pushUrl = (url) => {
        history.push(url);
        dispatch(ModalAction.close(PAYMENT_SUCC_MODAL));
    }
    const refresh = () => {
        const { id, payType,socket } = record;
        if (socket) return; // socket存在 说明是付款跳转过来的
        dispatch(BillsAction.getQrcode(id, payType)); // 说明是价格抵扣后为0 直接跳转过来的
    }
    useEffect(() => {
        refresh()
    }, [])
    return <Modal
        title=""
        visible={visible}
        width={600}
        footer={false}
        maskClosable={false}
        centered={true}
        closable={false}
        className="payment-success-modal"
        closeIcon={<CloseModalIcon />}
    >
        <div className="payment-modal">
            <img src={payImg} alt="" />
            <p>支付成功</p>
            <div className="payment-modal-btn">
                <Button onClick={() => { pushUrl('/console/account/bill') }}>查看账单页</Button>
                <Button onClick={() => { pushUrl('/console/instances') }} type="primary">去文件系统</Button>
            </div>
        </div>
    </Modal>
}