import { Decimal } from 'decimal.js';

export function unitTransition(data, unit) {
    data = parseFloat(data);
    const transition = {
        value: data,
        units: unit,
    };

    if (data === 0) {
        return `${transition.value}${transition.units}`;
    }
    const k = unit === 'B/s' || unit === 'Bps' || unit === 'bps' ? 1000 : 1024;
    let sizes: any[] = [];
    const i = Math.floor(Math.log(data) / Math.log(k));
    switch (unit) {
        case 'B':
            sizes = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB'];
            break;
        case 'B_bandwidth':
            sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB'];
            break;
        case 'GiB':
            sizes = ['GiB', 'TiB', 'PiB', 'EiB'];
            break;
        case 'MiB':
            sizes = ['MiB', 'GiB', 'TiB', 'PiB', 'EiB'];
            break;
        case 'bps':
            sizes = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'Ebps'];
            break;
        case 'B/s':
            sizes = ['B/s', 'KB/s', 'MB/s', 'GB/s', 'TB/s', 'PB/s', 'EB/s'];
            break;
        case 'Bps':
            sizes = ['Bps', 'KBps', 'MBps', 'GBps', 'TBps', 'PBps', 'EBps'];
            break;
        default:
            return transition;
    }
    const precision = (parseInt((data / Math.pow(k, i)) + '') + '').length + 1;
    transition.value = parseFloat((data / Math.pow(k, i)).toPrecision(precision));
    transition.units = sizes[i];
    return `${transition.value}${transition.units}`;
}

export function capacityPercent(used, total, totalUnit) {
    const sizes = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB'];
    const k = 1024;
    const i = sizes.indexOf(totalUnit);

    if (i === -1 || used === 0) {
        return 0;
    }

    return used / (total * Math.pow(k, i)) * 100;
}

export function MbpsToMBs(value: string | number, unit: 'Mbps' | '元/Mbps/小时' | '元/Mbps/s/小时') {
    const units = ['Mbps', '元/Mbps/小时', '元/Mbps/s/小时'];
    if (!units.includes(unit) || Number(value) < 0) {
        return 0;
    }
    switch (unit) {
        case 'Mbps':
            return `${new Decimal(value).div(8).toFixed(2)}MB/s`;
        case '元/Mbps/小时':
            return `${new Decimal(value).mul(8).toFixed(2)}元/MB/小时`;
        case '元/Mbps/s/小时':
            return `${new Decimal(value).mul(8).toFixed(2)}元/MB/s/小时`;
        default:
            return 0;
    }

}

export function GiBToGB(value: string | number, unit: 'GiB' | '元/GiB/小时', priceTime?: '天' | '月') {
    const units = ['GiB', '元/GiB/小时'];
    const defualtPriceTime = '小时';
    const rate = priceTime === '天' ? 24 : priceTime === '月' ? 720 : 1;
    // 1GiB =（1024 * 1024 * 1024）B = 1,073,741,824B
    // 1GB =（1000 * 1000 * 1000）B = 1,000,000,000B
    // 1GiB / 1GB = 1,073,741,824 / 1,000,000,000 = 1.073741824
    const quantity = 1.073741824;
    if (!units.includes(unit) || Number(value) < 0) {
        return 0;
    }
    switch (unit) {
        case 'GiB':
            return `${new Decimal(value).mul(quantity).toFixed(2)}GB`;
        case '元/GiB/小时':
            return `${new Decimal(value).mul(quantity).mul(rate).toFixed(2)}元/GB/${priceTime || defualtPriceTime}`;
        default:
            return 0;
    }
}

// export function unitTransition(oldData, oldUnit) {
//     oldData = parseFloat(oldData);
//     const transitionObj = {
//         value: oldData,
//         units: oldUnit,
//     };

//     if (oldData === 0) {
//         return transitionObj;
//     }
//     const k = oldUnit === 'B/s' || oldUnit === 'Bps' ? 1000 : 1024;
//     let sizes = [] as string[];
//     const i = Math.floor(Math.log(oldData) / Math.log(k));
//     switch (oldUnit) {
//         case 'B':
//             sizes = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB'];
//             break;
//         case 'GB':
//             sizes = ['GiB', 'TiB', 'PiB', 'EiB'];
//             break;
//         case 'MB':
//             sizes = ['MiB', 'GiB', 'TiB', 'PiB', 'EiB'];
//             break;
//         case 'bps':
//             sizes = ['bps', 'kbps', 'mbps', 'gbps', 'tbps'];
//             break;
//         case 'B/s':
//             sizes = ['B/s', 'KB/s', 'MB/s', 'GB/s', 'TB/s', 'PB/s', 'EB/s'];
//             break;
//         case 'Bps':
//             sizes = ['Bps', 'KBps', 'MBps', 'GBps', 'TBps', 'PBps', 'EBps'];
//             break;
//         default:
//             return transitionObj;
//     }
//     const precision = (parseInt((oldData / Math.pow(k, i)) + '') + '').length + 1;
//     transitionObj.value = parseFloat((oldData / Math.pow(k, i)).toPrecision(precision));
//     transitionObj.units = sizes[i];
//     return transitionObj;
// }
