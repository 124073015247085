import { CopyOutlined } from "@ant-design/icons";
import React from "react";
import { CopyIcon } from "../../components/icons.component";

export const MountStepAwsFusedContainer = ({ cidr, copyContent, instanceId, clientId, vip }) => {
    return <td>
        <p>1、文件系统创建完成后，需要建立对等网络连接</p>
        <p className="content-secondary">
            a)打开<a href="https://cn-northwest-1.signin.amazonaws.cn" target="_blank">AWS</a>
            ——相应区域——VPC控制面板——对等连接。接受平台AWS账户（9723-9008-6825）发送的对等连接申请。对等连接状态由“正在处理接受”，变为“活动”即可。
        </p>
        <p className="content-secondary">
            b)在相应的VPC路由表中，添加一条
            <span id="content_cidr">{cidr}</span>
            <a onClick={() => copyContent('cidr')} className="copy-button" title="复制至剪贴板">
                <CopyIcon />
            </a>
            ，指向peering connection（该对等连接）的路由
        </p>
        <p className="content-secondary">c)增加安全组入站规则：UDP&nbsp;&nbsp;7004 - 7014&nbsp;&nbsp;{cidr}</p>
        <p className="content-primary">2、挂载性能型文件系统</p>
        <p className="content-secondary">a)准备工作</p>
        <p className="content-three-level">i.执行以下命令检查kernel-devel以及kernel-headers包版本和kernel版本的一致性</p>
        <code>
            <p>#uname -r</p>
            <p>#rpm -qa | grep kernel</p>
            <p>#rpm -qa | grep "^gcc"</p>
        </code>
        <p className="content-three-level">ii.若缺少以上包，请您下载并执行以下命令安装软件依赖包</p>
        <code>
            <p>下载gcc，kernel-header，kernel-devel</p>
            <p>#yum install gcc</p>
            <p>#yum install kernel-headers-$(uname -r) kernel-devel-$(uname -r)</p>
        </code>
        <p className="content-secondary">b)挂载文件系统</p>
        <code>
            <p>
                #&nbsp;
                <span id="content_mount">curl -SL http://{vip}/yr-install.sh | bash -s
                    /{`${instanceId} ${clientId}` as string}
                </span>
                <a onClick={() => copyContent('mount')} className="copy-button" title="复制至剪贴板">
                    <CopyIcon />
                </a>
            </p>
            <p>#&nbsp;df -h</p>
        </code>
        <p className="content-primary">3、挂载容量型文件系统（容量型和性能型不能同时挂载在同一台服务器上）</p>
        <p className="content-secondary">
            a)获取DNS: 打开<a href="https://cn-northwest-1.signin.amazonaws.cn" target="_blank">AWS</a>
            ——相应区域——VPC控制面板——终端节点——详细信息，获取<i>DNS名称</i>，复制DNS名称。
        </p>
        <p className="content-secondary">b)ssh到需要挂载文件系统的虚拟机，挂载文件系统。 </p>
        <code>
            <p>
                #&nbsp;
                <span id="content_fused_mkdir">mkdir -p /mnt/yrfs</span>
                <a onClick={() => copyContent('fused_mkdir')} className="copy-button" title="复制至剪贴板">
                    <CopyIcon />
                </a>
            </p>
            <p>
                #&nbsp;
                <span
                    id="content_fused_mount">
                    mount -t nfs -o tcp,nolock,nfsvers=3 <i>DNS名称</i>:/mnt/yrfs/{instanceId} /mnt/yrfs
                </span>
                <a onClick={() => copyContent('fused_mount')} className="copy-button" title="复制至剪贴板">
                    <CopyIcon />
                </a>
            </p>
            <p>
                #&nbsp;
                <span id="content_fused_df">df -h</span>
                <a onClick={() => copyContent('fused_df')} className="copy-button" title="复制至剪贴板">
                    <CopyIcon />
                </a>
            </p>
        </code>
    </td>;
}
