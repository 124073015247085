import { MapReducer } from "../../../redux/reducers/map.reducer";
import { INSTANCE } from '../constants/action-types.constants';
export class InstanceReducer extends MapReducer {
    private static defaults = {
        instances: [],
        region: {
            platformsData: [],
            regionsData: {},
        },
        instance: {},
        monitor: {
            bandwidth: [],
            inode: [],
            iops: [],
            capacity: [],
        },
        oldInstance: {},
        products: {},
    }
    constructor() {
        super(INSTANCE, InstanceReducer.defaults);
    }
}