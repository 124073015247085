import { Checkbox, Table, Button, Space, Divider } from 'antd';
import { PageTypography } from './../../components/page.typography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Fetch, Pagination as PaginationInterface, State } from '../../../interfaces';
import { MessageAction } from '../actions/message.action';
import './message.container.less';
import { GET_MESSAGES } from "../constants/fetch.constants";
import { DefaultTable, DefaultTableText } from '../../components/default-table.component';
import { useMount } from 'react-use';

const typeMap = {
    notification: '网站通知',
    system: '系统消息',
}

export const MessageContainer = (props) => {
    const { type } = props?.match?.params;
    const dispatch = useDispatch();
    const [selected, updateSelected] = useState<any[]>([]);
    const [indeterminate, updateIndeterminate] = useState(false);
    const [checkAll, updateCheckAll] = useState(false);
    const [messageId, updateMessageId] = useState('');
    const [messageReadId, updateMessageReadId] = useState('');
    const [messageHeight, updateMessageHeight] = useState(500);

    const messages = useSelector<any, any>(state => state.message.messages ?? [] as any);
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_MESSAGES] ?? {});
    const { pagination } = useSelector<State, { pagination: PaginationInterface }>(
        state => ({ pagination: state.pagination.message ?? {} as any }),
    );
    const { message } = useSelector<any, any>(
        state => state.message ?? {} as any,
    );
    useEffect(() => {
        if (type) {
            getMessages();
        }
    }, [type]);
    useEffect(() => {
        if (messageId) {
            dispatch(MessageAction.getMessage(messageId));
            dispatch(MessageAction.readMessages(() => getMessages(), [messageReadId]));
            getMessages();
        } else {
            updateMessageId(messages[0]?.messageId);
            dispatch(MessageAction.getMessage(messages[0]?.messageId));
        }
    }, [messageId, messages?.length]);
    useMount(() => {
        updateMessageHeight(window.innerHeight - 278);
        window.onresize = () => {
            updateMessageHeight(window.innerHeight - 278);
        }
    });

    const getMessages = () => {
        dispatch(MessageAction.getMessages({ page: 1, size: 1000, type: typeMap[type] }));
        // dispatch(MessageAction.getAllMessages({ type: typeMap[type] }));
    };
    const selectAll = (checked) => {
        if (checked) {
            const selectedRowKeys = messages?.map((item) => item.id);
            updateSelected(selectedRowKeys);
            updateCheckAll(true);
            updateIndeterminate(false);
        } else {
            updateSelected([]);
            updateCheckAll(false);
            updateIndeterminate(false);
        }
    };

    const pushState = (url: string) => window.location.hash = url;
    const switchType = (type: string) => {
        pushState(`/console/message/${type}`);
        updateSelected([]);
        updateCheckAll(false);
        updateIndeterminate(false);
    };
    return <div className="message-container">
        <PageTypography title='系统消息'>系统发出消息、通知等信息时，会实时推送到消息中心，您也可以在这里查看系统发送的历史信息。</PageTypography>
        <ul className="message-type">
            <li className={type === 'all' ? 'active' : ''} onClick={() => switchType('all')}>全部</li>
            <li className={type === 'notification' ? 'active' : ''} onClick={() => switchType('notification')}>网站通知</li>
            <li className={type === 'system' ? 'active' : ''} onClick={() => switchType('system')}>系统消息</li>
        </ul>
        <div className="message-box" style={{ height: messageHeight, maxHeight: 600 }}>
            <div className="message-list">
                <div className="tools">
                    <p className="total">合计：{messages?.length ?? 0}</p>
                    <Space size={8} style={{ marginLeft: 'auto' }}>
                        <Checkbox onChange={(e) => selectAll(e.target.checked)} indeterminate={indeterminate} checked={checkAll}>全选</Checkbox>
                        <Button className="btn-small" onClick={() => dispatch(MessageAction.deleteMessages(
                            () => {
                                getMessages();
                                dispatch(MessageAction.getMessage(messages[0]?.messageId));
                            }, selected)
                        )}>删除</Button>
                        <Button type="primary" className="btn-primary-black btn-small" onClick={() => dispatch(MessageAction.readMessages(
                            () => getMessages())
                        )}>全部已读</Button>
                    </Space>
                </div>
                <Table
                    className="table"
                    rowKey="id"
                    dataSource={messages}
                    showHeader={false}
                    pagination={false}
                    loading={fetching}
                    rowClassName={record => record.messageId === messageId ? 'row-selected' : ''}
                    rowSelection={{
                        type: "checkbox",
                        onChange: (selectedRowKeys) => {
                            updateIndeterminate(!!selectedRowKeys.length && selectedRowKeys.length < messages.length);
                            updateCheckAll(selectedRowKeys.length === messages.length);
                            updateSelected(selectedRowKeys);
                        },
                        selectedRowKeys: selected,
                    }}
                    locale={{
                        emptyText: () => <DefaultTableText />
                    }}
                >
                    <Table.Column key="message" render={(message) => {
                        return <span className="message-item" onClick={() => {
                            updateMessageId(message.messageId);
                            updateMessageReadId(message.id);
                        }}>
                            <p className="title">{message.title}</p>
                            <p className="time">{moment(message.createdAt).format('YYYY-MM-DD HH:mm:ss')}</p>
                            <div className="message-hint" style={{ display: message.isRead ? 'none' : 'block' }}></div>
                        </span>
                    }} />
                </Table>
            </div>
            {messages.length === 0 ? <div className="message-detail">
                <DefaultTable />
            </div> : <div className="message-detail">
                <p className="title">{message?.title}</p>
                <Divider type="horizontal" style={{ borderColor: '#E6ECF4', margin: '24px 0' }} />
                <p className="time">发布于：{moment(message?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</p>
                <div className="article yr-message" dangerouslySetInnerHTML={{ __html: message?.message }} />
            </div>}
        </div>
    </div>
}
