import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { LoginLayout } from '../../components/login-layout.component'
import { useDispatch } from 'react-redux';
import { LoginAction } from '../actions/login.action';
import './sign-up.container.less';
import { Link } from 'react-router-dom';
import { EmailIcon, PasswordIcon, PhoneIcon, VerifyIcon } from '../../components/icons.component';

export const SignUpContainer = (props) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [remaining, updateRemaining] = useState(60);
    const [isChecked, updateIsChecked] = useState(false);
    useEffect(() => {
        switch (remaining) {
            case 0:
                updateRemaining(60);
                break;
            case 60:
                break;
            default:
                setTimeout(() => {
                    updateRemaining((remaining: number) => remaining - 1);
                }, 1000);
                break;
        }
    }, [remaining]);

    const submit = (values) => {
        dispatch(LoginAction.signUp(
            values,
            () => pushState(`/sign-up/success?email=${encodeURIComponent(values.email)}`),
        ));
    };

    const sendSms = () => {
        form.validateFields(['phone'])
            .then(values => {
                dispatch(LoginAction.sendSignUpSms(values.phone));
                updateRemaining(59);
            })
            .catch(info => {
                console.log('validate error: ', info);
            });
    };
    const pushState = (url: string) => {
        window.location.hash = url;
    };

    return <LoginLayout>
        <div className="sign-up-container">
            <p className="title">欢迎注册焱融SaaS文件存储账号</p>
            <Form
                form={form}
                onFinish={(values) => submit(values)}
                className="sign-up-form"
            >
                <Form.Item
                    name="email"
                    rules={[
                        { required: true, message: '请输入邮箱' },
                        { type: 'email', message: '请输入正确的邮箱' },
                    ]}
                >
                    <Input placeholder="请输入邮箱" prefix={<EmailIcon />} />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: '请输入密码' }]}
                >
                    <Input.Password placeholder="请输入密码" prefix={<PasswordIcon />} />
                </Form.Item>
                <Form.Item
                    name="repassword"
                    rules={[
                        { required: true, message: '请再次输入密码' },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('两次输入的密码不一致');
                            }
                        })
                    ]}
                >
                    <Input.Password placeholder="确认密码" prefix={<PasswordIcon />} />
                </Form.Item>
                <Form.Item
                    name="phone"
                    rules={[
                        () => ({
                            validator(rule, value) {
                                const pattern = /^1[3-9]\d{9}$/;
                                if (pattern.test(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('请输入正确的手机号');
                            },
                        }),
                    ]}
                >
                    <Input placeholder="手机号" prefix={<PhoneIcon />} />
                </Form.Item>
                <Form.Item
                    name="verify"
                    rules={[{ required: true, message: '请输入验证码' }]}
                    className="verify-item"
                >
                    <Input addonAfter={<Button
                        onClick={() => sendSms()}
                        disabled={remaining !== 60}
                    >
                        {remaining === 60 ? '发送验证码' : `${remaining}秒后重新发送`}
                    </Button>} placeholder="验证码" prefix={<VerifyIcon />} />
                </Form.Item>
                <Checkbox onChange={(e) => updateIsChecked(e.target.checked)}>
                    我已阅读并同意<Link to="/console/documents/terms" target="_blank">用户服务协议</Link>
                </Checkbox>
                <br />
                <Button
                    className="btn-register"
                    type="primary"
                    disabled={!isChecked}
                    onClick={() => form.submit()}
                    style={{ marginTop: 12 }}>立即注册</Button>
            </Form>
            <div className="sign-in-tools">
                <p>已有账号？<Link to="/sign-in">立即登录</Link></p>
            </div>
            <div className="mobile-btn-box">
                <Button type="primary" disabled={!isChecked} onClick={() => form.submit()} className="btn-register-mobile">注册</Button>
                <Link to="/sign-in" className="btn-login-mobile">立即登录</Link>
            </div>
        </div>
    </LoginLayout>
}
