import { Button, Table, Spin } from 'antd';
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux'
import { invoiceType, InvoiceTitleType } from './../enums'
import { Fetch, State, Pagination, Modal as ModalInterface } from './../../../interfaces'
import { InvoiceAction } from './../actions'
import { DefaultTable } from '../../components/default-table.component';
import { ModalAction } from "../../../redux";
import { tofixed } from './../../../utils';
import { INVOICE_NEWS_MODAL } from './../constants/modal.constants'
import { InvoiceNews } from '../modals/invoice-news'
import { GET_INVOICE, GET_INVOICE_MAKE, GET_INVOICE_INFO, GET_INVOICE_ADDRESS } from '../constants/fetch.constants';
import { PageTypography } from './../../components/page.typography';
import { CardNav } from './../../components/card.navigation'
import './new.invoice.container.less'


export const NewInvoiceContainer = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { invoices, invo_amount, invo_info, invo_address } = useSelector<State, any>(state => state.invoice);
    const { visible } = useSelector<State, ModalInterface>((state) => state.modals[INVOICE_NEWS_MODAL] ?? {} as any);
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_INVOICE] ?? {} as any)
    const { fetching: make_loading } = useSelector<State, Fetch>(state => state.fetch[GET_INVOICE_MAKE] ?? {} as any)
    const { fetching: info_loading } = useSelector<State, Fetch>(state => state.fetch[GET_INVOICE_INFO] ?? {} as any)
    const { fetching: address_loading } = useSelector<State, Fetch>(state => state.fetch[GET_INVOICE_ADDRESS] ?? {} as any)
    const { pagination } = useSelector<State, { pagination: Pagination }>(
        (state) => ({ pagination: state.pagination.invoice ?? ({} as any) })
    );
    const onPageChange = (page: number, size: number) => {
        dispatch(InvoiceAction.getInvoiceList(page, size));
    }
    const refresh = (page = pagination.page, size = pagination.size) => {
        dispatch(InvoiceAction.getInvoiceList(page, size));
        dispatch(InvoiceAction.getMakeMoney());
        dispatch(InvoiceAction.getInvoiceInfo());
        dispatch(InvoiceAction.getInvoiceAddress());
    }
    useEffect(() => {
        refresh()
    }, []);
    return (
        <div className="invoice-container">
            <PageTypography title='发票'>您可在此页面申请和管理发票信息。</PageTypography>
            <div className="invoice-title">
                <Spin spinning={make_loading}>
                    <div className="invoice-apply">
                        <div className="apply-info">
                            <p>可开发票金额</p>
                            <b>¥{invo_amount.available}</b>
                            <p>已开发票金额：<span>¥{invo_amount.used}</span></p>
                        </div>
                        <Button type="primary" disabled={invo_amount.available <= 0 ?? true} onClick={() => { history.push('/console/account/invoice/list') }}>申请发票</Button>
                    </div>
                </Spin>
                <Spin spinning={info_loading}>
                    <div className="invoice-info">
                        <p>
                            <b>发票信息</b>
                            <span onClick={() => { dispatch(ModalAction.open(INVOICE_NEWS_MODAL, invo_info)) }}>管理</span>
                        </p>
                        <p>默认抬头：{invo_info?.title ?? '-'}</p>
                        <p>开具类型：{InvoiceTitleType[invo_info?.titleType] ?? '-'}</p>
                    </div>
                </Spin>
                <Spin spinning={address_loading}>
                    <div className="invoice-address">
                        <p>
                            <b>默认邮寄地址</b>
                            <Link to='/console/account/invoice/address'>管理</Link>
                        </p>
                        {Object.getOwnPropertyNames(invo_address).length ?
                            <>
                                <p>{invo_address?.addressee}<span>{invo_address.telephone}</span></p>
                                <p className="address-max">{JSON.parse(invo_address.area).join('') + invo_address.detailedAddress}</p>
                            </>
                            :
                            <>
                                <p>-</p>
                                <p>-</p>
                            </>
                        }
                    </div>
                </Spin>
            </div>
            <CardNav title='开票记录'>
                <Table
                    dataSource={invoices}
                    rowKey='id'
                    pagination={{
                        total: pagination.total,
                        defaultPageSize: pagination.size,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "50", "100"],
                        current: pagination.page,
                        onChange: onPageChange as any

                    }}
                    loading={fetching}
                    locale={{
                        emptyText: () => <DefaultTable text='暂无开票记录' />
                    }}

                >
                    <Table.Column title='开票时间' dataIndex='invoiceTime' render={(text) => {
                        return moment(text).format('YYYY-MM-DD')
                    }}></Table.Column>
                    <Table.Column title='开票类型' dataIndex='invoiceType' render={(text) => invoiceType[text]}></Table.Column>
                    <Table.Column title='发票抬头' dataIndex='title'></Table.Column>
                    <Table.Column title='发票金额' dataIndex='money' render={(text) => {
                        return <span>¥{tofixed(text)}</span>
                    }}></Table.Column>
                    {/* <Table.Column title='发票备注' dataIndex='remarks' render={(text) => {
                        return <span>{text ? text : '-'}</span>
                    }}></Table.Column> */}
                </Table>
            </CardNav>
            {visible && <InvoiceNews />}
        </div>
    )
}