import { Button, Form, message as Message, Checkbox, Input, Slider } from 'antd';
import { PageTypography } from './../../components/page.typography';
import { CardNav } from './../../components/card.navigation'
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMount, useUnmount } from 'react-use';
import { State, Fetch } from '../../../interfaces';
import { FetchAction } from '../../../redux';
import { InstanceAction } from '../actions/instance.action';
import { CREATE_UPGRADE_ORDER, GET_UPGRADE_PRICE } from '../constants/fetch.constants';
import { BillingModeType, ProductType } from '../enums';
import './renew-instance.container.less';

let timer;
const bandwidthReg = /fs\.\w+\.bandwidth/;

export const UpgradeBandwidthContainer = (props) => {

    const instanceId = props?.match?.params?.instanceId;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [isAgree, updateIsAgree] = useState(false);

    const upgrade = useSelector<any, any>(
        state => state.fee.upgrade ?? {} as any,
    );
    const { instance } = useSelector<State, any>(
        state => state.instance ?? {} as any,
    );
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[CREATE_UPGRADE_ORDER] ?? {} as any);
    const { fetching: feeFetching } = useSelector<State, Fetch>(
        state => state.fetch[GET_UPGRADE_PRICE] ?? {} as any,
    );

    useMount(() => {
        dispatch(InstanceAction.getInstance(instanceId));
    });
    useEffect(() => {
        dispatch(InstanceAction.getProducts(instance?.regionId, instance?.billingMode));
    }, [instance.id]);
    useUnmount(() => {
        dispatch(InstanceAction.clearInstance());
    });

    const products = useSelector<State, any>(state => state.instance.products);

    const productInst = products?.filter(item => item.type === instance?.type)[0];
    const bandwidthBillingItem = productInst?.billingItems.filter(item => bandwidthReg.test(item.type))[0];
    const instanceBandwidthItem = instance?.items?.filter(item => bandwidthReg.test(item.type))[0];
    const [bandwidth, setBandwidth] = useState(bandwidthBillingItem?.min ?? 0);
    const getUpgradePrice = () => {
        clearTimeout(timer);
        dispatch(FetchAction.fetching(GET_UPGRADE_PRICE));
        timer = setTimeout(async () => {
            try {
                await form.validateFields(['amount', 'bandwidthInput']);
            } catch ({ errorFields }) {
                // @ts-ignore
                if (errorFields.length) {
                    return;
                }
            }

            const { amount } = form.getFieldsValue();
            const params = {
                amount: Number(amount),
                orderType: 'upgrade',
                instanceId: instance?.id,
                billingItem: instanceBandwidthItem?.type,
            };
            dispatch(InstanceAction.getUpgrade(params));
        }, 100);
    };
    const submit = () => {
        const { amount } = form.getFieldsValue();
        const params = {
            amount: Number(amount),
            orderType: 'upgrade',
            instanceId: instance?.id,
            billingItem: instanceBandwidthItem?.type,
        };
        dispatch(InstanceAction.createUpgradeOrder(params, (orderId) => {
            Message.success('创建订单成功');
            pushState(`/console/account/payment/${orderId}`);
        }));
    };

    const calculatePrice = () => {
        if (feeFetching) {
            return <span className="loading">加载中</span>;
        }
        const billingMode = instance.billingMode;
        if (billingMode === 'prepay') {
            return <p className="price">
                <span className="label">配置金额：￥</span>
                <span className="amount">
                    {upgrade?.originalAmount === undefined ? '--' : `${upgrade?.originalAmount}`}
                </span>
            </p>
        }

        if (billingMode === 'afterpay') {
            return <p className="price">
                {
                    upgrade?.items ? upgrade?.items?.map(item => {
                        let label = '';
                        if (item.billingItem.indexOf('bandwidth') === -1) {
                            label = '容量金额';
                        } else {
                            label = '预置吞吐量';
                        }

                        const bandwidth = form.getFieldValue('amount');
                        const value = item.billingItem.indexOf('bandwidth') !== -1 ?
                            `￥ ${(item.price * 24 * 30 * bandwidth).toFixed(2)}` :
                            `￥ ${(item.price * 24 * 30).toFixed(2)}`;
                        const unit = item.billingItem.indexOf('bandwidth') !== -1 ? '/月' : `/${item.unit}/月`;
                        return <div className="price-item" key={`${item.product}_${item.billingItem}`}>
                            <span className="label">{label}</span>
                            <span className="amount">{value}</span>
                            <span className="unit">{unit}</span>
                        </div>;
                    }) : <span>
                        <span className="label">配置金额：￥</span>
                        <span className="amount">
                            {upgrade?.originalAmount === undefined ? '--' : `${upgrade?.originalAmount}`}
                        </span>
                    </span>
                }
            </p>
        }
    }

    const pushState = (url: string) => window.location.hash = url;
    return <div className="renew-instance-container">
        <PageTypography title='文件系统'>焱融SaaS文件存储平台，是构建在公有云基础架构上的共享NAS数据服务平台，为用户提供更快、更经济、更灵活的数据共享服务。用户可选择POSIX文件接口或者标准NFS共享接口访问数据，满足不同应用场景下多样化的存储需求。</PageTypography>
        <CardNav title='扩容带宽' link='/console/instances'>
            <Form
                className="form"
                form={form}
                layout="vertical"
                colon={false}
                onValuesChange={() => getUpgradePrice()}
                onFinish={() => submit()}
            >
                <Form.Item label="付费模式">{BillingModeType[instance?.billingMode]}</Form.Item>
                <Form.Item label="文件系统名">
                    <Input disabled value={instance?.name} />
                </Form.Item>
                <Form.Item label="存储类型">
                    <Input disabled value={ProductType[instance?.type]} />
                </Form.Item>
                <Form.Item label="初始带宽">{instanceBandwidthItem?.amount} {instanceBandwidthItem?.unit}</Form.Item>
                <Form.Item label="到期时间">{moment(instance?.expireTime).format('YYYY-MM-DD')}</Form.Item>
                <Form.Item
                    label="预置吞吐量"
                    name="amount"
                    required
                >
                    <Slider
                        getTooltipPopupContainer={node => node.parentNode as HTMLElement}
                        max={bandwidthBillingItem?.max}
                        min={instanceBandwidthItem?.amount}
                        step={10}
                        marks={{
                            [bandwidthBillingItem?.min]: `${bandwidthBillingItem?.min ?? 0}MB`,
                            [bandwidthBillingItem?.max]: `${bandwidthBillingItem?.max ?? 0}MG`,
                        }}
                        tooltipPlacement="bottom"
                        tipFormatter={value => `${value}MB`}
                        tooltipVisible={true}
                        className="storage-slider"
                    />
                </Form.Item>
                <Checkbox onChange={(e) => updateIsAgree(e.target.checked)}>
                    同意<Link to="/console/documents/terms" target="_blank">用户协议</Link>
                </Checkbox>
            </Form>
            <div className="form-footer">
                {calculatePrice()}
                <Button
                    loading={fetching}
                    type="primary"
                    disabled={!isAgree}
                    onClick={() => form.submit()}
                    className="btn-submit">
                    提交订单
                </Button>
            </div>
        </CardNav>
    </div>
}
