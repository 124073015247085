import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './styles/index.less';
import { HashRouter as Router } from 'react-router-dom';
import { Routes } from './modules';
import store from './redux/store';

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Routes/>
        </Router>
    </Provider>,
    document.getElementById('root')
);

