import React from 'react';
import './button.component.less';

export const Button = (props) => {
    return <a
        className={`button ${props.type}-button ${props.disabled ? 'disabled' : ''} ${props.className ?? ''}`}
        onClick={props.disabled || props.loading ? () => void 0 : props.onClick}
        style={props.style ?? {}}>
        {props.loading ? props.loadingText : props.title}
    </a>;
}
