import { MapReducer } from "../../../redux/reducers/map.reducer";
import { FEE } from '../constants/action-types.constants';

export class FeeReducer extends MapReducer {
    private static defaults = {
        purchase: {},
        renew: {},
        upgrade: {},
        modify: {},
    }
    constructor() {
        super(FEE, FeeReducer.defaults);
    }
}