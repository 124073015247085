import { MapReducer } from '../../../redux/reducers/map.reducer';
import { TICKET } from '../constants/action-type.constants';

export class TicketReducer extends MapReducer {
    private static defaults = {
        tickets: [],
        categories: [],
        templates: [],
        ticket: {},
    }
    constructor() {
        super(TICKET, TicketReducer.defaults);
    }
}