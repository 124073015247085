import { Modal, Form, Select, Input, Checkbox, Space } from 'antd'
import { useState } from 'react'
import { useDeepCompareEffect } from 'react-use';
import * as _ from 'lodash';
import { State, Modal as ModalInterface, Fetch } from '../../../interfaces'
import { citiesOptions } from './../../../utils'; // 地址
import { useDispatch, useSelector } from 'react-redux';
import { ModalAction } from '../../../redux'
import { createAddressse, createRegisteredAddress, createTelephone } from './../../../utils'
import { INVOICE_ADDRESS_MODAL } from '../constants/modal.constants'
import { SET_INVOICE_ADDRESS } from '../constants/fetch.constants'
import { InvoiceAction } from '../actions/invoice.action';
import { CloseModalIcon } from '../../components/icons.component';

export const InvoiceAddress = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { fetching } = useSelector<State, Fetch>((store) => store.fetch[SET_INVOICE_ADDRESS] ?? {} as any);
    const { visible, ...record } = useSelector<State, ModalInterface>((state) => state.modals[INVOICE_ADDRESS_MODAL] ?? {} as any);
    // 获取默认值使用
    const getAreaVal = (arr: any[], i) => {
        const areaArr = JSON.parse(record.area);
        const index = _.findIndex(arr, (o) => o.value === areaArr[i])
        return arr[index]
    }
    const [cities, setCities] = useState(record?.isUpdata ? getAreaVal(citiesOptions, 0) : citiesOptions[0]); // 默认北京
    const [secondCity, setSecondCity] = useState(record?.isUpdata ? getAreaVal(cities.children, 1) : cities.children[0]);
    const [thirdlyCity, setThirdlyCity] = useState(record?.isUpdata ? getAreaVal(secondCity.children, 2) : secondCity.children[0]);

    const handleProvinceChange = (value) => {
        console.log(value, '--1111--->')
        const index = _.findIndex(citiesOptions, (o: any) => o.value === value)
        setCities(citiesOptions[index]);
        setSecondCity(citiesOptions[index].children[0]);
        setThirdlyCity(citiesOptions[index].children[0].children[0])
    };

    const onSecondCityChange = value => {
        console.log(value, '--222--->')
        const index = _.findIndex(cities.children, (o: any) => o.value === value)
        setSecondCity(cities.children[index]);
        setThirdlyCity(cities.children[index].children[0])
    };
    const onThirdlyCityChange = value => {
        console.log(value, '--333--->')
        const index = _.findIndex(secondCity.children, (o: any) => o.value === value)
        setThirdlyCity(secondCity.children[index])
    };

    const submit = (values) => {
        console.log(cities.value, secondCity.value, thirdlyCity.value)
        const { default: checked, ...newValues } = values;
        const data = {
            area: JSON.stringify([cities.value, secondCity.value, thirdlyCity.value]),
            default: checked ? 1 : 0,
            ...newValues
        }
        if (record.isUpdata) {
            dispatch(InvoiceAction.updataInvoiceAddress(record.id, data))
        } else {
            dispatch(InvoiceAction.addInvoiceAddress(data))
        }
    }

    useDeepCompareEffect(() => {
        if (record.isUpdata) {
            const { default: checked } = record;
            form.setFieldsValue({ default: Boolean(checked) });
            form.resetFields();
        }
    }, [record])
    return (
        <Modal
            visible={visible}
            className="address-modal"
            onCancel={() => { dispatch(ModalAction.close(INVOICE_ADDRESS_MODAL)) }}
            title={record?.isUpdata ? '修改地址' : '添加地址'}
            width={700}
            onOk={() => { form.submit() }}
            okText='确定'
            centered={true}
            destroyOnClose={true}
            confirmLoading={fetching}
            closeIcon={<CloseModalIcon />}
        >
            <Form
                preserve={false}
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 19 }}
                onFinish={(values) => submit(values)}
                layout='horizontal'
                className="invoiceTitle"
                initialValues={{
                    ...record
                }}
            >
                <Form.Item label="收件人" name='addressee' validateFirst={true} required rules={[
                    { required: true, message: '请输入收件人' },
                    { validator: createAddressse, message: '收件人姓名为2-30个字符' },
                ]}
                    getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
                >
                    <Input placeholder="请输入收件人"></Input>
                </Form.Item>
                <Form.Item label="选择地区" required>
                    <Space size={10} align="baseline">
                        <Select defaultValue={cities.value} style={{ width: 165 }} onChange={handleProvinceChange}>
                            {citiesOptions.map(province => (
                                <Select.Option key={province.value}>{province.label}</Select.Option>
                            ))}
                        </Select>
                        <Select style={{ width: 165 }} value={secondCity.value} onChange={onSecondCityChange}>
                            {cities.children.map(city => (
                                <Select.Option key={city.value}>{city.label}</Select.Option>
                            ))}
                        </Select>
                        <Select style={{ width: 165 }} value={thirdlyCity.value} onChange={onThirdlyCityChange}>
                            {secondCity.children.map(third => (
                                <Select.Option key={third.value}>{third.label}</Select.Option>
                            ))}
                        </Select>
                    </Space>

                </Form.Item>

                <Form.Item label="详细地址" name='detailedAddress' validateFirst={true} required rules={[
                    { required: true, message: '请输入详细地址' },
                    { validator: createRegisteredAddress, message: '详细地址最少为3个字符' },
                ]}
                    getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
                >
                    {/* <Input placeholder="请输入详细地址"></Input> */}
                    <Input.TextArea placeholder="请输入详细地址" autoSize={{ minRows: 2 }} />
                </Form.Item>
                <Form.Item label="联系电话" name='telephone' validateFirst={true} required rules={[
                    { required: true, message: '请输入联系电话' },
                    // {
                    //     pattern: /^1[3|4|5|7|8][0-9]\d{8}$/, message: '请输入正确的手机号'
                    // },
                    { validator: createTelephone, message: '请输入正确的手机号/联系电话' },
                ]}>
                    <Input placeholder="请输入联系电话"></Input>
                </Form.Item>
                <Form.Item label="邮政编码" name='postcode' rules={[
                    {
                        pattern: /^\d{6}$/, message: '邮政编码为6位数字'
                    },
                ]}
                >
                    <Input placeholder="请输入邮政编码"></Input>
                </Form.Item>
                <Form.Item
                    className="address-default"
                    name='default'
                    valuePropName="checked"
                    wrapperCol={{ offset: 4 }}>
                    <Checkbox>默认地址</Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    )
}