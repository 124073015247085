import { useState } from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { Cascader, Form, Input, Upload, message as Message,  Button } from 'antd';
import { PageTypography } from './../../components/page.typography';
import { CardNav } from './../../components/card.navigation'
import './ticket-submit.container.less';
import { useDispatch, useSelector } from 'react-redux';
import { TicketAction } from '../actions/ticket.action';
import { useMount, useUnmount } from 'react-use';
import { UploadIcon } from '../../components/icons.component';

export const TicketSubmitContainer = () => {

    const [templateId, updateTemplateId] = useState<string>('');
    const [fileList, updateFileList] = useState<any[]>([]);
    const [previewImage, updatePreviewImage] = useState('');
    const [previewVisible, updatePreviewVisible] = useState(false);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const templates = useSelector<any, any>(
        state => state.ticket.templates ?? [] as any,
    );
    const { phone, email } = useSelector<any, any>(
        state => state.session ?? {} as any,
    );
    const template = templates.map((tpl) => tpl.children.filter((child) => child.value === templateId)[0]).filter(i => i)[0];
    useMount(() => {
        dispatch(TicketAction.getCategories());
        dispatch(TicketAction.getTemplates());
    });
    useUnmount(() => {
        form.resetFields();
    });

    const submit = (values) => {
        let error = false;
        const attachments = fileList.map((item: any) => {
            if (item?.response?.statusCode === 415) error = true;
            return item?.response?.name;
        });
        if (error) {
            Message.error('请重新上传附件');
            return false;
        }
        const metas: any = [];
        Object.keys(values).forEach(key => {
            if (key.startsWith('metas_')) {
                metas.push({ field: key.replace('metas_', ''), value: values[key] });
                delete values[key];
            }
        });
        const params = {
            ...values,
            templateId: values.templateIds[1],
            attachments,
            metas,
            phone,
            email,
        };
        dispatch(TicketAction.createTicket(params, () => {
            Message.success('创建工单成功');
            window.location.hash = '/console/tickets';
        }));
    };

    const handlePicChange = ({ fileList }) => {
        fileList = fileList.filter(file => file.size < 1024 * 1024);
        updateFileList(fileList);
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        updatePreviewImage(file.url || file.preview);
        updatePreviewVisible(true);
    };

    const beforeUpload = (file) => {
        const types = ['image/jpg','image/jpeg','image/bmp','image/png','image/gif'];
        const isLt1M = file.size < 1024 * 1024;
        const isImg = types.includes(file.type);
        if (!isLt1M) Message.error('图片大小不能超过1MB');
        if (!isImg) Message.error('文件格式错误');
        return isImg ? true && isLt1M : Upload.LIST_IGNORE;
    };

    return <div className="ticket-submit-container">
        <PageTypography title='工单'>您可以通过工单系统来询问任何问题，我们的工作人员会第一时间为您解决问题。</PageTypography>
        <CardNav title='创建工单' link='/console/tickets'>
            <Form
                form={form}
                onFinish={(values) => submit(values)}
                layout="vertical"
                colon={false}
                className="form"
            >
                <Form.Item
                    label="工单分类"
                    name="templateIds"
                    required
                    rules={[{ required: true }]}
                >
                    <Cascader
                        style={{ width: '100%' }}
                        getPopupContainer={node => node.parentNode as HTMLElement}
                        options={templates}
                        onChange={(value) => updateTemplateId(value[1] as string)}
                        placeholder="请选择工单分类"
                        suffixIcon={<CaretDownOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    label="问题描述"
                    name="content"
                    required
                    rules={[{ required: true }]}
                >
                    <Input.TextArea placeholder="请输入问题描述" style={{ height: 120, resize: 'none' }} />
                </Form.Item>
                {
                    template?.fields?.map((field) => <Form.Item
                        label={field.field}
                        name={`metas_${field.field}`}
                        required
                        rules={[{ required: true }]}
                    >
                        <Input placeholder={field.description} />
                    </Form.Item>)
                }
                <Form.Item
                    label="电子邮箱"
                >
                    <Input disabled value={email} />
                </Form.Item>
                <Form.Item
                    label="手机号"
                >
                    <Input disabled value={phone} />
                </Form.Item>
                <Form.Item
                    label="抄送邮箱"
                    name="cc"
                    required={false}
                    rules={[
                        { type: 'email', message: '请输入正确的邮箱' },
                    ]}
                >
                    <Input placeholder="请输入需要抄送的邮箱" />
                </Form.Item>
                <p className="upload-tip">支持 .png、.jpg、.jpeg、.gif、.bmp 格式，可上传 5 个文件，单个大小不超过 1 M</p>
                <Upload
                    accept={'image/jpg,image/jpeg,image/bmp,image/png,image/gif'}
                    action="/apis/attachments"
                    onPreview={handlePreview}
                    onChange={handlePicChange}
                    listType="picture"
                    beforeUpload={beforeUpload}
                >
                    <Button className="btn-icon" icon={<UploadIcon />}>上传附件</Button>
                </Upload>
            </Form>
            <div className="form-footer">
                <Button type="primary" onClick={() => form.submit()}>提交工单</Button>
            </div>
        </CardNav>
    </div>
}
