import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import './sign-up-success.container.less';
import { LoginAction } from '../actions/login.action';
import { useMount } from 'react-use';
import { getQuery } from '../../../helpers/query.helper';
import { Link } from 'react-router-dom';
import logo from '../../../asserts/logo_black.png';

export const SignUpSuccessContainer = (props) => {

    const dispatch = useDispatch();
    // const email = decodeURIComponent(props.location.search?.split('?')[1]?.split('=')[1]);
    const email = decodeURIComponent(getQuery(props?.location?.search)?.email);
    const isActivatePage = decodeURIComponent(getQuery(props?.location?.search)?.isActivatePage);

    const [remaining, updateRemaining] = useState(60);

    useEffect(() => {
        switch (remaining) {
            case 0:
                updateRemaining(60);
                break;
            case 60:
                break;
            default:
                setTimeout(() => {
                    updateRemaining((remaining: number) => remaining - 1);
                }, 1000);
                break;
        };
    }, [remaining]);
    useMount(() => {
        sendActivateEmail();
    });

    const sendActivateEmail = () => {
        updateRemaining(59);
        dispatch(LoginAction.sendActivateEmail(email));
    };

    return <div className="sign-up-success-container">
        <div className="header">
            <img src={logo} alt="logo" />
            <p>已有账号？<Link to="/sign-in">立即登录</Link></p>
        </div>
        <div className="success-box">
            <div className={isActivatePage ? 'activate-icon' : 'success-icon'}></div>
            <p className="success-content">{isActivatePage ? '激活账户' : '账户注册成功！'}</p>
            <p className="activate-content">请登录您的注册邮箱，根据激活邮件指引激活该账户 </p>
            <Button
                disabled={remaining !== 60}
                onClick={() => sendActivateEmail()}
                type="primary"
                className="success-btn"
            >
                {remaining === 60 ? '' : `(${remaining})`}重新发送激活邮件
            </Button>
            <Link to="/sign-in" className="btn-gologin-mobile">去登录</Link>
        </div>
    </div>
}