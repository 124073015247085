export function getQuery(queryStr: string): any {
    const query = {};
    if (queryStr) {
        const queryArr = queryStr.split('?')[1].split('&');
        queryArr.map(item => {
            let obj = item.split('=');
            query[obj[0]] = decodeURIComponent(obj[1]);
            return item
        });
    }
    return query;
}

export const filterQuery = (query) => {
    for (const key in query) {
        if (!query[key]) {
            delete query[key];
        }
    }
    return query;
}