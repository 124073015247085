/* eslint-disable react/jsx-no-target-blank */
import { Modal } from 'antd';
import { useState } from 'react';
import logo from '../../asserts/logo_black.svg';
import { SassRule } from '../document/sass-login/sass.rule'
import { CloseModalIcon } from './icons.component';
import './login-layout.component.less';

export const LoginLayout = (props) => {
    const [visible, setVisible] = useState(false);
    return <div className="login-layout">
        <div className="pc-layout">
            <header>
                <a href="https://www.yanrongyun.com" target="_blank"><img src={logo} alt="logo" className="logo" /></a>
                <nav>
                    <a href="https://github.com/yr-tech/yunzhou" target="_blank">GitHub社区</a>
                </nav>
            </header>
            <div className="login-layout-bg">
                <div className="login-layout-container">
                    <div className="login-layout-ad">
                        <p className="ad-aws">比 Amazon EFS 降低<span>75%</span>成本</p>
                        <div className="ad-discount">
                            <p >新用户免费试用<span>30</span>天</p>
                            <span className="ad-voucher" onClick={() => { setVisible(true) }}>活动规则&gt;&gt;</span>
                        </div>
                        <ul className="ad-features">
                            <li>无缝接入您现有的VPC环境</li>
                            <li>无需重新构建应用程序即可轻松迁移应用负载</li>
                            <li>统一数据平台，管理不同云上的共享数据资源</li>
                            <li>零难度，一键获取共享存储资源</li>
                        </ul>
                    </div>
                    <div className="login-box">
                        {props.children}
                    </div>
                </div>
            </div>
            <Modal
                visible={visible}
                getContainer={false}
                onCancel={() => { setVisible(false) }}
                title='新用户免费试用活动规则'
                width={700}
                centered={true}
                closeIcon={<CloseModalIcon />}
                onOk={() => { setVisible(false) }}
                okText='我了解了'
            >
                <SassRule />
            </Modal>
        </div>
        <div className="mobile-layout">
            <header>
                <a href="https://www.yanrongyun.com" target="_blank"><img src={logo} alt="logo" className="logo" /></a>
                <nav>
                    <a href="https://github.com/yr-tech/yunzhou" target="_blank">GitHub社区</a>
                </nav>
            </header>
            {props.children}
        </div>
    </div>
}

