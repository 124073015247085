export const CREATE_INSTANCE_MODAL = 'create_instance_modal';
export const UPGRADE_INSTANCE_MODAL = 'upgrade_instance_modal';
export const UPGRADE_BANDWIDTH_MODAL = 'upgrade_bandwidth_modal';
export const RENEW_INSTANCE_MODAL = 'renew_instance_modal';
export const UNMOUNT_INSTANCE_MODAL = 'unmount_instance_modal'; //文件系统卸载
export const MODIFY_TYPE_MODAL = 'modify_type_modal';
export const MODIFY_CONFIG_MODAL = 'modify_config_modal'; // 配置变更
export const MODIFY_QUICKEN_MODAL = 'modify_quicken_modal'; // 性能加速
export const UPDATE_NAME_MODAL = 'update_name_modal'; // 更改名称
export const MODIFY_CONFIRM_MODAL = 'modify_confirm_modar'; // 确认对话框
