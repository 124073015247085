import { Action } from "../action";
import { PAGINATION } from "../constants/action-types.constants";

export class PaginationAction {
    static update(key: string, page: number, size: number, total: number, length: number) {
        return new Action(PAGINATION).put(key, {
            total: Number(total),
            size: Number(size),
            page: Number(page),
            length: Number(length),
        });
    }
}
