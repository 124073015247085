import { Col, Form, Input, Modal, Row, message as Message, Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State, Modal as ModalInterface } from '../../../interfaces';
import { CloseModalIcon } from '../../components/icons.component';
import { REMOVE_ACCOUNT_MODAL } from '../constants/modal.constants';
import { ModalAction, SessionAction } from '../../../redux';
import { Button } from '../../components/button.component';
import './remove-account.modal.less';
import { AccountAction } from '../actions';     
export const RemoveAccountModal = (props) => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [remaining, updateRemaining] = useState(60);
    const formLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 },
    };
    const { visible } = useSelector<State, ModalInterface>(
        state => state.modals[REMOVE_ACCOUNT_MODAL] ?? {} as any,
    );
    const { phone } = useSelector<State, any>(
        state => state.session ?? {} as any,
    );

    useEffect(() => {
        switch (remaining) {
            case 0:
                updateRemaining(60);
                break;
            case 60:
                break;
            default:
                setTimeout(() => {
                    updateRemaining((remaining: number) => remaining - 1)
                }, 1000);
                break;
        };
    }, [remaining]);

    const submit = (values) => {
        dispatch(AccountAction.removeAccount(values, () => {
            Message.success('注销账户成功');
            ModalAction.close(REMOVE_ACCOUNT_MODAL);
            dispatch(SessionAction.signOut());
            window.location.hash = '/sign-in';
        }));
    };

    return <Modal
        visible={visible}
        className="remove-account-modal"
        footer={null}
        onCancel={() => dispatch(ModalAction.close(REMOVE_ACCOUNT_MODAL))}
        title="注销账户"
        width={640}
        centered={true}
        closeIcon={<CloseModalIcon />}
        destroyOnClose={true}
    >
        <Form
            form={form}
            colon={false}
            labelAlign="left"
            onFinish={(values) => submit(values)}
            {...formLayout}
            className="form"
        >
            <Form.Item
                label="手机号"
            >
                <span style={{ fontSize: 14 }}>{phone}</span>
            </Form.Item>
            <Form.Item
                label="验证码"
                name="code"
                required={false}
                rules={[{ required: true, message: '请输入验证码' }]}
            >
                <Row>
                    <Col span={15}>
                        <Input placeholder="请输入验证码" className="form-input" />
                    </Col>
                    <Col span={8} offset={1}>
                        <Button
                            type="primary"
                            title={remaining === 60 ? '发送验证码' : `${remaining}秒后重新发送`}
                            className={`form-verify ${remaining === 60 ? '' : 'disabled'}`}
                            onClick={() => {
                                updateRemaining(59);
                                dispatch(AccountAction.sendRemoveAccountSms());
                            }}
                            disabled={remaining !== 60}
                        />
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item
                label=" "
            >
                <Alert message="注销账户为不可逆操作，确定注销！" type="error" />
            </Form.Item>
            <Form.Item
                className="modal-buttons"
                wrapperCol={{ span: 24 }}
            >
                <Button
                    title="取消"
                    type="dashed"
                    className="modal-button"
                    onClick={() => dispatch(ModalAction.close(REMOVE_ACCOUNT_MODAL))}
                />
                <Button
                    title="确定"
                    type="primary"
                    className="modal-button"
                    onClick={() => form.submit()}
                />
            </Form.Item>
        </Form>
    </Modal>
}