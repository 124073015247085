import { Dispatch } from 'redux';
import { State } from "../../interfaces";
import { Action } from "../action";
import { TICKET } from "../../modules/ticket/constants/action-type.constants";

export class SocketAction {
    public static refreshTicketReplies(e: any) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            const ticket = state().ticket.ticket;
            if (!ticket || !ticket.replies) {
                return;
            }
            const replies = ticket.replies;
            const reply = e.params ?? {};
            if (window.location.hash !== `#/console/tickets/ticket/${reply?.ticket?.id}`) {
                return;
            }
            replies.push({
                id: reply.id,
                resolverId: reply.resolverId,
                createdAt: reply.createdAt,
                content: reply.content,
            });
            dispatch(new Action(TICKET).put('ticket', { ...ticket }));
            window.scrollTo(0, 99999);
        };
    }
}
