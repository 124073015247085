import { Form, Input, Modal } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State, Modal as ModalInterface } from '../../../interfaces';
import { ModalAction } from '../../../redux';
import { CloseModalIcon } from './../../components/icons.component';
import { AccountAction } from '../actions/account.action';
import { ACTIVATE_VOUCHER_MODAL } from '../constants/modal.constants';
import './activate-voucher.modal.less';

export const ActivateVoucherModal = (props) => {
    const formLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { visible } = useSelector<State, ModalInterface>(
        state => state.modals[ACTIVATE_VOUCHER_MODAL] ?? {} as any,
    );
    const submit = (values) => {
        dispatch(AccountAction.activateVoucher(values.id));
    };
    return <Modal
        title="激活代金券"
        visible={visible}
        onCancel={() => dispatch(ModalAction.close(ACTIVATE_VOUCHER_MODAL))}
        destroyOnClose={true}
        className="activate-voucher-modal"
        onOk={() => form.submit()}
        cancelText={''}
        centered={true}
        closeIcon={<CloseModalIcon />}
    >
        <Form
            {...formLayout}
            form={form}
            onFinish={(values) => submit(values)}
            preserve={false}
            className="form"
            colon={false}
            labelAlign="left"
        >
            <Form.Item
                label="代金券编码"
                name="id"
                required={false}
                rules={[{ required: true, message: '请输入代金券编号' }]}
            >
                <Input />
            </Form.Item>
            {/* <Form.Item wrapperCol={{ span: 24 }} className="button-box">
                <Button
                    title="取消"
                    type="dashed"
                    onClick={() => dispatch(ModalAction.close(ACTIVATE_VOUCHER_MODAL))}
                    className="modal-button"
                />
                <Button
                    title="确定"
                    type="primary"
                    onClick={() => form.submit()}
                    className="modal-button"
                />
            </Form.Item> */}
        </Form>
    </Modal>
}