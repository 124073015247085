import moment from 'moment'
import { useState, useEffect, useRef } from 'react';
import { Table, Button, Space, DatePicker, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PageTypography } from '../../components/page.typography';
import { CardNav } from '../../components/card.navigation';
import { State, Pagination, Fetch } from '../../../interfaces';
import { BillsAction } from '../actions';
import { BillsTypeDosageInfo } from './../enums'
import { GET_DOSAGE_INFO, GET_DOSAGE_INFO_LOAD } from '../constants/fetch.constants';
import { UploadTopIcon } from './../../components/icons.component';
import { DefaultTable } from '../../components/default-table.component';
import { deriveCsv } from './../../../utils'
import { GiBToGB, MbpsToMBs } from './../../../helpers/capacity.helper'
import { DateIcon, AccountMoreIcon } from './../../components/icons.component'
import 'moment/locale/zh-cn';
import './new.dosage.container.less'

const format = 'YYYYMMDD'
const formathms = 'YYYY-MM-DD HH:mm:ss'
export const NewDosageContainer = () => {
    const { RangePicker } = DatePicker;
    const { id, billingCycle, name } = useParams<any>();
    const paramsRef = useRef<any>({
        startTime: moment(billingCycle).format(format),
        endTime: moment(billingCycle).add(1, 'M').subtract(1, 'd').format(format),
        billingItem: null,
    })
    const { email } = useSelector<State, any>(state => state.session);
    const dispatch = useDispatch()
    // 列表状态
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_DOSAGE_INFO] ?? {} as any);
    const { fetching: downLoadLoading } = useSelector<State, Fetch>(state => state.fetch[GET_DOSAGE_INFO_LOAD] ?? {} as any);
    const { pagination } = useSelector<State, { pagination: Pagination }>(
        (state) => ({ pagination: state.pagination.dosage ?? ({} as any) }));
    const dosage_list = useSelector<State, any>(state => state.bills.dosage_list);
    // 开始时间-结束时间
    const [dateTime, setDateTime] = useState<any>([]);
    const [hackValue, setHackValue] = useState<any>(undefined);
    // select下拉
    const [selectVal, setSelectVal] = useState(null);

    const disableTime = (current) => {
        return current && (current < moment(billingCycle) || current >= moment(billingCycle).add(1, 'M'));
    }
    const onChange = (date) => {
        setDateTime(date);
        paramsRef.current = {
            ...paramsRef.current,
            startTime: moment(date[0]).format(format),
            endTime: moment(date[1]).format(format)
        }
        refresh()
    }

    // 分页
    const onPageChange = (page: number, size: number) => {
        refresh(page, size);
    }
    //初始化函数
    const refresh = (page: number = pagination.page, size: number = pagination.size) => {
        dispatch(BillsAction.getDosageInfo(id, { page, size, ...paramsRef.current }))
    }
    useEffect(() => {
        paramsRef.current = {
            ...paramsRef.current,
            billingItem: selectVal,
        }
        refresh(1, 10);
    }, [selectVal])
    // csv 导出
    const getCsv = () => {
        let header = [
            { label: '账期', key: 'billingCycle' },
            { label: '账号', key: 'email' },
            { label: '文件系统名', key: 'name' },
            { label: '明细', key: 'billingItem' },
            { label: '消费时间', key: 'timeinterval' },
            { label: '平均使用量', key: 'usage' },
        ]
        dispatch(BillsAction.getDosageInfoLoad(id, { ...paramsRef.current }, (list: any) => {
            const data = list.map((item) => {
                return {
                    billingCycle: `${moment(item.billingCycle).format('YYYY年MM月')}`,
                    email: email,
                    name: item.instanceName,
                    billingItem: BillsTypeDosageInfo[item.billingItem],
                    timeinterval: `${moment(item.startTime).format(formathms)} ~ ${moment(item.endTime).format(formathms)}`,
                    usage: item.billingItem === "fs.capacity.bandwidth" ? `${MbpsToMBs(item.usage, 'Mbps')}MB/s` : GiBToGB(item.usage, 'GiB'),
                }
            })
            deriveCsv(header, data, '用量详情', 'csv');
        }))
    }
    return (
        <div className="dosage-container">
            <PageTypography title='费用'>您可在此页面查看您的账单明细。</PageTypography>
            {/* 用量详情 */}
            <CardNav title='用量详情' link={-1}>
                <div className='dosage-title'>
                    <Button icon={<UploadTopIcon />} className='load-button' onClick={getCsv} loading={downLoadLoading}>下载用量详情</Button>
                    <Space>
                        <span>时间范围</span>
                        <RangePicker
                            suffixIcon={<DateIcon />}
                            value={hackValue || dateTime}
                            disabledDate={disableTime}
                            onChange={onChange}
                            onOpenChange={(open) => { setHackValue(open ? [] : undefined) }}
                        // format={"MM/DD"}
                        />
                        <Select
                            className="dosage-select"
                            suffixIcon={<AccountMoreIcon />}
                            defaultValue={selectVal}
                            onChange={(val) => { setSelectVal(val) }}
                        >
                            <Select.Option value={null}>显示所有</Select.Option>
                            <Select.Option value={'fs.capacity.size'}>容量型产品</Select.Option>
                            <Select.Option value={'fs.capacity.bandwidth'}>性能加速</Select.Option>
                        </Select>
                    </Space>
                </div>
                <Table
                    dataSource={dosage_list}
                    rowKey='id'
                    className="dosage-table"
                    pagination={{
                        total: pagination.total,
                        defaultPageSize: pagination.size,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50'],
                        current: pagination.page,
                        onChange: onPageChange as any
                    }}
                    loading={fetching}
                    locale={{
                        emptyText: () => <DefaultTable />,
                    }}
                >
                    <Table.Column title="账期" dataIndex="billingCycle" key="billingCycle" />
                    <Table.Column title="账号" render={() => email} />
                    <Table.Column title="文件系统名" dataIndex="instanceName" key="intanceName" />
                    <Table.Column title="明细" dataIndex="billingItem" render={(text) => BillsTypeDosageInfo[text]} />
                    <Table.Column title="消费时间" dataIndex="startTime" render={(text, record: any) => (
                        <span>{moment(text).format(formathms)} ~ {moment(record.endTime).format(formathms)}</span>
                    )} />
                    <Table.Column title="平均使用量" dataIndex="usage" render={(text, record: any) => {
                        return record.billingItem === "fs.capacity.bandwidth" ?
                            <span>{MbpsToMBs(text, 'Mbps')}</span>
                            :
                            <span>{GiBToGB(text, 'GiB')}</span>
                    }} />
                </Table>
            </CardNav>
        </div>
    )
}