import { CopyOutlined, InfoCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import awsStatusImg from '../../../asserts/mount_aws_status.png';
import awsDnsImg from '../../../asserts/mount_aws_dns.png';
import { Modal } from "antd";
import { CloseModalIcon, CopyIcon } from "../../components/icons.component";

export const MountStepAwsCapacityContainer = ({ privateLink, copyContent, instanceId }) => {
    const [tip, updateTip] = useState({ visible: false, title: '', pic: '' });
    return <td>
        <p>1、建立AWS Privatelink 连接</p>
        <p className="content-secondary">
            a)打开
            <a href="https://cn-northwest-1.signin.amazonaws.cn" target="_blank">AWS</a>
            ——相应区域——VPC控制面板——终端节点。
        </p>
        <p className="content-secondary">
            b)点击“创建终端节点”；服务类别选择“其他终端节点服务”；在服务名称处输入“
            <span id="content_private_link">
                {privateLink}
            </span>
            ”
            <a onClick={() => copyContent('private_link')} className="copy-button" title="复制至剪贴板">
                <CopyIcon />
            </a>；点击 “验证”按钮。
        </p>
        <p className="content-secondary">
            c)在VPC输入框中，输入需要挂载文件系统的EC2所在的VPC、子网及可用区；选择文件系统挂载服务器所用安全组；点击“创建终端节点”。
        </p>
        <p className="content-secondary">
            d)在该安全组添加：入站规则 TCP&nbsp;&nbsp;30001-30004；TCP&nbsp;&nbsp;111；TCP&nbsp;&nbsp;2049。
        </p>
        <p>
            <strong>注意：</strong>
            创建终端节点完成之后，终端节点列表中相应的终端节点状态变成“待接受”，平台会在10分钟内接受请求，等终端节点状态变成“可用”之后&nbsp;
            <InfoCircleOutlined onClick={() => updateTip({ visible: true, title: '获取终端节点状态', pic: awsStatusImg })} />
            ，执行下一步操作。
        </p>
        <p style={{ marginTop: 10 }}>2、挂载文件系统</p>
        <p className="content-secondary">
            a)获取DNS: 打开
            <a href="https://cn-northwest-1.signin.amazonaws.cn" target="_blank">AWS</a>
            ——相应区域——VPC控制面板——终端节点——详细信息，获取<i>DNS名称</i>，复制DNS名称&nbsp;
            <InfoCircleOutlined onClick={() => updateTip({ visible: true, title: '获取DNS', pic: awsDnsImg })} />
            。
        </p>
        <p className="content-secondary">b)登录到需要挂载文件系统的虚拟机，挂载文件系统。 </p>
        <code>
            <p>
                #&nbsp;
                (Centos)&nbsp;<span id="content_nfsutils">yum install nfs-utils</span>
                <a onClick={() => copyContent('nfsutils')} className="copy-button" title="复制至剪贴板">
                    <CopyIcon />
                </a>
            </p>
            <p>
                #&nbsp;
                (Ubuntu)&nbsp;<span id="content_nfscommon">apt install nfs-common</span>
                <a onClick={() => copyContent('nfscommon')} className="copy-button" title="复制至剪贴板">
                    <CopyIcon />
                </a>
            </p>
            <p>
                #&nbsp;
                <span id="content_mkdir">mkdir -p /mnt/yrfs</span>
                <a onClick={() => copyContent('mkdir')} className="copy-button" title="复制至剪贴板">
                    <CopyIcon />
                </a>
            </p>
            <p>
                #&nbsp;
                <span id="content_mount">
                    mount -t nfs -o tcp,nolock,noac,nfsvers=3 <i>DNS名称</i>:/mnt/yrfs/{instanceId} /mnt/yrfs
                </span>
                <a onClick={() => copyContent('mount')} className="copy-button" title="复制至剪贴板">
                    <CopyIcon />
                </a>
            </p>
            <p>
                #&nbsp;
                <span id="content_df">df -h</span>
                <a onClick={() => copyContent('df')} className="copy-button" title="复制至剪贴板">
                    <CopyIcon />
                </a>
            </p>
        </code>
        <Modal
            title={tip.title}
            footer={null}
            onCancel={() => updateTip({ visible: false, title: '', pic: '' })}
            visible={tip.visible}
            width={1200}
            closeIcon={<CloseModalIcon />}
            centered={true}
        >
            <img alt={tip.title} style={{ width: '100%', padding: 24 }} src={tip.pic} />
        </Modal>
    </td>;
}
