export const createsWebSql = (_: any, value: any) => {
    const reg = /select|update|delete|truncate|join|union|exec|insert|drop|count|‘|"|;|>|<|%/i;
    if (reg.test(value)) {
        return Promise.reject(new Error('请勿输入非法字符'))
    }
    return Promise.resolve();
}
// 发票抬头 不支持全英文/数字的抬头名称
export const createTitleEnt = (_: any, value: any) => {
    const reg = /(^\d*$)|(^[a-zA-Z]*$)/
    if (value && reg.test(value)) {
        return Promise.reject(_?.message)
    }
    return Promise.resolve();
}
// 统一社会信用代码只能是15、17、18、20位
export const createCodeReg = (_: any, value: any) => {
    const reg = /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/; // 统一社会信用代码
    if (value && reg.test(value)) {
        return Promise.reject(_?.message)
    }
    return Promise.resolve();
}
// 3、开户行名称长度为1-30位
export const createBankeReg = (_: any, value: any) => {
    const reg = /^.{1,30}$/
    if (value === '' || value === null || reg.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject(_?.message)
}
// 基本户开户账号长度为6至32位
export const createAccountReg = (_: any, value: any) => {
    const reg = /^.{6,32}$/
    if (value === '' || value === null || reg.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject(_?.message)
}
// 注册场所地址最少为3个字符
export const createRegisteredAddress = (_: any, value: any) => {
    const reg = /^.{3,}$/
    if (value === '' || value === null || reg.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject(_?.message)
}
// 注册固定电话长度为5到17位
export const createPhoneReg = (_: any, value: any) => {
    const reg = /^.{5,17}$/
    if (value === '' || value === null || reg.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject(_?.message)
}
// 注册固定电话长度为5到17位
export const createAddressse = (_: any, value: any) => {
    const reg = /^.{2,30}$/
    if (value === '' || value === null || reg.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject(_?.message)
}
// 电话或者手机号
export const createTelephone = (_: any, value: any) => {
    const reg = /^0\d{2,3}-?\d{7,8}$/
    const reg1 = /^1[3|4|5|7|8][0-9]\d{8}$/
    if (value === '' || value === null || reg.test(value) || reg1.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject(_?.message)
}