import { MapReducer } from '../../../redux/reducers/map.reducer';
import { LOGIN } from '../constants/action-types.constants';

export class LoginReducer extends MapReducer {
    private static readonly defaults = {
        captcha: '',
        needCaptcha: false,
    }

    constructor() {
        super(LOGIN, LoginReducer.defaults);
    }
}
