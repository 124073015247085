import React, { useEffect } from 'react';
import './doc.less';
import './terms.container.less';

export const InvoiceContainer = (props) => {

    useEffect(() => {
        const plist: any = document.querySelectorAll('.doc-container p');
        for (let i = 0; i < plist.length; i++) {
            const height = parseInt(window.getComputedStyle(plist[i]).height)
            if (height >= 30) {
                plist[i].style.textIndent = '2em'
            } else {
                plist[i].style.textIndent = '0em'
            }
        }
    }, [])
    return <div className="doc-container">
        <h1>开具增值税普通/专用发票申请书</h1>
        <p><br /></p>
        <h2>北京焱融科技有限公司：</h2>
        <p>本人为当前焱融SaaS文件存储账号的合法持有人，受相关公司（即本次申请开具增值税普通/专用发票的申请主体，下称“申请人”）委托，为申请人代为购买焱融SaaS文件存储（<a href="https://console.yanrongyun.com">https://console.yanrongyun.com</a>）上的产品及服务，代其向北京焱融科技有限公司申请相关发票等。</p>
        <p><br /></p>
        <p>本人已充分知晓及了解，根据国家税法及发票管理相关规定，任何单位和个人不得有以下虚开发票行为：</p>
        <div>
            <span>（一）为他人、为自己开具与实际经营业务情况不符的发票；</span>
            <span>（二）让他人为自己开具与实际经营业务情况不符的发票；</span>
            <span>（三）介绍他人开具与实际经营业务情况不符的发票。</span>
        </div>
        <p>本人进一步了解，任何单位和个人不得要求他人为自己开具与实际经营业务情况不符的增值税专用发票，包括但不限于：</p>
        <div>
            <span>（一）在没有货物采购或者没有接受应税劳务的情况下要求他人为自己开具增值税专用发票；</span>
            <span>（二）虽有货物采购或者接受应税劳务但要求他人为自己开具数量或金额与实际情况不符的增值税专用发票。</span>
        </div>
        <p>否则属于“虚开增值税专用发票”。</p>
        <p><br /></p>
        <p>本人确认并承诺:</p>
        <div>
            <span>1、本人接受申请人委托，通过本人焱融SaaS文件存储账号代申请人采购焱融SaaS文件存储网站产品及服务，代购上述产品及服务的费用，均由申请人实际支付；</span>
            <span>2、本人代申请人向北京焱融科技有限公司申请增值税普通/专用发票前，已获得申请人明确承诺，将按国家相关规定申请开具及使用增值税普通/专用发票；</span>
            <span>3、本人所填写的申请人开票信息正确无误，若因开票信息错误等原因造成的相应后果及损失，由本人自行承担，与北京焱融科技有限公司无关。</span>
            <span>4、北京焱融科技有限公司依据本申请出具以申请人为主体的增值税普通/专用发票，并不改变本人为当前焱融SaaS文件存储账号合法持有人的认定，本人仍须对账号下的所有活动（包括但不限于代申请人购买、索取发票等行为）承担相应的法律后果，并配合北京焱融科技有限公司或税务机关的核实调查工作。</span>
            <span>5、本申请书的全部内容均为真实合法，且本人与申请人之间不存在任何违法违规行为，双方之间因上述代为采购行为、代为申请发票行为而引起的任何纠纷，由双方自行解决，与北京焱融科技有限公司无关。</span>
        </div>
        <p><br /></p>
        <p>本人认可:</p>
        <div>
            <span>1、焱融SaaS文件存储系统后台记录的本人在线确认时间，为本申请书的生效时间。</span>
            <span>2、本人在此之前就本账号下发票主体相关事项所提交的其他申请文件，自本申请书生效之时起自动失效。</span>
        </div>
        <p><br /></p>
        <p>本人确认上述内容确系本人真实意思表示，并向北京焱融科技有限公司申请开具以申请人为主体的增值税普通/专用发票。</p>
    </div>
}
