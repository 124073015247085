import { CopyOutlined } from "@ant-design/icons";
import React from "react";
import { CopyIcon } from "../../components/icons.component";

export const MountStepTencentPerformanceContainer = ({ account, cidr, copyContent, vip, clientId, instanceId }) => {
    return <td>
        <p>1、文件系统创建完成后，需要建立对等网络连接</p>
        <p className="content-secondary">
            a)打开<a href="https://cloud.tencent.com" target="_blank">腾讯云</a>
            ——私有网络——相应区域——对等连接。接受平台腾讯云账户（{account}）发送的对等连接申请。对等连接状态由“待接受”，变为“已连接”即可。
        </p>
        <p className="content-secondary">
            b)在相应的子网路由表中，添加一条路由表：
            <span id="content_cidr">{cidr}</span>
            <a onClick={() => copyContent('cidr')} className="copy-button" title="复制至剪贴板">
                <CopyIcon />
            </a>
            ，下一跳类型选择对等连接，下一跳选择已建立的对等连接。
        </p>
        <p className="content-secondary">c)增加安全组入站规则：udp&nbsp;&nbsp;7004 - 7014&nbsp;&nbsp;{cidr}</p>
        <p className="content-primary">2、挂载性能型文件系统</p>
        <p className="content-secondary">a)准备工作</p>
        <p className="content-three-level">i.执行以下命令检查kernel-devel以及kernel-headers包版本和kernel版本的一致性</p>
        <code>
            <p>#&nbsp;uname -r</p>
            <p>#&nbsp;rpm -qa | grep kernel</p>
            <p>#&nbsp;rpm -qa | grep "^gcc"</p>
        </code>
        <p className="content-three-level">ii.若缺少以上包，请您下载并执行以下命令安装软件依赖包</p>
        <code>
            <p>下载gcc，kernel-header，kernel-devel</p>
            <p>#yum install gcc</p>
            <p>#yum install kernel-headers-$(uname -r) kernel-devel-$(uname -r)</p>
        </code>
        <p className="content-secondary">b)挂载文件系统</p>
        <code>
            <p>
                #&nbsp;
                <span id="content_mount">
                    curl -SL http://{vip}/yr-install.sh | bash -s /{`${instanceId} ${clientId}` as string}
                </span>
                <a onClick={() => copyContent('mount')} className="copy-button" title="复制至剪贴板">
                    <CopyIcon />
                </a>
            </p>
            <p>#&nbsp;df -h</p>
        </code>
    </td>;
}
