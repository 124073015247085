import { Button, Table } from 'antd';
import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Fetch, State } from '../../../interfaces';
import { BillsAction } from '../actions';
import { tofixed } from './../../../utils';
import { PageTypography } from '../../components/page.typography';
import { CardNav } from '../../components/card.navigation';
import { GET_BILLS_INVOICE } from '../constants/fetch.constants';
import { DefaultTable } from '../../components/default-table.component';
import './new.invoicelist.container.less';

export const NewInvoiceListContainer = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const paramsRef = useRef({});
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_BILLS_INVOICE] ?? {} as any)
    const invoice_bills = useSelector<State, any>(state => state.bills.invoice_bills ?? [] as any);
    const [priceTotal, setPricetotal] = useState(0)
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        if (selectedRows.length) {
            paramsRef.current = selectedRows.map((item) => item.id);
            console.log(selectedRows)
            setPricetotal(selectedRows.length ? selectedRows.map((sub) => tofixed(sub.amountDue)).reduce((a, b) => a + b) : 0);
        } else {
            paramsRef.current = [];
            setPricetotal(0);
        }
        setSelectedRowKeys(selectedRowKeys)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    useEffect(() => {
        dispatch(BillsAction.getInvoicePayBills(false, 'cleared'));
    }, []);
    return (
        <div className="invoicelist-container">
            <PageTypography title='发票'>您可在此页面申请和管理发票信息。</PageTypography>
            <CardNav title='申请发票' link='/console/account/invoice'>
                <Table
                    dataSource={invoice_bills}
                    rowSelection={rowSelection}
                    pagination={false}
                    rowKey={(record) => record.id}
                    loading={fetching}
                    locale={{
                        emptyText: () => <DefaultTable text="暂无可开发票账单" />
                    }}
                >
                    <Table.Column title='账单' dataIndex='billingCycle'></Table.Column>
                    <Table.Column title='开票主体' render={() => '北京焱融科技有限公司'}></Table.Column>
                    <Table.Column title='实付金额' dataIndex='originalAmount' render={(text, record: any) => tofixed(parseFloat(text) - parseFloat(record.roundAmount))}></Table.Column>
                    <Table.Column title='可开票金额' dataIndex='amountDue' render={(text) => tofixed(text)}></Table.Column>
                    <Table.Column title='账单支付时间' dataIndex='paidAt' render={(text) => moment(text).format('YYYY-MM-DD')}></Table.Column>
                </Table>
                <div className="invoicelist-footer">
                    <span>已选{selectedRowKeys.length}个账单，共￥{priceTotal}元</span>
                    <Button type="primary" disabled={selectedRowKeys.length <= 0} onClick={() => { history.push({ pathname: `/console/account/invoice/two/${JSON.stringify(paramsRef.current)}`, state: paramsRef.current }) }}>下一步</Button>
                </div>
            </CardNav>
        </div >
    )
}