export const StorageProduct = () => {
    return (
        <div>
            <h2>产品介绍</h2>
            <p>焱融SaaS文件存储是一个构建在云中的通用文件存储平台，可以帮助用户无缝接入公有、私有、混合云环境。焱融SaaS文件存储是完全构建在公有云基础架构上的，平台所有组件，包括客户端及网络均在公共云基础架构中运行，是专为云端设计的共享文件系统，用户通过注册，就可以在焱融SaaS文件存储平台上，为不同公有云上运行的业务创建自己的共享文件存储空间，通过简单几步，就可以将这个存储空间共享至自己独立的租户和VPC内，应用程序可以使用NFS或POSIX私有协议客户端（即将推出）访问这些数据。</p>
            <h2>产品优势</h2>
            <p>更灵活：焱融SaaS文件存储服务提供无与伦比的灵活性，无论容量还是性能都支持按量付费。</p>
            <p>更简单：没有繁琐的认证过程，使用邮箱和手机号注册即可享受焱融SaaS文件存储服务。</p>
            <p>更高效：无论什么样的业务环境都能轻松迁移，简化多云环境管理。</p>
            <p>更可靠：焱融科技拥有多项安全认证，为用户提供可靠的服务。</p>
            <h2>应用场景：</h2>
            <b>机器学习</b>
            <p>焱融SaaS文件存储完全兼容 POSIX 接口，支持机器学习、深度学习和并行计算的需要，在 TensorFlow、PyTorch、MXNet 等框架中无门槛使用。焱融SaaS文件存储独有的性能加速可支撑机器学习的高性能需求，用户在训练时开启性能加速，训练结束后关闭性能加速，节约大量成本。</p>
            <b>容器持久化存储</b>
            <p>容器在企业 DevOps 持续交付流程、微服务架构中起到越来越重要的作用，容器存储也逐渐被企业重视。容器的多POD通过焱融SaaS文件存储服务共享持久化数据，在POD故障时可以进行故障切换，实现容器持久化存储。同时焱融SaaS文件存储服务的性能加速服务也能满足对性能需求高的容器应用。</p>
            <b>企业核心数据库</b>
            <p>企业核心数据库承载了企业的关键数据，需要快速响应业务的高并发请求，对数据访问性能、安全性和可靠性都有极高的要求。很多企业数据库系统随着数据量和业务量的快速增长，存储系统无法提供足够的性能支撑，数据查询、读写的效率越来越低，成为制约整个业务系统性能的关键瓶颈。焱融SaaS文件存储服务可为企业核心数据库提供高性能、无限容量的存储服务，有效提升企业核心数据库的运行效率。</p>
            <b>泛娱乐和媒体应用</b>
            <p>在音视频后期制作、广播媒体处理和渲染等场景中，一个典型的特点是待处理文件较大，需要多台服务器形成高性能计算集群共同处理。焱融SaaS文件存储服务平台可提供高性能的文件存储服务，支撑媒体行业的高清编辑需求，大幅提高工作效率。</p>
            <b>日常备份</b>
            <p>随着业务快速增长，每天系统产生的log会不断增长，其中包含着大量有价值信息。不管是因为政策要求，还需要对日志进行分析，企业每天都有大量的日志需要进行备份。将数据保存在本地磁盘上，快速增长的数据可能会打爆本地硬盘造成系统故障，需要将这些log方便、低成本并集中化的保存在存储上。焱融SaaS文件存储服务提供高可靠、低价格、无限扩容的文件存储服务，满足用户日常备份需求。</p>
            <b>大数据分析</b>
            <p>传统的大数据平台采用hadoop方案，计算采用MR、Spark等，而存储使用HDFS方案，部署架构上采用计算存储融合方案。而随着计算规模、存储规模的不成比例增长，越来越多的企业采用存算分离的部署架构，而后端的存储方案也不局限于HDFS，包括NAS、对象存储等，大数据分析往往对数据吞吐能力有着极高的要求，焱融SaaS文件存储具备高性能、高可用、弹性伸缩、全托管的特点，为大数据平台提供极高的吞吐能力的同时，可以有效降低大数据场景下的数据存储成本。</p>
        </div>
    )
}