import { MapReducer } from '../../../redux/reducers/map.reducer';
import { CAPTCHA }from '../constants/action-types.constants';

export class CaptchaReducer extends MapReducer {
    private static readonly defaults = {
    }

    constructor() {
        super(CAPTCHA, CaptchaReducer.defaults);
    }
}
