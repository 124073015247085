import _ from 'lodash';
import { Dispatch } from 'redux';
import { State } from '../../../interfaces';
import { Action, Client, ModalAction, PaginationAction } from '../../../redux';
import { FEE, INSTANCE } from '../constants/action-types.constants';
import {
    GET_INSTANCES,
    GET_PURCHASE_PRICE,
    GET_UPGRADE_PRICE,
    GET_RENEW_PRICE,
    CREATE_PURCHASE_ORDER,
    CREATE_UPGRADE_ORDER,
    CREATE_RENEW_ORDER,
    GET_INSTANCE,
    UNMOUNT_INSTANCE, // 文件系统卸载
    GET_MONITOR_DATA,
    GET_MODIFY_PRICE,
    CREATE_MODIFY_ORDER,
    MODIFY_NETWORK_CONFIG, //配置变更
    MODIFY_NETWORK_QUICKEN, //性能加速
    MODIFY_CONFIRM_LOADING, // 对话框
    UPDATE_INSTANCE_NAME, //名称变更
} from '../constants/fetch.constants';
import {
    MODIFY_CONFIG_MODAL, //配置变更
    MODIFY_QUICKEN_MODAL, //性能加速
    MODIFY_CONFIRM_MODAL, // 对话框
    UNMOUNT_INSTANCE_MODAL, // 文件系统卸载
    UPDATE_NAME_MODAL, //名称变更
} from "../constants/modal.constants";
import { MonitorItems, amountSize } from '../enums';

export class InstanceAction {

    private static client = new Client();
    // 文件系统列表
    public static getAccountInstances(params: { page: number, size: number, sorterKey?: string, sorterOrder?: string }) {
        return async (dispatch: Dispatch<any>, state: State) => {
            const { list, total } = await this.client.get<any>('/apis/instances', {
                fetch: GET_INSTANCES,
                params,
                dispatch,
            });
            dispatch(new Action(INSTANCE).put('instances', list));
            dispatch(PaginationAction.update('instance', params.page, params.size, total, list.length));
        }
    }
    // 配置变更调用
    public static getInstances(page?: number, size?: number) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            const pagination = state().pagination['instance'];
            page = page ?? pagination.page;
            size = size ?? pagination.size;
            const { list, total } = await this.client.get<any>('/apis/instances', {
                fetch: GET_INSTANCES,
                params: { page, size },
                dispatch,
            });
            dispatch(new Action(INSTANCE).put('instances', list));
            dispatch(PaginationAction.update('instance', page, size, total, list.length));
        };
    }
    // 是否开启性能加速
    public static setPerformanceAtion(record: Record<string, any>) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            const { checked, index, instanceId } = record;
            const list = [...state().instance.instances];
            await this.client.post<any, any>('/apis/upgrade-instance-orders', {
                instanceId,
                billingItem: "fs.capacity.bandwidth",
                amount: checked ? amountSize.open : amountSize.close  // 8000:400
            }, {
                fetch: MODIFY_NETWORK_QUICKEN,
                dispatch,
            })
            // let amount = list[index].items[0].amount;
            // if (amount) {
            list[index].items[0].amount = checked ? amountSize.open : amountSize.close; // 开启加速:关闭加速
            dispatch(ModalAction.close(checked ? MODIFY_QUICKEN_MODAL : MODIFY_CONFIRM_MODAL)); // 开启加速modal:关闭加速modal
            // }
            dispatch(new Action(INSTANCE).put('instances', list));
        }
    }
    // 文件系统 退订
    public static unInstance(instanceId: string) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            await this.client.post(`/apis/instances/${instanceId}/actions/unsubscribe`, {}, { dispatch, fetch: MODIFY_CONFIRM_LOADING });
            const { page, size } = state().pagination.instance;
            dispatch(this.getInstances(page, size));
            dispatch(ModalAction.close(MODIFY_CONFIRM_MODAL));
        };
    }
    // 文件系统 退订->删除
    public static deleteInstance(instanceId: string) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            await this.client.delete(`/apis/instances/${instanceId}`, { dispatch, fetch: MODIFY_CONFIRM_LOADING });
            const instances = state().instance.instances ?? [];
            const newInstances = instances.filter(ins => ins.id !== instanceId);
            dispatch(new Action(INSTANCE).put('instances', [...newInstances]));
            dispatch(ModalAction.close(MODIFY_CONFIRM_MODAL));
        };
    }

    public static getInstance(instanceId: string) {
        return async (dispatch: Dispatch<any>, state: State) => {
            const { instance } = await this.client.get<any>(`/apis/instances/${instanceId}`, {
                dispatch,
                fetch: GET_INSTANCE,
            });
            if (!instance?.cluster?.cidr) {
                const cluster = await this.client.get(`/apis/clusters/${instance.clusterId}/cidr`) ?? {} as any;
                instance.cluster = { ...instance.cluster, cidr: cluster.cidr };
            }
            dispatch(new Action(INSTANCE).put('instance', instance));
        };
    }

    public static clearInstance() {
        return async (dispatch: Dispatch<any>, state: State) => {
            dispatch(new Action(INSTANCE).delete('instance', {}));
        }
    }

    public static updateInstanceName(instanceId: string, name: string) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            await this.client.patch(`/apis/instances/${instanceId}`, { name }, {
                dispatch,
                fetch: UPDATE_INSTANCE_NAME
            });
            const instances = state().instance.instances;
            instances.forEach(instance => {
                if (instance.id === instanceId) {
                    instance.name = name;
                }
            });
            dispatch(new Action(INSTANCE).put('instances', [...instances]));
            dispatch(ModalAction.close(UPDATE_NAME_MODAL));
        };
    }

    public static getPurchase(values) {
        return async (dispatch: Dispatch<any>, state: State) => {
            try {
                const { fee } = await this.client.post<any, any>('/apis/describe-fee', {
                    ...values,
                }, {
                    dispatch,
                    fetch: GET_PURCHASE_PRICE,
                });
                dispatch(new Action(FEE).put('purchase', fee));
            } catch (e) {
                dispatch(new Action(FEE).put('purchase', {}));
            }
        };
    }

    public static getUpgrade(values) {
        return async (dispatch: Dispatch<any>, state: State) => {
            const { fee } = await this.client.post<any, any>('/apis/describe-fee', {
                ...values,
            }, {
                fetch: GET_UPGRADE_PRICE,
                dispatch,
            });
            dispatch(new Action(FEE).put('upgrade', fee));
        };
    }

    public static clearUpgradeFee() {
        return new Action(FEE).put('upgrade', {});
    }

    public static getRenewPrice(values) {
        return async (dispatch: Dispatch<any>, state: State) => {
            const { fee } = await this.client.post<any, any>('/apis/describe-fee', {
                ...values,
            }, {
                fetch: GET_RENEW_PRICE,
                dispatch,
            });
            dispatch(new Action(FEE).put('renew', fee));
        };
    }

    public static getModifyPrice(values) {
        return async (dispatch: Dispatch<any>, state: State) => {
            const { fee } = await this.client.post<any, any>('/apis/describe-fee', {
                ...values,
            }, {
                fetch: GET_MODIFY_PRICE,
                dispatch,
            });
            dispatch(new Action(FEE).put('modify', fee));
        }
    }

    public static clearModifyPrice() {
        return new Action(FEE).put('modify', {});
    }
    // 创建文件系统
    public static createPurchase(values, callback) {
        return async (dispatch: Dispatch<any>, state: State) => {
            const order = await this.client.post<any, any>('/apis/purchase-instance-orders', {
                ...values,
            }, {
                dispatch,
                fetch: CREATE_PURCHASE_ORDER,
            });
            callback(order.id);
        };
    }
    // 文件系统卸载
    public static removeInstance(instanceId: string) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            await this.client.delete(`/apis/instances/${instanceId}`, { dispatch, fetch: UNMOUNT_INSTANCE });
            const instances = state().instance.instances ?? [];
            const newInstances = instances.filter(ins => ins.id !== instanceId);
            dispatch(new Action(INSTANCE).put('instances', [...newInstances]));
            dispatch(ModalAction.close(UNMOUNT_INSTANCE_MODAL));
        };
    }
    public static createUpgradeOrder(values, callback) {
        return async (dispatch: Dispatch<any>, state: State) => {
            const order = await this.client.post<any, any>('/apis/upgrade-orders', {
                ...values,
            }, {
                dispatch,
                fetch: CREATE_UPGRADE_ORDER,
            });
            callback(order.id);
        };
    }

    public static createRenewOrder(values, callback) {
        return async (dispatch: Dispatch<any>, state: State) => {
            const order = await this.client.post<any, any>('/apis/renew-orders', {
                ...values,
            }, {
                dispatch,
                fetch: CREATE_RENEW_ORDER,
            });
            callback(order.id);
        }
    }

    public static createModify(values, callback) {
        return async (dispatch: Dispatch<any>, state: State) => {
            const order = await this.client.post<any, any>('/apis/modify-orders', {
                ...values,
            }, {
                dispatch,
                fetch: CREATE_MODIFY_ORDER,
            });
            callback(order.id);
        }
    }

    public static validIP(regionId: string, ip: string, awsAccountId: string, awsVpcId: string, fn?: (message?: string) => void) {
        return async (dispatch: Dispatch<any>, state: State) => {
            try {
                await this.client.post(
                    `/apis/regions/${regionId}/actions/ip-validation`,
                    { ip, awsAccountId, awsVpcId },
                    { showMessage: false },
                );
                if (fn) {
                    fn();
                }
            } catch (e: any) {
                if (fn) {
                    fn(e?.response?.data?.message ?? e?.message);
                }
            }

        };
    }

    public static getProducts(regionId: string, billingMode: string) {
        return async (dispatch: Dispatch<any>, state: State) => {
            const { products } = await this.client.get<any>(`/apis/regions/${regionId}/products`, {
                params: { billingMode },
            });
            dispatch(new Action(INSTANCE).put('products', products));
        }
    }

    public static getRegions() {
        return async (dispatch: Dispatch<any>, state: State) => {
            const { regions } = await this.client.get<any>('/apis/regions');
            const platformsData = [] as any;
            const regionsData = {} as any;
            regions.map((item) => {
                if (_.findIndex(platformsData, { key: item.platform }) === -1) {
                    platformsData.push({ name: item.platformName, key: item.platform });
                }
                if (regionsData.hasOwnProperty(item.platform)) {
                    regionsData[item.platform].push({ name: item.name, id: item.id });
                } else {
                    regionsData[item.platform] = [];
                    regionsData[item.platform].push({ name: item.name, id: item.id });
                }
                return item
            });
            dispatch(new Action(INSTANCE).put('region', { platformsData, regionsData }));
        };
    }

    public static getMonitorData(instanceId: string, type: string, before: number) {

        return async (dispatch: Dispatch<any>, state: any) => {
            let { histories } = await this.client.get<any>(`/apis/instances/${instanceId}/monitors/${type}/histories`, {
                params: { before },
                fetch: `${GET_MONITOR_DATA}_${type}`,
                dispatch,
            });
            const data = [] as any[];

            histories.filter(history => MonitorItems[history.itemKey])
                .map(history => {
                    history.data.map(item => {
                        data.push({
                            key: MonitorItems[history.itemKey],
                            timestamp: item.clock * 1000,
                            value: Number(item.value)
                        });
                        return item
                    });
                    return history
                });

            data.sort((a, b) => a.timestamp - b.timestamp).sort((a, b) => a.key.localeCompare(b.key));

            const { monitor } = state().instance;
            dispatch(new Action(INSTANCE).put('monitor', { ...monitor, [type]: data }));
        }
    }
    /*配置变更*/
    public static ModifyNetworkConfig(instanceId: string, params: any) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/apis/instances/${instanceId}/networks`, {
                ...params
            }, {
                fetch: MODIFY_NETWORK_CONFIG,
                dispatch,
            });
            const { page, size } = state().pagination.instance;
            dispatch(ModalAction.close(MODIFY_CONFIG_MODAL));
            dispatch(this.getInstances(page, size));
        }
    }
    /* 性能加速 */
    // public static ModifyNatureQuicken(instanceId: string) {
    //     return async (dispatch: Dispatch<any>, state: any) => {
    //         // await this.client.put(`/apis/instances/${instanceId}/networks`, {}, {
    //         //     fetch: MODIFY_NETWORK_QUICKEN,
    //         //     dispatch,
    //         // });
    //         console.log('性能加速成功-----》');
    //         const { page, size } = state().pagination.instance;
    //         dispatch(ModalAction.close(MODIFY_QUICKEN_MODAL));
    //         dispatch(this.getInstances(page, size));
    //     }
    // }
}
