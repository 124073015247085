import { useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Radio, Row, Select, message as Message } from 'antd';
import { PageTypography } from './../../components/page.typography';
import { CardNav } from './../../components/card.navigation'
import { useDispatch, useSelector } from 'react-redux';
import { BillingModeType, ProductType } from '../enums';
import { Fetch, State } from '../../../interfaces';
import moment from 'moment';
import './renew-instance.container.less';
import { useMount } from 'react-use';
import { InstanceAction } from '../actions/instance.action';
import { Link } from 'react-router-dom';
import { FetchAction } from '../../../redux';
import { CREATE_RENEW_ORDER, GET_RENEW_PRICE } from '../constants/fetch.constants';

let timer;

const sizeItems = ['fs.capacity.size', 'fs.performance.size'];

export const RenewInstanceContainer = (props) => {
    const instanceId = props?.match?.params?.instanceId;
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [moreTime, setMoreTime] = useState<string>('更多');
    const [isAgree, updateIsAgree] = useState(false);

    const renew = useSelector<any, any>(
        state => state.fee.renew ?? {} as any,
    );
    const instance = useSelector<State, any>(
        state => state.instance.instance ?? {} as any,
    );
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[CREATE_RENEW_ORDER] ?? {});
    const { fetching: feeFetching } = useSelector<State, Fetch>(
        state => state.fetch[GET_RENEW_PRICE] ?? {} as any,
    );

    useMount(() => {
        dispatch(InstanceAction.getInstance(instanceId));
        getRenewPrice();
    });

    const instanceSizeItem = instance?.items?.filter(item => sizeItems.includes(item.type))[0];

    const getRenewPrice = () => {
        clearTimeout(timer);
        dispatch(FetchAction.fetching(GET_RENEW_PRICE))
        timer = setTimeout(() => {
            const { period } = form.getFieldsValue();
            const params = {
                orderType: 'renew',
                instanceId: instanceId,
                period: period,
            };
            dispatch(InstanceAction.getRenewPrice(params));
        }, 100);
    };

    const submit = () => {
        const { period } = form.getFieldsValue();
        const params = {
            orderType: 'renew',
            instanceId: instance.id,
            period: period,
        };
        dispatch(InstanceAction.createRenewOrder(params, (orderId) => {
            Message.success('创建订单成功');
            pushState(`/console/account/payment/${orderId}`);
        }));
    };
    const pushState = (url: string) => window.location.hash = url;
    const calculatePrice = () => {
        if (feeFetching) {
            return '加载中';
        }
        return <p>
            <span>配置金额：¥</span>
            <span className="amount">{renew?.originalAmount ?? '--'}</span>
        </p>
    };

    return <div className="renew-instance-container">
        <PageTypography title='文件系统'>焱融SaaS文件存储平台，是构建在公有云基础架构上的共享NAS数据服务平台，为用户提供更快、更经济、更灵活的数据共享服务。用户可选择POSIX文件接口或者标准NFS共享接口访问数据，满足不同应用场景下多样化的存储需求。</PageTypography>
        <CardNav title='续费' link='/console/instances'>
            <Form
                className="form"
                form={form}
                layout="vertical"
                colon={false}
                onValuesChange={() => getRenewPrice()}
                onFinish={() => submit()}
            >
                <Form.Item label="付费模式">{BillingModeType[instance?.billingMode]}</Form.Item>
                <Form.Item label="文件系统名">
                    <Input disabled value={instance?.name} />
                </Form.Item>
                <Form.Item label="存储类型">
                    <Input disabled value={ProductType[instance?.type]} />
                </Form.Item>
                <Form.Item label="初始存储量">{instanceSizeItem?.amount} {instanceSizeItem?.unit}</Form.Item>
                <Form.Item label="到期时间">{moment(instance?.expireTime).format('YYYY-MM-DD')}</Form.Item>
                <Row>
                    <Col span={19}>
                        <Form.Item
                            label="时长"
                            name="period"
                            required
                            initialValue={1}
                        >
                            <Radio.Group style={{ width: '100%', textAlign: 'center' }} onChange={() => setMoreTime('更多')}>
                                <Radio.Button style={{ width: '20%' }} value={1}>
                                    <span style={{ fontSize: 14 }}>1个月</span>
                                </Radio.Button>
                                <Radio.Button style={{ width: '20%' }} value={2}>
                                    <span style={{ fontSize: 14 }}>2个月</span>
                                </Radio.Button>
                                <Radio.Button style={{ width: '20%' }} value={3}>
                                    <span style={{ fontSize: 14 }}>3个月</span>
                                </Radio.Button>
                                <Radio.Button style={{ width: '20%' }} value={6}>
                                    <span style={{ fontSize: 14 }}>半年</span>
                                </Radio.Button>
                                <Radio.Button style={{ width: '20%' }} value={12}>
                                    <span style={{ fontSize: 14 }}>1年</span>
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label=" ">
                            <Select className="time-select" value={moreTime} onChange={(value) => {
                                setMoreTime(`${value}个月`);
                                form.setFieldsValue({ period: value });
                                getRenewPrice();
                            }}>
                                <Select.Option value={4}><span style={{ fontSize: 12 }}>4个月</span></Select.Option>
                                <Select.Option value={5}><span style={{ fontSize: 12 }}>5个月</span></Select.Option>
                                <Select.Option value={7}><span style={{ fontSize: 12 }}>7个月</span></Select.Option>
                                <Select.Option value={8}><span style={{ fontSize: 12 }}>8个月</span></Select.Option>
                                <Select.Option value={9}><span style={{ fontSize: 12 }}>9个月</span></Select.Option>
                                <Select.Option value={10}><span style={{ fontSize: 12 }}>10个月</span></Select.Option>
                                <Select.Option value={11}><span style={{ fontSize: 12 }}>11个月</span></Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Checkbox onChange={(e) => updateIsAgree(e.target.checked)}>
                    同意<Link to="/console/documents/terms" target="_blank">用户协议</Link>
                </Checkbox>
            </Form>
            <div className="form-footer">
                {calculatePrice()}
                <Button
                    loading={fetching}
                    type="primary"
                    disabled={!isAgree}
                    onClick={() => form.submit()}
                    className="btn-submit">
                    提交订单
                </Button>
            </div>
        </CardNav>
    </div>
}
