import { Card, Button } from 'antd'
import { LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'
import React from 'react';

interface IProps {
    title: string | any;   // 标题
    link?: string | number;   // 跳转地址
    children?: any;
    className?: string;
    style?:React.CSSProperties
}
export const CardNav = (props: IProps): any => {
    const { title, link, children, className, style} = props;
    const history = useHistory();
    const pushState = () => {
        const type = Object.prototype.toString.call(link);
        if(type === '[object Number]'){
            history.go(link as number)
        }else{
            history.push(link as string)
        }
    }
    return (
        <Card className={`card-navigation ${className ?? ''}`}  style={style ?? {}}
            title={<span className="card-header">
                {link && <Button icon={<LeftOutlined />} type="link" className="card-back" onClick={pushState} />}
                <span className={`card-title`}>{title}</span>
            </span>}
        >
            {children}
        </Card>
    )
}