import moment from 'moment';
import { Radio, Checkbox, Button, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom';
import { State, Fetch, Modal as ModalInterface } from '../../../interfaces'
import React, { useState, useRef, useEffect } from 'react'
import { BillStatus, BillsTypeInfo } from './../enums';
import { PageTypography } from './../../components/page.typography';
import { CardNav } from '../../components/card.navigation';
import { PaymentSuccessModal } from './../modals/payment-success.modal';
import { PaymentTypeModal } from './../modals/payment-type.modal';
import { ModalAction } from '../../../redux';
import { tofixed } from './../../../utils';
import { GET_BILLS_INFO, GET_VOUCHERS, SET_BILLS_VOUCHER } from './../constants/fetch.constants';
import { PAYMENT_TYPE_MODAL, PAYMENT_SUCC_MODAL } from './../constants/modal.constants'; // 付款弹窗
import { BillsAction, AccountAction } from './../actions'
import { GiBToGB, MbpsToMBs } from './../../../helpers/capacity.helper'
import './new.payment.container.less';
import './new.order.title.less';
import Decimal from 'decimal.js';

export const NewPaymentContainer = () => {
    const { id } = useParams<any>();
    const dispatch = useDispatch();
    const refPayBtn = useRef<any>(null);
    const { email } = useSelector<State, any>(state => state.session);
    const { visible: payLoading } = useSelector<State, ModalInterface>(
        state => state.modals[PAYMENT_TYPE_MODAL] ?? {} as any,
    );
    const { visible: succLoading } = useSelector<State, ModalInterface>(
        state => state.modals[PAYMENT_SUCC_MODAL] ?? {} as any,
    );
    const { fetching: submitLoading } = useSelector<State, Fetch>(state => state.fetch[SET_BILLS_VOUCHER] ?? {});
    const { fetching: voucherLoading } = useSelector<State, Fetch>(state => state.fetch[GET_VOUCHERS] ?? {});
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_BILLS_INFO] ?? {} as any);
    const vouchers = useSelector<State, any>(state => state.account.vouchers ?? [] as any); // 发票数据
    const payment_bills_info = useSelector<State, any>(state => state.bills.payment_bills_info); // 账单数据
    const [checkedValues, setCheckedValues] = useState<string[]>([]); // 代金劵选择
    const [amountDue, setAmountDue] = useState(0); // 实付款金额
    const [voucherPrice, setVoucherPrice] = useState(0) // 代金劵金额
    const [payType, setPayType] = useState('wechat_pay'); // 微信支付/对公转账
    const [isFixed, setIsFixed] = useState(false) // 是否吸底
    // 谁在付款btn的吸底效果
    const fixedPay = () => {
        const clientH = document.documentElement.clientHeight; //网页可见区域高
        const clientHandTop = document.documentElement.scrollTop + clientH; // 网页可见区域高 + 滚动条滑动高度
        const bayTop = getElementTop(refPayBtn.current) + refPayBtn.current.offsetHeight; // 容器距离底部+自身高度
        setIsFixed(bayTop > clientHandTop)
        // console.log(getElementTop(refPayBtn.current,'offsetLeft'))
    }
    // 累加元素距顶高度
    const getElementTop = (el: any, type = 'offsetTop') => {
        let actualTop = el[type]
        let current = el.offsetParent
        while (current !== null) {
            actualTop += current[type]
            current = current.offsetParent
        }
        return actualTop
    }
    const onChange = (values: any[]) => {
        const voucherPrice = vouchers.filter((item) => values.includes(item.id))
            .map((voucher) => parseFloat(voucher.balance));
        const price = voucherPrice.length ? voucherPrice.reduce((prev, cur) => prev + cur) : 0
        setVoucherPrice(price)
        setCheckedValues(values);
    }
    const openModaltype = (amount) => {
        if (amount <= 0) {
            //已支付 && 代金券抵扣后金额为0  -》跳转支付成功
            dispatch(ModalAction.open(PAYMENT_SUCC_MODAL, { payType, id }));
        } else {
            //  未支付 && 微信支付 || 对公转账
            dispatch(ModalAction.open(PAYMENT_TYPE_MODAL, { payType, amount, id }));
        }
    }
    // 使用代金劵
    const setVoucherBtn = () => {
        const { id, isVoucherUsed, amountDue } = payment_bills_info
        if (!checkedValues.length || isVoucherUsed) { // 没有使用代金券 ｜｜ 已经使用过代金券
            // 付款二维码接口
            openModaltype(amountDue);
        } else {
            // 代金劵处理
            dispatch(BillsAction.setVoucher(id, { voucherIds: checkedValues }, (amount) => {
                openModaltype(amount);
            }))
        }
    }
    useEffect(() => {
        document.addEventListener('scroll', fixedPay);
        return () => {
            document.removeEventListener('scroll', fixedPay);
        }
    }, [fixedPay]);
    // 监听支付方式切换
    useEffect(() => {
        fixedPay();
    }, [payType]);
    //初始化函数
    const refresh = () => {
        dispatch(BillsAction.getPayBillsInfo(id));
        dispatch(AccountAction.getVouchers(1, 999999));
    }
    useEffect(() => {
        refresh()
    }, []);
    // 获取金额
    useEffect(() => {
        if (Object.getOwnPropertyNames(payment_bills_info).length) {
            setVoucherPrice(0); // 代金券清零
            setCheckedValues([]);
            setAmountDue(parseFloat(payment_bills_info.amountDue))
        }
    }, [payment_bills_info])
    return (
        <div className="pay-container">
            <PageTypography title='付款'>您可在此页面发起支付。请及时支付您的欠款</PageTypography>
            {/* 账单管理 */}
            <CardNav title={<b>账单管理</b>}>
                {Object.getOwnPropertyNames(payment_bills_info).length ?
                    <Spin spinning={fetching}>
                        <div className="info-title pay-title">
                            {/* <div className="info-title-item">
                                <span>订单号：</span>
                                <b>20220123003</b>
                            </div> */}
                            <div className="info-title-item">
                                <span className="width98">账期：</span>
                                <b>{payment_bills_info.billingCycle}</b>
                            </div>
                            <div className="info-title-item">
                                <span>账号：</span>
                                <b>{email}</b>
                            </div>
                            <div className="info-title-item">
                                <span>出账日期：</span>
                                <b>{moment(payment_bills_info.createdAt).format('YYYY-MM-DD')}</b>
                            </div>
                            <div className="info-title-item">
                                <span className="width98">最晚还款日期：</span>
                                <b>{moment(payment_bills_info.billingCycle).add(1, 'M').add(14, 'd').format('YYYY-MM-DD')}</b>
                            </div>
                            <div className="info-title-item">
                                <span>状态：</span>
                                <b>{BillStatus[payment_bills_info.status]}</b>
                            </div>
                        </div>
                        {payment_bills_info?.details?.map((item) => (
                            <React.Fragment key={item.instanceId}>
                                {/* 容量形 */}
                                <div className="info-title box pay-box-title" key={item.size.id}>
                                    <div className="info-title-item">
                                        <span>产品：</span>
                                        <b>{BillsTypeInfo[item.size.billingItem]}</b>
                                    </div>
                                    <div className="info-title-item">
                                        <span>产品单价：</span>
                                        <b>¥{GiBToGB(item.size.price, '元/GiB/小时', '月')}</b>
                                    </div>
                                    <div className="info-title-item">
                                        <span>平均使用量：</span>
                                        <b>{GiBToGB(item.size.usage, 'GiB')}</b>
                                    </div>
                                    <div className="info-title-item">
                                        <span>官网价：</span>
                                        <b>{tofixed(parseFloat(item.size.originalAmount) - parseFloat(item.size.roundAmount))}元</b>
                                    </div>
                                </div>
                                {/* 性能加速 */}
                                {(parseFloat(item.bandwidth.originalAmount) - parseFloat(item.bandwidth.roundAmount)) > 0 ?
                                    <div className="info-title box pay-box-title" key={item.bandwidth.id}>
                                        <div className="info-title-item">
                                            <span>产品：</span>
                                            <b>{BillsTypeInfo[item.bandwidth.billingItem]}</b>
                                        </div>
                                        <div className="info-title-item">
                                            <span>产品单价：</span>
                                            <b>¥{`${MbpsToMBs(item.bandwidth.price, '元/Mbps/小时')}`}</b>
                                        </div>
                                        <div className="info-title-item">
                                            <span>平均使用量：</span>
                                            <b>{MbpsToMBs(item.bandwidth.usage, 'Mbps')}</b>
                                        </div>
                                        <div className="info-title-item">
                                            <span>官网价：</span>
                                            <b>{tofixed(parseFloat(item.bandwidth.originalAmount) - parseFloat(item.bandwidth.roundAmount))}元</b>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </React.Fragment>
                        ))}
                        <div className="footer-pay">
                            <div className="footer-pay-price">
                                <span>消费金额：<b>{tofixed(parseFloat(payment_bills_info.originalAmount) - parseFloat(payment_bills_info.roundAmount))}元</b></span>
                                {parseFloat(payment_bills_info.voucherAmount) > 0 ?
                                    <span>代金券抵扣金额：<b>{tofixed(payment_bills_info.voucherAmount)}元</b></span>
                                    :
                                    null
                                }
                                <span>欠款金额：<b>{tofixed(payment_bills_info.amountDue)}元</b></span>
                            </div>
                        </div>
                    </Spin>
                    :
                    null
                }
            </CardNav>
            {/* 代金券 */}
            {!payment_bills_info.isVoucherUsed ?
                <CardNav title={<div><b>代金券</b>（确定后代金券金额不可更改，不可撤销）</div>}>
                    {!vouchers.length ? <p className="voucher-empty">-暂无代金券<Link to='/console/account/voucher'>去激活</Link>-</p>
                        :
                        <Spin spinning={voucherLoading}>
                            <Checkbox.Group style={{ width: '100%' }} onChange={onChange} value={checkedValues}>
                                {vouchers.map((voucher: any) => {
                                    return (
                                        <div className="voucher-card" key={voucher.id}>
                                            <div className="voucher-img">
                                                <span className="voucher-check">
                                                    <Checkbox value={voucher.id} disabled={voucherPrice >= amountDue && !checkedValues.includes(voucher.id)}></Checkbox>
                                                </span>
                                                <span className="voucher-right">{(voucher?.status === 'available' && Number(voucher?.balance) !== 0) ? '可用' : '不可用'}</span>
                                                <div className="voucher-price">
                                                    <b>{voucher?.balance}</b>
                                                    <span>可用额度</span>
                                                    <span>{`总额度：${voucher?.amount}`}</span>
                                                </div>
                                            </div>
                                            <div className='voucher-text'>
                                                <p>代金券编号：{voucher?.id}</p>
                                                <p>有效期至：{moment(voucher?.endTime).format('YYYY-MM-DD')}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Checkbox.Group>
                        </Spin>
                    }
                </CardNav>
                : null
            }
            {/* 支付管理 */}
            <CardNav title={<b>支付方式</b>} className='remove-bottom'>
                <Radio.Group value={payType} onChange={(e) => { setPayType(e.target.value); }} defaultValue="wechat_pay" className="pay-radio-group">
                    <Radio.Button value="wechat_pay">微信支付</Radio.Button>
                    <Radio.Button value="transfer">对公转账</Radio.Button>
                </Radio.Group>
                <div className="pay-footer" ref={refPayBtn}>
                    <div className={`pay-btn ${isFixed ? 'pay-btn-position' : ''}`}>
                        <span>实付金额：¥</span>
                        <b>{voucherPrice >= amountDue ? 0 : new Decimal(amountDue).sub(voucherPrice).toFixed(2)} </b>
                        {/* <Button type="primary" onClick={() => { dispatch(ModalAction.open(PAYMENT_TYPE_MODAL, { payType, amountDue: voucherPrice >= amountDue ? 0 : amountDue - voucherPrice })) }}>确定</Button> */}
                        <Button type="primary" onClick={setVoucherBtn} loading={submitLoading || voucherLoading || fetching}>确定</Button>
                    </div>
                </div>
            </CardNav>

            {succLoading ? <PaymentSuccessModal /> : null}
            {payLoading ? <PaymentTypeModal /> : null}
        </div>
    )
}