import { MapReducer } from '../../../redux/reducers/map.reducer';
import { DOCUMENT } from '../constants/action-types.constants';

export class DocumentReducer extends MapReducer {
    private static defaults = {
        document: {},
        menus: [],
    }

    constructor() {
        super(DOCUMENT, DocumentReducer.defaults);
    }
}
