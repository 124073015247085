import { SHA256, enc, HmacSHA256 } from 'crypto-js';
import dayjs from 'dayjs';

export function sign(date, hashedPayload) {
    const yyyyMMdd = dayjs().format('YYYYMMDD').toString();
    const stringToSign = [
        'HMAC-SHA256',
        date,
        `${yyyyMMdd}/yanrong/file-storage`,
        hashedPayload,
    ].join('\n');
    const secret = 'com.yanrongyun.2016-04';
    const signSecret = HmacSHA256(
        'yanrong_request',
        HmacSHA256(yyyyMMdd, 'YanRong' + secret).toString(enc.Hex),
    ).toString(enc.Hex);
    return HmacSHA256(stringToSign, signSecret).toString(enc.Hex);
}

export function generateHashedPayload(method, url, query = {}) {
    const signHeaders = {
        'user-agent': navigator.userAgent,
        'host': window.location.host,
    }
    const queryKeys = Object.keys(query).filter(key => key !== 'sign' && query[key] !== undefined);
    const standardQueries = queryKeys
        .sort()
        .map((key, index) => {
            return `${key}=${encodeURIComponent(query[key])}${queryKeys.length - 1 === index ? '' : '&'}`;
        })
        .join('');

    const standardHeaders = Object.keys(signHeaders)
        .sort()
        .map(key => {
            return `${key}:${signHeaders[key]}\n`;
        })
        .join('');
    const requestPayload = '' +
        method.toUpperCase() + '\n' +
        url + '/\n' +
        standardQueries + '\n' +
        standardHeaders;
    return SHA256(requestPayload).toString(enc.Hex);
}
