import React, { useEffect } from 'react';
import { Form, Input, Button, message as Message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { State, Account, Fetch } from '../../../interfaces';
import { AccountAction } from '../actions';
import { UPDATE_ACCOUNT } from '../constants/fetch.constants';

export const InfoForm = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const account = useSelector<State, Account>(
        state => state.account.info,
    );
    const { fetching } = useSelector<State, Fetch>(
        state => state.fetch[UPDATE_ACCOUNT] ?? {} as any,
    );
    useEffect(() => {
        form.setFieldsValue({ ...account });
    }, [account.email, account.phone, account.name, account.company, account.address]);
    const submit = (values) => {
        const params = {
            name: values.name,
            company: values.company,
            address: values.address,
        };
        dispatch(AccountAction.updateAccount(params, () => {
            Message.success('修改成功');
        }));
    };
    return <Form
        form={form}
        layout="vertical"
        onFinish={(values) => submit(values)}
        colon={false}
        style={{ width: 600 }}
    >
        <Form.Item
            name="email"
            label="电子邮箱"
            initialValue={account.email}
        >
            <Input disabled />
        </Form.Item>
        <Form.Item
            name="phone"
            label="手机号"
            initialValue={account.phone}
        >
            <Input disabled />
        </Form.Item>
        <Form.Item
            name="name"
            label="姓名"
            initialValue={account.name}
        >
            <Input placeholder="请输入您的姓名" />
        </Form.Item>
        <Form.Item
            name="company"
            label="公司"
            initialValue={account.company}
        >
            <Input placeholder="请输入您的公司" />
        </Form.Item>
        <Form.Item
            name="address"
            label="所在地"
            initialValue={account.address}
        >
            <Input placeholder="请输入您的所在地" />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center', margin: '48px 0 120px' }}>
            <Button type="primary" onClick={() => form.submit()} loading={fetching}>修改</Button>
        </Form.Item>
    </Form>
}
