export enum BillStatus {
    'unsettled' = '出账中',
    'cleared' = '已出账',
}
export enum BillsType {
    'size' = '容量型产品',
    'bandwidth' = '性能加速服务',
}
export enum BillsTypeInfo {
    'fs.capacity.size' = '容量型产品',
    'fs.capacity.bandwidth' = '性能加速服务',
}
export enum BillsTypeDosageInfo {
    'fs.capacity.size' = '使用容量',
    'fs.capacity.bandwidth' = '平均使用性能',
}
export enum paymentType {
    'wechat_pay' = '微信支付',
    'transfer' = '对公转账'
}

export enum PayMethod {
    /**
     * 对公转账
     */
    TRANSFER = 'transfer',
    /**
     * 支付宝
     */
    ALI_PAY = 'ali_pay',
    /**
     * 微信支付
     */
    WECHAT_PAY = 'wechat_pay',
}