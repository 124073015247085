import React, { useState, useEffect } from 'react';
import { find } from 'lodash';
import { Modal, Form, Input, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CloseModalIcon } from './../../components/icons.component';
import { State, Modal as ModalInterface, Fetch } from '../../../interfaces';
import { MODIFY_CONFIG_MODAL } from '../constants/modal.constants';
import { ModalAction } from '../../../redux';
import { InstanceAction } from '../actions/instance.action';
import { MODIFY_NETWORK_CONFIG } from '../constants/fetch.constants';
import arnImg from '../../../asserts/arn_tip.jpg';
import aliyunImg from '../../../asserts/aliyun_tip.jpeg';
import './modify-config.modal.less';

export const ModifyConfigModal = (props) => {

    const dispatch = useDispatch();
    const formLayout = { labelCol: { span: 5 }, wrapperCol: { span: 18, offset: 1 } };
    const { visible, ...instance } = useSelector<State, ModalInterface>(
        state => state.modals[MODIFY_CONFIG_MODAL] ?? {} as any,
    );
    const { fetching } = useSelector<State, Fetch>(
        state => state.fetch[MODIFY_NETWORK_CONFIG] ?? {} as any,
    );
    const [form] = Form.useForm();
    const [tipVisible, updateTipVisible] = useState(false);

    const instanceAwsAccountId = (find(instance.metadata, { key: 'aws_account_id' }) ?? {}).value;
    const instanceAwsVpcId = (find(instance.metadata, { key: 'aws_vpc_id' }) ?? {}).value;
    const instanceArn = (find(instance.metadata, { key: 'arn' }) ?? {}).value;

    const submit = (values) => {
        dispatch(InstanceAction.ModifyNetworkConfig(instance.id, values));
    };
    
    const platform = instance?.region?.platform;
    useEffect(() => {
        form.setFieldsValue({ arn: instance.arn })
    }, [instance])

    return <Modal
        visible={visible}
        title="配置变更"
        onCancel={() => dispatch(ModalAction.close(MODIFY_CONFIG_MODAL))}
        destroyOnClose={true}
        width={600}
        className="modify-config-modal"
        okText="配置变更"
        centered={true}
        closeIcon={<CloseModalIcon />}
        onOk={() => form.submit()}
        confirmLoading={fetching}
    >
        <Form
            form={form}
            preserve={false}
            onFinish={(values) => submit(values)}
            colon={false}
            labelAlign="left"
            className="form"
            {...formLayout}
        >
            {
                instance?.type && instance?.type !== 'fs.capacity' ? <Form.Item
                    label="您的账户ID"
                    name="awsAccountId"
                    required={false}
                    rules={[{ required: true, message: '请输入您的账户ID（12位）' }]}
                    initialValue={instanceAwsAccountId}
                >
                    <Input maxLength={12}/>
                </Form.Item> : null
            }
            {
                instance?.type && instance?.type !== 'fs.capacity' ? <Form.Item
                    label="您的VPC ID"
                    name="awsVpcId"
                    required={false}
                    rules={[{ required: true, message: '请输入您的VPC ID' }]}
                    initialValue={instanceAwsVpcId}
                >
                    <Input/>
                </Form.Item> : null
            }
            {
                instance?.type && instance?.type !== 'fs.performance' ?
                    <Row>
                        <Col span={23}>
                            <Form.Item
                                label={platform === 'aws' ? 'ARN' : '阿里云账户ID'}
                                wrapperCol={{ span: 24 }}
                                name="arn"
                                required={false}
                                rules={[{
                                    required: true,
                                    message: `请输入${platform === 'aws' ? 'ARN' : '阿里云账户ID'}`,
                                }]}
                                initialValue={instanceArn}>
                                <Input placeholder="请输入ARN"/>
                            </Form.Item>
                        </Col>
                        <Col span={1} style={{ lineHeight: '40px', color: '#7F8A94', textAlign: 'center' }}>
                            <div className="annotation-icon" onClick={() => updateTipVisible(true)}></div>
                        </Col>
                    </Row> : null
            }
        </Form>
        <Modal
            title="获取ARN"
            footer={null}
            onCancel={() => updateTipVisible(false)}
            visible={tipVisible}
            width={1200}
            closeIcon={<CloseModalIcon />}
            centered={true}
        >
            <img alt="arn" style={{ width: '100%', padding: 24 }} src={platform === 'aws' ? arnImg : aliyunImg}/>
        </Modal>
    </Modal>
}
