import { Dispatch } from 'redux';
import {Action, Client} from '../../../redux';
import {CREATE_EXPERIENCE_APPLY, CREATE_EXPERIENCE_FEEDBACK} from '../constants/fetch.constants';
import {ACCOUNT} from "../../account/constants/action-types.constants";

export class ExperienceAction {
    private static client = new Client()

    public static createExperienceApply(params, callback) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { accountId} = params;
            await this.client.post(`/apis/experience-apply/${accountId}`, params, {
                fetch: CREATE_EXPERIENCE_APPLY,
                dispatch,
            });
            callback();
        }
    }

    public static createExperienceFeedback(params, callback) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { accountId} = params;
            await this.client.post(`/apis/experience-feedback/${accountId}`, params, {
                fetch: CREATE_EXPERIENCE_FEEDBACK,
                dispatch,
            });
            callback();
        }
    }

    public static updateExperienceFeedback(feedbackId: string, params, callback) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post(`/apis/experience-feedback-update/${feedbackId}`, params, {
                fetch: CREATE_EXPERIENCE_FEEDBACK,
                dispatch,
            });
            callback();
        }
    }

    public static hasExperienceApply(accountId: string, callback) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const has = await this.client.get(`/apis/has-experience-apply/${accountId}`);
            callback(has);
        }
    }

    public static hasExperienceFeedback(accountId: string, callback) {
        return async (dispatch: Dispatch<any>, state: any) => {
            // const accountId = state().session.accountId ?? {} as any;
            const has = await this.client.get(`/apis/has-experience-feedback/${accountId}`);
            callback(has);
        }
    }

    public static getExperienceFeedback(accountId: string, callback) {
        return async (dispatch: Dispatch<any>, state: any) => {
            // const accountId = state().session.accountId ?? {} as any;
            const model = await this.client.get(`/apis/get-experience-feedback/${accountId}`);
            dispatch(new Action(ACCOUNT).put('feedback', model));
            callback(model);
        }
    }
}
