import React from 'react';
import { Link } from 'react-router-dom';
import './default-table.component.less';

export const DefaultTable = (props) => {
    return props.type === 'voucher' ? <div className="default-table default-voucher-table">
        <p>暂无可用代金券，<Link to="/console/account/voucher">去激活代金券&gt;&gt;</Link></p>
    </div> :
        <div className="default-table">
            <p>-{props.text ? props.text : '暂无内容'}-</p>
        </div>
}

export const DefaultTableText = (props) => {
    return props.type === 'voucher' ? <div className="default-table-text" style={{ marginBottom: 100 }}>
        <p>-暂无代金券-</p>
    </div> : <div className="default-table-text">-暂无内容-</div>
}
