import * as _ from 'lodash';
import { Menu, Layout } from 'antd';
import { useState } from 'react';
import { PageTypography } from './../../components/page.typography';
import { CommonLayout } from '../../components/common-layout.component';
import {
    StorageProduct,
    StorageBegin,
    StorageCharging,
    StorageIssue,
    StorageProtocol
} from './storage.pages'
import './document.storage.less';

export const DocumentStorage = () => {
    const menuKeys = [
        {
            name: '产品介绍',
            page: <StorageProduct />
        },
        {
            name: '快速上手',
            page: <StorageBegin />
        },
        {
            name: '产品计费',
            page: <StorageCharging />
        },
        {
            name: '常见问题',
            page: <StorageIssue />
        },
        {
            name: '相关协议',
            page: <StorageProtocol />
        },
    ];
    const [selectedKeys, setSelectedKeys] = useState(['产品介绍']);
    const [touchIndex, setTouchIndex] = useState(0);

    const onChange = ({ key, keyPath }) => {
        setTouchIndex(_.findIndex(menuKeys, { name: key }));
        setSelectedKeys(keyPath);
    }
    return (
        <CommonLayout>
            <div className="document-storage">
                <PageTypography title='产品文档'>产品文档是焱融SaaS文件存储的使用说明，包括并不限于产品介绍、使用场景、快速入门、产品计费、常见问题等。</PageTypography>
                <Layout className="document-layout">
                    <Layout.Sider className="document-sider" theme="light">
                        <Menu
                            defaultSelectedKeys={selectedKeys}
                            onClick={onChange}
                            className="document-menus"
                            id="menus"
                        >
                            {menuKeys.map(menu => (
                                <Menu.Item key={menu.name}>{menu.name}</Menu.Item>
                            ))}
                        </Menu>
                    </Layout.Sider>
                    <Layout.Content className="document-content">
                        <div className='document-content-box'>
                            {menuKeys[touchIndex].page}
                        </div>

                    </Layout.Content>
                </Layout>
            </div>
        </CommonLayout>

    )
}