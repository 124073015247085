import Icon from '@ant-design/icons';
import messageSvg from '../../asserts/icons/icon_news_24.svg';
import defaultAvatarSvg from '../../asserts/icons/img_default_avatar_36@3x.svg';
import accountSettingSvg from '../../asserts/icons/icon_account_setup_16@1x.svg';
import SignOutSvg from '../../asserts/icons/icon_sign_out_16@1x.svg';
import createSvg from '../../asserts/icons/icon_new_16.svg';
import createDisableSvg from '../../asserts/icons/icon_add_disabled16.svg';
import refreshSvg from '../../asserts/icons/icon_refresh_16@3x.svg';
import settingSvg from '../../asserts/icons/icon_operation_18@3x.svg';
import adminSvg from '../../asserts/icons/img_administrators_24.svg';
import prevSvg from '../../asserts/icons/icon_left_normal_16@3x.svg';
import nextSvg from '../../asserts/icons/icon_right_normal_16@3x.svg';
import rightGreenSvg from '../../asserts/icons/icon_arrow_16.svg';
import ticketSvg from '../../asserts/icons/icon_work_order_16@1x.svg';
import plusSvg from '../../asserts/icons/icon_new_black_16@1x.svg';
import balanceSvg from '../../asserts/icons/icon_balance_16@1x.svg';
import billingSvg from '../../asserts/icons/icon_expense_bill_16@1x.svg';
import orderSvg from '../../asserts/icons/icon_order_16@1x.svg';
import voucherSvg from '../../asserts/icons/icon_coupon_management_16@1x.svg';
import accountMoreSvg from '../../asserts/icons/icon_more_ash_12@3x.svg';
import lightSvg from '../../asserts/icons/icon_activation_16.svg';
import uploadSvg from '../../asserts/icons/icon_upload_attachment_1_16.svg';
import uploadTopSvg from '../../asserts/icons/icon_upload_attachment_16.svg';
import usernameSvg from '../../asserts/icons/icon_account_number_16.svg';
import usernamewSvg from '../../asserts/icons/icon_account_w.svg';
import passwordSvg from '../../asserts/icons/icon_password_16.svg';
import passwordwSvg from '../../asserts/icons/icon_password_w.svg';
import verifySvg from '../../asserts/icons/icon_verification_code_16.svg';
import verifywSvg from '../../asserts/icons/icon_verification_code_w.svg';
import emailSvg from '../../asserts/icons/icon_mailbox_16.svg';
import phoneSvg from '../../asserts/icons/icon_mobile_phone_16.svg';
import copySvg from '../../asserts/mount/icon_copy_16.svg';
import normalSvg from '../../asserts/icons/icon_check_mark_16.svg';
import exitSvg from '../../asserts/icons/icon_tuiding_16.svg';
import DateSvg from '../../asserts/icons/icon_date_16.svg'
import annotationSvg from '../../asserts/icons/icon_annotation_red_16.svg'
import editSvg from '../../asserts/icons/icon_refresh_16.svg'
import closeModalSvg from '../../asserts/icons/icon_close_16.svg'

export const CopyIcon = (props) => {
    return <Icon component={() => <img src={copySvg} alt="" />} {...props} style={{ margin: '0 6px' }} />;
}

export const EmailIcon = (props) => {
    return <Icon component={() => <img src={emailSvg} alt="" />} {...props} />;
}

export const PhoneIcon = (props) => {
    return <Icon component={() => <img src={phoneSvg} alt="" />} {...props} />;
}

export const VerifyIcon = (props) => {
    return <Icon component={() => <img src={verifySvg} alt="" />} {...props} />;
}

export const UsernameIcon = (props) => {
    return <Icon component={() => <img src={usernameSvg} alt="" />} {...props} />;
}

export const PasswordIcon = (props) => {
    return <Icon component={() => <img src={passwordSvg} alt="" />} {...props} />;
}

export const UploadIcon = (props) => {
    return <Icon component={() => <img src={uploadSvg} alt="" />} {...props} />;
}
export const UploadTopIcon = (props) => {
    return <Icon component={() => <img src={uploadTopSvg} alt="" />} {...props} />;
}

export const LightIcon = (props) => {
    return <Icon component={() => <img src={lightSvg} alt="" />} {...props} />;
}

export const AccountMoreIcon = (props) => {
    return <Icon component={() => <img src={accountMoreSvg} alt="" />} {...props} />;
}

export const VoucherIcon = (props) => {
    return <Icon component={() => <img src={voucherSvg} alt="" />} {...props} />;
}

export const OrderIcon = (props) => {
    return <Icon component={() => <img src={orderSvg} alt="" />} {...props} />;
}

export const BillingIcon = (props) => {
    return <Icon component={() => <img src={billingSvg} alt="" />} {...props} />;
}

export const BalanceIcon = (props) => {
    return <Icon component={() => <img src={balanceSvg} alt="" />} {...props} />;
}

export const PlusIcon = (props) => {
    return <Icon component={() => <img src={plusSvg} alt="" />} {...props} />;
}

export const TicketIcon = (props) => {
    return <Icon component={() => <img src={ticketSvg} alt="" />} {...props} />;
}

export const RightGreenIcon = (props) => {
    return <Icon component={() => <img src={rightGreenSvg} alt="" />} {...props} />;
}

export const PrevIcon = (props) => {
    return <Icon component={() => <img src={prevSvg} alt="" />} {...props} />;
}

export const NextIcon = (props) => {
    return <Icon component={() => <img src={nextSvg} alt="" />} {...props} />; 
}

export const AdminIcon  = (props) => {
    return <Icon component={() => <img src={adminSvg} alt="" />} {...props} />;
}

export const SettingIcon = (props) => {
    return <Icon component={() => <img src={settingSvg} alt="" />} {...props} />;
}

export const RefreshIcon = (props) => {
    return <Icon component={() => <img src={refreshSvg} alt="" />} {...props} />;
}

export const CreateIcon = (props) => {
    return <Icon component={() => <img src={createSvg} alt="" />} {...props} />;
}
export const CreateDisabledIcon = (props) => {
    return <Icon component={() => <img src={createDisableSvg} alt="" />} {...props} />;
}

export const SignOutIcon = (props) => {
    return <Icon component={() => <img src={SignOutSvg} alt="" />} {...props} />;
}

export const AccountSettingIcon = (props) => {
    return <Icon component={() => <img src={accountSettingSvg} alt="" />} {...props} />;
}

export const DefaultAvatarIcon = (props) => {
    return <Icon component={() => <img src={defaultAvatarSvg} alt="" />} {...props} />;
}

export const MessageIcon = (props) => {
    return <Icon component={() => <img src={messageSvg} alt="" />} {...props} />;
}
export const NormalIcon = (props) => {
    return <Icon component={() => <img src={normalSvg} alt=""/>} {...props}/>;
}
export const ExitIcon = (props) => {
    return <Icon component={() => <img src={exitSvg} alt=""/>} {...props}/>;
}
export const DateIcon = (props) => {
    return <Icon component={() => <img src={DateSvg} alt=""/>} {...props}/>;
}
export const AnnotaIcon = (props) => {
    return <Icon component={() => <img src={annotationSvg} alt=""/>} {...props}/>;
}
export const EditIcon = (props) => {
    return <Icon component={() => <img src={editSvg} alt=""/>} {...props}/>;
}
export const UserNameWIcon = (props) => {
    return <Icon component={() => <img src={usernamewSvg} alt="" style={{width:'16px'}}/>} {...props}/>;
}
export const PasswordWIcon = (props) => {
    return <Icon component={() => <img src={passwordwSvg} alt="" style={{width:'16px'}}/>} {...props}/>;
}
export const VerifyWIcon = (props) => {
    return <Icon component={() => <img src={verifywSvg} alt="" style={{width:'16px'}}/>} {...props}/>;
}
export const CloseModalIcon = (props) => {
    return <Icon component={() => <img src={closeModalSvg} alt=""/>} {...props}/>;
}
