import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { Dispatch } from 'redux';
import { message as Message } from 'antd';
import { generateHashedPayload, sign } from "../helpers/sign.helper";
import { FetchAction } from "./actions";

interface RequestConfig extends AxiosRequestConfig {
    fetch?: string;
    dispatch?: Dispatch<any>;
    showMessage?: boolean;
}

export class Client {
    private readonly client: AxiosInstance = Axios.create();

    constructor() {
        this.client.interceptors.request.use(
            config => {
                const hashedPayload = generateHashedPayload(config.method, config.url, config.params);
                const date = new Date().getTime();
                config.headers.authorization = sign(date, hashedPayload);
                config.headers['x-yr-date'] = date;
                config.headers['sign-headers'] = 'host;user-agent';
                return config;
            },
            err => Promise.reject(err),
        );
        this.client.interceptors.response.use(
            response => {
                return response;
            }, (error) => {
                if (error.response && error.response.status === 401) {
                    const historyHash = window.location.hash;
                    const hash = historyHash.indexOf('/sign-in?hash') === -1 ? `/sign-in?hash=${encodeURIComponent(historyHash)}` : historyHash;
                    window.location.hash = hash;
                }
                return Promise.reject(error);
            });
    }

    get<T extends any>(url: string, options?: RequestConfig): Promise<T> {
        return this.request({ method: 'get', url, ...options });
    }

    post<T extends any, B extends any>(url: string, data: B, options?: RequestConfig): Promise<T> {
        return this.request({ method: 'post', url, data, ...options });
    }

    patch<T extends any, B extends any>(url: string, data: B, options?: RequestConfig): Promise<T> {
        return this.request({ method: 'patch', url, data, ...options });
    }

    put<T extends any, B extends any>(url: string, data: B, options?: RequestConfig): Promise<T> {
        return this.request({ method: 'put', url, data, ...options });
    }

    delete<T extends any>(url: string, options?: RequestConfig): Promise<T> {
        return this.request({ method: 'delete', url, ...options });
    }

    async request(options: RequestConfig) {

        if (options.fetch && options.dispatch) {
            options.dispatch(FetchAction.fetching(options.fetch));
        }

        let response: AxiosResponse;
        try {
            response = await this.client.request(options);
        } catch (e) {
            const message = e?.response?.data?.message ?? e?.message;
            const code = e?.response?.status;
            if (options.fetch && options.dispatch) {
                options.dispatch(FetchAction.error(options.fetch, message));
            }
            if ((options.showMessage || options.showMessage === undefined) && code !== 401) {
                Message.destroy();
                Message.error(message);
            }

            throw e;
        }

        if (response.status >= 400 || response.status < 200) {
            const message = response?.data?.message;
            if (options.fetch && options.dispatch) {
                options.dispatch(FetchAction.error(options.fetch, message));
            }
            if ((options.showMessage || options.showMessage === undefined) && response.status !== 401) {
                Message.destroy();
                Message.error(message);
            }
        }

        setImmediate(() => {
            if (options.fetch && options.dispatch) {
                options.dispatch(FetchAction.succeed(options.fetch));
            }
        });

        return response.data;
    }
}
