import { Modal, Spin, Button } from 'antd';
import QRCode from 'qrcode.react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State, Fetch, Modal as ModalInterface } from '../../../interfaces';
import { ModalAction } from '../../../redux';
import { BillsAction } from './../actions';
import { paymentType, PayMethod } from './../enums'
import { PAYMENT_TYPE_MODAL } from '../constants/modal.constants';
import { GET_CODE_URL } from '../constants/fetch.constants';
import resetIcon from './../../../asserts/icons/icon_shuaxin_28.svg';
import { CloseModalIcon } from '../../components/icons.component';

import './payment-type.modal.less';

export const PaymentTypeModal = () => {
    const dispatch = useDispatch();
    const { visible, ...record } = useSelector<State, ModalInterface>(
        state => state.modals[PAYMENT_TYPE_MODAL] ?? {} as any,
    );
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_CODE_URL] ?? {} as any);
    const { code_url } = useSelector<State, any>(state => state.bills);
    const setSocketBtn = () => {
        const { id } = record;
        dispatch(BillsAction.setSocketTest(id))
    }
    const refresh = () => {
        const { id, payType } = record;
        dispatch(BillsAction.getQrcode(id, payType));
    }
    useEffect(() => {
        refresh()
    }, [])
    return (
        <>
            <Modal
                title={paymentType[record.payType]}
                visible={visible}
                width={record.payType === PayMethod['TRANSFER'] ? 700 : 600}
                footer={false}
                maskClosable={false}
                centered={true}
                destroyOnClose={true}
                closeIcon={<CloseModalIcon />}
                onCancel={() => {
                    dispatch(ModalAction.close(PAYMENT_TYPE_MODAL));
                }}
                className="payment-type-modal"
            >
                <Spin spinning={record.payType !== PayMethod['TRANSFER'] && fetching}>
                    {/* 对公转账 */}
                    {record.payType === PayMethod['TRANSFER'] ?
                        <div className="public-pay">
                            <p className="payment-price">应付金额 <b>{record.amount}</b>元</p>
                            <p>请将费用转到下面的银行账户中。</p>
                            <table className='public-table'>
                                <tbody>
                                    <tr>
                                        <td>开户名称</td>
                                        <td>招商银行股份有限公司</td>
                                    </tr>
                                    <tr>
                                        <td>开户银行</td>
                                        <td>北京清河支行</td>
                                    </tr>
                                    <tr>
                                        <td>银行账户</td>
                                        <td>110921524510301</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p className='payment-strong'>请备注好您的焱融SaaS文件存储账号。</p>
                            <p className="payment-explain">如支付金额与您的账单不符，我们会为您退回该笔款项。受银行处理时间影响，该方式到账会有延误，请您耐心等待。</p>
                        </div>
                        :
                        // 微信支付
                        <div className="qrcode-box">
                            <p className="payment-price">应付金额 <b>{record.amount}</b>元</p>
                            <div className='qrcode-qr'>
                                <QRCode
                                    value={code_url}
                                    size={260} // 二维码的大小
                                    fgColor="#000000" // 二维码的颜色
                                    style={{ margin: 'auto' }}
                                // imageSettings={{ // 二维码中间的logo图片
                                //     src: icon,
                                //     height: 50,
                                //     width: 50,
                                //     excavate: true, // 中间图片所在的位置是否镂空
                                // }}
                                />
                                {code_url ?
                                    null
                                    :
                                    <div className="url-error" onClick={refresh}>
                                        <img src={resetIcon} alt="" />
                                        <p>二维码失效，请您刷新后再重试</p>
                                    </div>
                                }

                            </div>
                            <Button type='link' style={{ position: 'absolute', bottom: '10px', right: '10px' }} onClick={setSocketBtn}>测试使用</Button>
                            <span>请使用微信扫一扫，扫描支付二维码</span>
                        </div>
                    }
                </Spin>
            </Modal>
        </>
    )

}