import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ModalAction } from '../../../redux';
import { REMOVE_ACCOUNT_MODAL } from '../constants/modal.constants';
import { RemoveAccountModal } from '../modals/remove-account.modal';

export const Logout = (props) => {
    const dispatch = useDispatch();
    return <div>
        <p style={{ fontSize: 14, color: '#324558', marginBottom: 28 }}>在注销账户之前，需要卸载账户中的所有文件系统</p>
        <Button type="primary" onClick={() => dispatch(ModalAction.open(REMOVE_ACCOUNT_MODAL))}>注销账户</Button>
        <RemoveAccountModal />
    </div>
}
