import * as _ from 'lodash';
import { useState, useEffect } from 'react';
import { Button, Form, message as Message, Input, Row, Col, Select, Checkbox, Modal, Switch, message } from 'antd';
import { useHistory } from 'react-router';
import { PageTypography } from './../../components/page.typography';
import { CardNav } from './../../components/card.navigation';
import { useDispatch, useSelector } from 'react-redux';
import { ModalAction } from '../../../redux';
import { Fetch, State } from '../../../interfaces';
import { InstanceAction } from '../actions/instance.action';
import { CREATE_PURCHASE_ORDER } from '../constants/fetch.constants';
import { MODIFY_QUICKEN_MODAL } from '../constants/modal.constants';
import { ModifyQuickenModal } from './../modals/modify-quicken.modal';
import { amountSize } from './../enums';
import { GiBToGB, MbpsToMBs } from './../../../helpers/capacity.helper';
import { CaretDownOutlined } from '@ant-design/icons';
import { useMount } from 'react-use';
import { Link } from 'react-router-dom';
import arnImg from '../../../asserts/arn_tip.jpg';
import aliyunImg from '../../../asserts/aliyun_tip.jpeg';
import awsRegionImg from '../../../asserts/aws-region.jpeg';
import exchangeImg from '../../../asserts/mount/mount_exchange.png';
import './create-instance.container.less';
import { CloseModalIcon } from '../../components/icons.component';

export const CreateInstanceContainer = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { platformsData, regionsData } = useSelector<State, any>((state) => state.instance.region ?? ({} as any));
    const products = useSelector<State, any>((state) => state.instance.products);
    const priceSize = (_.find(products[0]?.billingItems, { type: 'fs.capacity.size' }) ?? {}).price ?? '0';
    const priceBandWidth = (_.find(products[0]?.billingItems, { type: 'fs.capacity.bandwidth' }) ?? {}).price ?? '0';
    const { fetching } = useSelector<State, Fetch>((state) => state.fetch[CREATE_PURCHASE_ORDER] ?? {});
    const [isAgree, updateIsAgree] = useState(false); // 同意用户协议
    const [regions, updateRegions] = useState([]); // 服务器区域 地区列表
    const [tip, updateTip] = useState({
        visible: false,
        title: '',
        pic: '',
    });
    const platform = form.getFieldValue('platform');

    const submit = (values) => {
        // console.log(values, '----->',amountSize.open)
        const { name, jiasu, regionId, arn } = values;
        const data = {
            name,
            // arn: "arn:aws-cn:iam::183896427249:user/yanrong",
            arn,
            regionId,
            product: 'fs.capacity', // 产品名称，默认 容量
            billingMode: 'afterpay', // 订单类型，默认
            amount: 1, // 产品名称，默认
            item: {
                'fs.capacity.bandwidth': jiasu ? amountSize.open : amountSize.close, // 是否开始加速 400/8000
                'fs.capacity.size': 3072000, //默认容量 最大值
            },
        };
        if (products[0]?.soldOut) {
            message.error('产品已售罄');
            return false;
        }
        dispatch(
            InstanceAction.createPurchase(data, () => {
                Message.success('创建成功');
                // 返回文件系统首页
                history.push('/console/instances');
            }),
        );
    };
    // form字段值更新时触发回调事件
    const handleFormChange = (changedValues, _allValues) => {
        const { platform, regionId } = changedValues;
        if (platform) {
            const regionId = regionsData[platform][0]?.id;
            updateRegions(regionsData[platform] ?? ([] as any));
            form.setFieldsValue({
                regionId: regionsData[platform][0].id,
            });
            dispatch(InstanceAction.getProducts(regionId, 'afterpay'));
            return;
        }
        if (regionId) {
            dispatch(InstanceAction.getProducts(regionId, 'afterpay'));
            return;
        }
    };
    const showQuickenModal = () => {
        dispatch(ModalAction.open(MODIFY_QUICKEN_MODAL, { isSubmit: true }));
    };

    useMount(() => {
        dispatch(InstanceAction.getRegions());
    });
    // 初始化设置服务器区域默认值
    useEffect(() => {
        if (platformsData.length) {
            updateRegions(regionsData[platformsData[0].key] ?? ([] as any));
            const regionId = regionsData[platformsData[0].key][0].id;
            const billingMode = 'afterpay';
            form.setFieldsValue({
                platform: platformsData[0].key ?? '',
                regionId,
            });
            dispatch(InstanceAction.getProducts(regionId, billingMode));
        }
    }, [platformsData, regionsData]);

    return (
        <div className="create-instance-container">
            <PageTypography title="文件系统">
                焱融SaaS文件存储，是构建在公有云基础架构上的NAS共享的数据服务平台，为用户提供更快，更便宜，更灵活的数据共享服务。可选POSIX文件接口或者标准NFS共享接口，满足不同应用场景下的多样化存储需求。
            </PageTypography>
            <CardNav title="创建文件系统" link="/console/instances">
                <Form form={form} onFinish={(values) => submit(values)} colon={false} onValuesChange={handleFormChange} className="form" layout="vertical">
                    <Form.Item
                        label="文件系统名"
                        name="name"
                        rules={[{ required: true, message: '请输入文件系统名称' }]}
                        getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
                    >
                        <Input placeholder="请输入文件系统名称" />
                    </Form.Item>
                    <Row className="server-region">
                        <Col span={11} style={{ paddingRight: 8 }}>
                            <Form.Item style={{ marginBottom: 0 }} label="服务器区域" name="platform" required>
                                <Select suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />} disabled={products[0]?.soldOut}>
                                    {platformsData.map((item) => (
                                        <Select.Option value={item.key} key={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11} style={{ paddingLeft: 8 }}>
                            <Form.Item style={{ marginBottom: 0 }} label="&nbsp;" name="regionId" required={false}>
                                <Select suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />} disabled={products[0]?.soldOut}>
                                    {regions.map((item: any) => (
                                        <Select.Option value={item.id} key={item.id}>
                                            {item.name + (products[0]?.soldOut ? '(售罄)' : '')}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={2} style={{ paddingTop: 38, textAlign: 'center' }}>
                            <div
                                className="annotation-icon"
                                onClick={() => updateTip({ visible: true, title: '获取可用区', pic: platform === 'aws' ? awsRegionImg : exchangeImg })}
                            ></div>
                        </Col>
                        <p style={{ color: '#7F8A94', marginTop: 8 }}>请如实填写您的交换机所在区域，如填写错误，文件系统将不可用！</p>
                        <p style={{ color: '#7F8A94', marginBottom: 24 }}>如您的交换机所在区域不在列表中，表明平台暂不支持该区域！</p>
                    </Row>

                    {platform === 'aws' ? (
                        <Form.Item label="ARN" name="arn" required rules={[{ required: true, message: `请输入ARN` }]}>
                            <Row>
                                <Col span={22}>
                                    <Input placeholder="请输入ARN" />
                                </Col>
                                <Col span={2} style={{ textAlign: 'center', lineHeight: '30px' }}>
                                    <div
                                        className="annotation-icon"
                                        onClick={() => updateTip({ visible: true, title: '获取ARN', pic: arnImg })}
                                        style={{ top: 6 }}
                                    ></div>
                                </Col>
                            </Row>
                        </Form.Item>
                    ) : null}
                    {platform === 'aliyun' ? (
                        <Form.Item
                            label="阿里云账户ID"
                            name="arn"
                            validateFirst={true}
                            rules={[
                                { required: true, message: '请输入阿里云账户ID' },
                                {
                                    validator: (_: any, value: string) => {
                                        if (value.length === 16) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(_?.message);
                                    },
                                    message: '请输入正确的阿里云ID',
                                },
                            ]}
                        >
                            <Row>
                                <Col span={22}>
                                    <Input placeholder="请输入阿里云账户ID" />
                                </Col>
                                <Col span={2} style={{ textAlign: 'center', lineHeight: '30px' }}>
                                    <div
                                        className="annotation-icon"
                                        onClick={() => updateTip({ visible: true, title: '获取阿里云账户ID', pic: aliyunImg })}
                                        style={{ top: 6 }}
                                    ></div>
                                </Col>
                            </Row>
                        </Form.Item>
                    ) : null}

                    <Form.Item label="开启性能加速" name="jiasu" valuePropName="checked" className="quicken">
                        <Switch />
                    </Form.Item>
                    <p className="quicken-info">
                        容量型产品每个文件系统分配50MBps的吞吐量，如您需要更高的吞吐量，请选择性能加速服务。性能加速服务将产生额外的费用，请您谨慎选择。
                        <span onClick={showQuickenModal}>了解什么是性能加速</span>
                    </p>
                    <p className="free-warning">
                        <span>*</span>请注意，免费试用活动不包括性能加速，仅限容量型产品。
                    </p>
                    <div className="price">
                        <div>
                            容量型价格：<span>{priceSize ? GiBToGB(priceSize, '元/GiB/小时', '月') : '--'}</span>
                        </div>
                        <div>
                            性能加速价格：<span>{priceBandWidth ? MbpsToMBs(priceBandWidth, '元/Mbps/s/小时') : '--'}</span>
                        </div>
                    </div>
                    <Checkbox onChange={(e) => updateIsAgree(e.target.checked)}>
                        同意
                        <Link to="/console/documents/terms" target="_blank">
                            用户协议
                        </Link>
                    </Checkbox>
                </Form>
                <div className="form-footer">
                    <Button loading={fetching} type="primary" disabled={!isAgree || products[0]?.soldOut} onClick={() => form.submit()} className="btn-submit">
                        提交订单
                    </Button>
                </div>
            </CardNav>
            <Modal
                title={tip.title}
                footer={null}
                closeIcon={<CloseModalIcon />}
                onCancel={() => updateTip({ visible: false, title: '', pic: '' })}
                visible={tip.visible}
                width={1200}
                centered={true}
            >
                <img alt={tip.title} style={{ width: '100%', padding: 24 }} src={tip.pic} />
            </Modal>
            <ModifyQuickenModal />
        </div>
    );
};
