import { Action } from "../action";
import { FETCH } from "../constants/action-types.constants";

export class FetchAction {
    public static fetching(key: string | number) {
        return new Action(FETCH).put(key, { fetching: true });
    }

    public static succeed(key: string | number, message?: string) {
        return new Action(FETCH).put(key, {
            fetching: false,
            error: false,
            message,
            success: true,
        });
    }

    public static error(key: string | number, message?: string) {
        return new Action(FETCH).put(key, {
            fetching: false,
            error: true,
            message,
            success: false,
        })
    }

    public static clear(key: string | number) {
        return new Action(FETCH).put(key, {
            fetching: false,
            error: false,
            message: null,
            success: false,
        })
    }
}
