import { Button } from 'antd';
import { RefreshIcon } from './icons.component';
import './refresh-button.component.less';

export const RefreshButton = (props) => {
    return <Button
        type="ghost" icon={<RefreshIcon />}
        className={`btn-refresh ${props.className ? props.className : ''}`}
        onClick={() => props.onClick()}
        style={ props.style ? props.style : {}}
    />
}
