import { Modal, Form, Input } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_NAME_MODAL } from '../constants/modal.constants';
import { State } from '../../../interfaces/state.interface';
import { ModalAction } from '../../../redux/actions';
import { InstanceAction } from '../actions/instance.action';
import { Fetch } from '../../../interfaces';
import { UPDATE_INSTANCE_NAME } from '../constants/fetch.constants';
import { CloseModalIcon } from '../../components/icons.component';

export const UpdateNameModal = (props) => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const formLayout = { labelCol: { span: 5 }, wrapperCol: { span: 18, offset: 1 } };

    const { visible, ...instance } = useSelector<State, any>(
        state => state.modals[UPDATE_NAME_MODAL] ?? {} as any,
    );
    const { fetching } = useSelector<State, Fetch>(
        state => state.fetch[UPDATE_INSTANCE_NAME] ?? {} as any,
    );

    const submit = (values) => {
        dispatch(InstanceAction.updateInstanceName(instance.id, values.name));
    };
    useEffect(() => {
        form.setFieldsValue({ name: instance.name })
    }, [instance])
    return <Modal
        visible={visible}
        destroyOnClose={true}
        title="更改名称"
        className="udpate-name-modal"
        onCancel={() => dispatch(ModalAction.close(UPDATE_NAME_MODAL))}
        width={600}
        okText="更改名称"
        onOk={() => form.submit()}
        centered={true}
        closeIcon={<CloseModalIcon />}
        confirmLoading={fetching}
    >
        <Form
            form={form}
            colon={false}
            onFinish={(values) => submit(values)}
            labelAlign="right"
            preserve={false}
            layout="horizontal"
            {...formLayout}
        >
            <Form.Item
                name="name"
                label="文件系统名称"
                required={false}
                rules={[{ required: true, message: '请输入新的文件系统名称' }]}
                getValueFromEvent={(e)=>e.target.value.replace(/(^\s*)|(\s*$)/g,'')}
            >
                <Input placeholder="请输入新的文件系统名称" />
            </Form.Item>
        </Form>
    </Modal>
}