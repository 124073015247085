import React from 'react';
import { Route } from 'react-router-dom';
import { CommonLayout } from '../components/common-layout.component';
import { TicketsContainer } from './containers/tickets.container';
import { TicketDetailContainer } from './containers/ticket-detail.container';
import { TicketSubmitContainer } from './containers/ticket-submit.container';
import { GetBaidu } from '../components/baidu.statistics';

export const Ticket = (props) => {
    return <CommonLayout path={props.location.pathname}>
        <GetBaidu>
        <Route path="/console/tickets" exact component={TicketsContainer} />
        <Route path="/console/tickets/submit" exact component={TicketSubmitContainer} />
        <Route path="/console/tickets/ticket/:ticketId" component={TicketDetailContainer} />
        </GetBaidu>
    </CommonLayout>
}