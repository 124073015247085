import { Dispatch } from 'redux';
import { Action, Client, PaginationAction } from '../../../redux';
import { MESSAGE } from '../constants/action-types.constants';
import { GET_MESSAGES, GET_UNREADMESSAGES, GET_ALL_MESSAGES } from "../constants/fetch.constants";

export class MessageAction {

    private static client = new Client();

    public static getAllMessages(params: { type?: string }) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { messages } = await this.client.get('/apis/messages/all-message', {
                params: {
                    type: params.type ? encodeURIComponent(params.type) : undefined,
                },
                dispatch,
                fetch: GET_ALL_MESSAGES,
            });
            console.log(messages)
            dispatch(new Action(MESSAGE).put('messages', messages));
        }
    }

    public static getMessages(params: { page: number, size: number, read?: number, type?: string }) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { list, total } = await this.client.get('/apis/messages', {
                params: {
                    page: params.page,
                    size: params.size,
                    read: params.read,
                    type: params.type ? encodeURIComponent(params.type) : undefined,
                },
                dispatch,
                fetch: GET_MESSAGES,
            });
            dispatch(new Action(MESSAGE).put('messages', list));
            dispatch(PaginationAction.update('message', params.page, params.size, total, list.length));
        };
    }

    public static getMessage(messageId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { message } = await this.client.get(`/apis/messages/${messageId}`);
            dispatch(new Action(MESSAGE).put('message', message));
        };
    }

    public static readMessages(callback: () => void, messages?: string[]) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post('/apis/read-messages', {
                messages,
            }, {});
            callback();
            dispatch(this.getUnreadMessagesSum());
            dispatch(this.getUnreadMessage());
        };
    }

    public static deleteMessages(callback: () => void, messages?: string[]) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post('/apis/delete-messages', {
                messages,
            }, {});
            callback();
        };
    }

    public static getUnreadMessagesSum() {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { total } = await this.client.get('/apis/messages/sum-unread-messages');
            dispatch(new Action(MESSAGE).put('sumUnread', total));
        }
    }

    public static getUnreadMessage() {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { list } = await this.client.get('/apis/messages', {
                params: {
                    page: 1,
                    size: 3,
                    read: 0,
                },
                dispatch,
                fetch: GET_UNREADMESSAGES,
            })
            dispatch(new Action(MESSAGE).put('unreadMessages', list));
        }
    }
}
