import { Form, Input, Row, Col, Button, message as Message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State, Fetch } from '../../../interfaces';
import { SessionAction } from '../../../redux/actions';
import { LoginAction } from '../../login/actions/login.action';
import { RESET_PASSWORD } from '../../login/constants/fetch.constants';

export const ResetForm = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [remaining, updateRemaining] = useState(60);
    const { email } = useSelector<State, any>(
        state => state.session ?? {} as any,
    );
    const { fetching } = useSelector<State, Fetch>(
        state => state.fetch[RESET_PASSWORD] ?? {} as any,
    );

    useEffect(() => {
        form.setFieldsValue({ email });
    }, [email]);
    useEffect(() => {
        switch (remaining) {
            case 0:
                updateRemaining(60);
                break;
            case 60:
                break;
            default:
                setTimeout(() => {
                    updateRemaining((remaining: number) => remaining - 1)
                }, 1000);
                break;
        };
    }, [remaining]);

    const submit = (values) => {
        dispatch(LoginAction.resetPassword(values, () => {
            Message.success('修改密码成功，请重新登录');
            dispatch(SessionAction.signOut());
        }));
    };
    const sendSms = () => {
        form.validateFields(['email'])
            .then(values => {
                dispatch(LoginAction.sendResetSms(values.email));
                updateRemaining(59);
            })
            .catch(info => {
                console.log('validate error: ', info);
            });
    };

    return <Form
        form={form}
        layout="vertical"
        colon={false}
        style={{ width: 600 }}
        onFinish={(values) => submit(values)}
    >
        <Form.Item
            name="email"
            label="账号"
            initialValue={email}
        >
            <Input disabled />
        </Form.Item>
        <Row>
            <Col span={19}>
                <Form.Item
                    label="验证码"
                    name="verify"
                    required={false}
                    rules={[{ required: true, message: '请输入验证码' }]}
                >
                    <Input placeholder="请输入验证码" />
                </Form.Item>
            </Col>
            <Col span={5} style={{ paddingLeft: 16 }}>
                <Form.Item
                    label="&nbsp;"
                >
                    <Button onClick={() => sendSms()} disabled={remaining !== 60} style={{ height: 40 }}>
                        {remaining === 60 ? '发送验证码' : `${remaining}秒后重新发送`}
                    </Button>
                </Form.Item>
            </Col>
        </Row>
        <Form.Item
            label="新密码"
            name="password"
            required={false}
            rules={[{ required: true, message: '请输入密码' }]}
        >
            <Input placeholder="请输入新密码" />
        </Form.Item>
        <Form.Item
            label="确认密码"
            name="repassword"
            required={false}
            rules={[
                { required: true, message: '请再次输入密码' },
                ({ getFieldValue }) => ({
                    validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject('两次输入的密码不一致!');
                    },
                }),
            ]}
        >
            <Input type="password" placeholder="请再次输入密码" />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center', margin: '48px 0 120px' }}>
            <Button type="primary" onClick={() => form.submit()} loading={fetching}>修改</Button>
        </Form.Item>
    </Form>
}
