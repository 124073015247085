import { Route } from 'react-router-dom';
import { CommonLayout } from '../components/common-layout.component';
// import { BalanceContainer } from './containers/balance.container';
import { SettingContainer } from './containers/setting.container';
// import { BillingsContainer } from './containers/billings.container';
// import { OrdersContainer } from './containers/orders.container';
import { VoucherContainer } from './containers/voucher.container';
// import { PaymentContainer } from './containers/payment.container';
// import { OrderDetailContainer } from './containers/order-detail.container';
// import { PaidSuccessContainer} from './containers/paid-success.container';
import { NewBillContainer } from './containers/new.bill.container';
import { NewBillDetailContainer } from './containers/new.bill.detail.container';
import { NewPaymentContainer } from './containers/new.payment.container';
import { NewDosageContainer} from './containers/new.dosage.container';
import { NewInvoiceContainer } from './containers/new.invoice.container';
import { NewInvoiceTwoContainer } from './containers/new.invoice.two.container';
import { NewAddresssContainer } from './containers/new.address.container';
import { NewInvoiceListContainer } from './containers/new.invoicelist.container';
import { GetBaidu } from '../components/baidu.statistics';

export const Account = (props) => {
    return <CommonLayout path={props.location.pathname}>
        <GetBaidu>
            {/*新版-费用-- 账单 */}
            <Route path="/console/account/bill" exact component={NewBillContainer} />
            {/********账单详情 */}
            <Route path="/console/account/bill/:id" component={NewBillDetailContainer} />
            {/******* 用量详情 */}
            <Route path="/console/account/dosage/:id/:billingCycle/:name" component={NewDosageContainer} />
            {/*新版-费用-- 付款 */}
            <Route path="/console/account/payment/:id" component={NewPaymentContainer} />
            {/*新版-费用-- 代金券 */}
            <Route path="/console/account/voucher" exact component={VoucherContainer} />
            {/*新版-费用-- 发票 */}
            <Route path="/console/account/invoice" exact component={NewInvoiceContainer} />
            <Route path="/console/account/invoice/list" exact component={NewInvoiceListContainer} />
            <Route path="/console/account/invoice/two/:bills" component={NewInvoiceTwoContainer} />
            <Route path="/console/account/invoice/address" exact component={NewAddresssContainer} />
            {/* 费用--余额 */}
            {/* <Route path="/console/account/balance" exact component={BalanceContainer} /> */}
            {/* 账户设置 */}
            <Route path="/console/account/setting" exact component={SettingContainer} />
            {/* 费用--费用账单 */}
            {/* <Route path="/console/account/billing" exact component={BillingsContainer} /> */}
            {/* 费用--订单列表 */}
            {/* <Route path="/console/account/order" exact component={OrdersContainer} /> */}
            {/* 取消订单详情 */}
            {/* <Route path="/console/account/order/:orderId" component={OrderDetailContainer} /> */}
            {/* 费用-代金券管理 */}
            {/* <Route path="/console/account/voucher" exact component={VoucherContainer} /> */}
            {/* 订单支付 */}
            {/* <Route path="/console/account/payment/:orderId" component={PaymentContainer} /> */}
            {/* 订单支付成功 */}
            {/* <Route path="/console/account/paid-success/:instanceId" exact component={PaidSuccessContainer} /> */}
        </GetBaidu>
    </CommonLayout>
}
