import { message } from 'antd';
import { Dispatch } from 'redux';
import { State } from '../../../interfaces';
import { Client, Action, PaginationAction, ModalAction } from '../../../redux';
import { INVOICE } from '../constants/action-types.constants';
import { GET_INVOICE, GET_INVOICE_MAKE, GET_INVOICE_INFO, GET_INVOICE_ADDRESS, PUT_INVOICE_INFO, SET_INVOICE_ADDRESS, LIST_INVOICE_ADDRESS, PUT_INVOICE_FORM } from '../constants/fetch.constants';
import { INVOICE_ADDRESS_MODAL } from './../constants/modal.constants'

export class InvoiceAction {
    private static client = new Client();
    // 开票记录api
    public static getInvoiceList(page: number, size: number) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            const { list, total } = await this.client.get('/apis/invoice/record/get', {
                params: { page, size },
                dispatch,
                fetch: GET_INVOICE,
            });

            dispatch(new Action(INVOICE).put('invoices', list));
            dispatch(PaginationAction.update('invoice', page, size, total, list.length));
        }
    }
    // 可开发票金额
    public static getMakeMoney() {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            const { invoicedSum, uninvoicedSum } = await this.client.get('/apis/bills/invoices/summary', {
                dispatch,
                fetch: GET_INVOICE_MAKE,
            });
            dispatch(new Action(INVOICE).put('invo_amount', { available: uninvoicedSum, used: invoicedSum }));
        }
    }
    // 发票首页 发票信息 默认信息
    public static getInvoiceInfo() {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            const list = await this.client.get('/apis/invoice/detail/get', {
                dispatch,
                fetch: GET_INVOICE_INFO,
            });
            dispatch(new Action(INVOICE).put('invo_info', list));
        }
    }
    // 发票信息  添加
    public static getInvoiceInfoAdd(data: Record<string, any>, callback?: () => void) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            await this.client.post('/apis/invoice/detail/add', {
                ...data
            }, {
                dispatch,
                fetch: PUT_INVOICE_INFO,
            });
            await dispatch(this.getInvoiceInfo());
            callback && callback();
        }
    }
    // 发票信息  更新
    public static getInvoiceInfoUpdata(id: string, data: Record<string, any>, callback?: () => void) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            await this.client.post(`/apis/invoice/detail/update/${id}`, {
                ...data
            }, {
                dispatch,
                fetch: PUT_INVOICE_INFO,
            });
            await dispatch(this.getInvoiceInfo());
            callback && callback();
        }
    }
    // 默认邮寄地址
    public static getInvoiceAddress() {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            const list = await this.client.get('/apis/invoice/address/get', {
                dispatch,
                fetch: GET_INVOICE_ADDRESS,
            })
            dispatch(new Action(INVOICE).put('invo_address', list ? list : {}));
            // console.log(state())
        }
    }
    // 默认邮寄地址list
    public static getInvoiceAddressList() {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            const list = await this.client.get('/apis/invoice/address/list', {
                dispatch,
                fetch: LIST_INVOICE_ADDRESS,
            });
            dispatch(new Action(INVOICE).put('address_list', list));
            // console.log(state())
        }
    }
    // 添加邮寄地址
    public static addInvoiceAddress(data) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            await this.client.post('/apis/invoice/address/add', {
                ...data
            }, {
                dispatch,
                fetch: SET_INVOICE_ADDRESS,
            })
            dispatch(this.getInvoiceAddressList());
            message.success('添加成功');
            dispatch(ModalAction.close(INVOICE_ADDRESS_MODAL));
        }
    }
    // 修改邮寄地址
    public static updataInvoiceAddress(id: string, data) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            await this.client.post(`/apis/invoice/address/update/${id}`, {
                ...data
            }, {
                dispatch,
                fetch: SET_INVOICE_ADDRESS,
            })
            dispatch(this.getInvoiceAddressList());
            message.success('修改成功');
            dispatch(ModalAction.close(INVOICE_ADDRESS_MODAL));
        }
    }
    // 修改邮寄地址
    public static delInvoiceAddress(id: string, callback: () => void) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            await this.client.post(`/apis/invoice/address/del//${id}`, {}, {
                dispatch,
                fetch: SET_INVOICE_ADDRESS,
            })
            dispatch(this.getInvoiceAddressList());
            callback()
            dispatch(ModalAction.close(INVOICE_ADDRESS_MODAL));
        }
    }
    // 开票记录列表   增加 - 申请开票
    public static addInvoiceRecord(data, callback: () => void) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            await this.client.post(`/apis/invoice/record/add`, {
                ...data
            }, {
                dispatch,
                fetch: PUT_INVOICE_FORM,
            })
            callback()
        }
    }

}
