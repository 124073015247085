import React from 'react';
import { Route } from 'react-router-dom';
import { CommonLayout } from '../components/common-layout.component';
import { InstancesContainer } from './containers/instances.container';
import { InstanceDetailContainer } from './containers/instance-detail.container';
import { CreateInstanceContainer } from './containers/create-instance.container';
// import { CreateInstanceContainerold } from './containers/create-instance.container_old';
import { RenewInstanceContainer } from './containers/renew-instance.container';
import { UpgradeBandwidthContainer } from './containers/upgrade-bandwidth.container';
import { UpgradeCapacityContainer } from './containers/upgrade-capacity.container';
import { ModifyTypeContainer } from './containers/modify-type.container';
import { GetBaidu } from '../components/baidu.statistics';

export const Instance = (props) => {
    return <CommonLayout path={props.location.pathname}>
        <GetBaidu>
        <Route path="/console/instances" exact component={InstancesContainer} />
        <Route path="/console/instances/create-instance" exact component={CreateInstanceContainer} />
        {/* <Route path="/console/instances/create-instance/old" exact component={CreateInstanceContainerold} /> */}
        <Route path="/console/instances/renew-instance/:instanceId" exact component={RenewInstanceContainer} />
        <Route path="/console/instances/upgrade-bandwidth/:instanceId" exact component={UpgradeBandwidthContainer } />
        <Route path="/console/instances/upgrade-capacity/:instanceId" exact component={UpgradeCapacityContainer} />
        <Route path="/console/instances/modify-type/:instanceId" exact component={ModifyTypeContainer} />
        <Route path="/console/instances/detail/:instanceId" component={InstanceDetailContainer} />
        </GetBaidu>
    </CommonLayout>
}
