export enum MonitorItems {
    'yrfs_x_quota_read_iops' = '读IOPS',
    'yrfs_x_quota_write_iops' = '写IOPS',
    'yrfs_x_quota_read_bandwidth' = '读带宽',
    'yrfs_x_quota_write_bandwidth' = '写带宽',
    'yrfs_x_inode_used' = 'inode（已用）',
    // 'yrfs_x_inode_limit' = 'inode（总）',
    'yrfs_x_quota_used' = '容量（已用）',
    // 'yrfs_x_quota_limit' = '容量（总）',
}

export enum MonitorItemTitles {
    'capacity' = '容量',
    'inode' = 'inode',
    'iops' = 'iops',
    'bandwidth' = '带宽',
}
