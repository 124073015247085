import { combineReducers as combine } from 'redux';

function getPrototypeChain(obj) {
    const protoChain: any[] = [];

    while (obj = Object.getPrototypeOf(obj)) {
        protoChain.push(obj);
    }
    return protoChain;
}

export function combineReducers(reducerMaps) {
    const reducers = {};
    Object.keys(reducerMaps).forEach(key => {
        const cache = new Map<string, any>();
        const chains = getPrototypeChain(reducerMaps[key]);
        reducers[key] = (state = reducerMaps[key].model, action) => {
            if (action.type !== reducerMaps[key].type) {
                return state;
            }
            let chain = cache.get(`${action.type}__${action.verb}`);
            if (!chain) {
                chain = chains.find(c => !!c[action.verb]);
                cache.set(`${action.type}__${action.verb}`, chain);
            }
            if (!chain) {
                return state;
            }
            return chain[action.verb].call(reducerMaps[key], state, action);
        }
    });

    return combine({ ...reducers });
}
