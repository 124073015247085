import { Button, Table, message, Space } from 'antd';
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Fetch, State, Modal as ModalInterface } from '../../../interfaces'
import { ModalAction } from '../../../redux'
import { InvoiceAction } from '../actions'
import { PageTypography } from '../../components/page.typography';
import { CardNav } from '../../components/card.navigation'
import { INVOICE_ADDRESS_MODAL } from '../constants/modal.constants'
import { LIST_INVOICE_ADDRESS } from '../constants/fetch.constants';
import { CreateIcon, CreateDisabledIcon } from '../../components/icons.component';
import { InvoiceAddress } from '../modals/invoice-addres'
import { RefreshButton } from '../../components/refresh-button.component';
import './new.address.container.less';

export const NewAddresssContainer = () => {
    const dispatch = useDispatch();
    const { visible } = useSelector<State, ModalInterface>((state) => state.modals[INVOICE_ADDRESS_MODAL] ?? {} as any);
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[LIST_INVOICE_ADDRESS] ?? {} as any)
    const address_list = useSelector<State, any>(state => state.invoice.address_list ?? [] as any);

    const removeAddress = (id: string) => {
        dispatch(InvoiceAction.delInvoiceAddress(id, () => {
            message.success('删除成功');
        }))
    }

    useEffect(() => {
        dispatch(InvoiceAction.getInvoiceAddressList());
    }, []);
    return (
        <div className="address-container">
            <PageTypography title='发票'>您可在此页面申请和管理发票信息。</PageTypography>
            <CardNav title='邮寄地址管理' link='/console/account/invoice'>
                <Space>
                    <Button
                        className="btn-primary-icon"
                        type="primary"
                        disabled={address_list.length >= 5}
                        icon={address_list.length >= 5 ? <CreateDisabledIcon /> : <CreateIcon />}
                        onClick={() => { dispatch(ModalAction.open(INVOICE_ADDRESS_MODAL)) }}
                    >
                        新增地址
                    </Button>
                    <RefreshButton onClick={() => { dispatch(InvoiceAction.getInvoiceAddressList()) }} />
                </Space>
                <Table
                    className='address-table'
                    dataSource={address_list}
                    pagination={false}
                    rowKey='id'
                    loading={fetching}
                    locale={{
                        emptyText: () => <span className="empty-color">--暂无邮寄地址--</span>
                    }}
                >
                    <Table.Column title='收件人姓名' dataIndex='addressee'></Table.Column>
                    <Table.Column title='联系电话' dataIndex='telephone'></Table.Column>
                    <Table.Column title='详细地址' dataIndex='detailedAddress' render={(text, record: Record<string, any>) => (
                        <span>{JSON.parse(record.area).join('') + text}</span>
                    )}></Table.Column>
                    <Table.Column title='邮编' dataIndex='postcode' render={(text) => text ?? '--'}></Table.Column>
                    <Table.Column title="" dataIndex='default' render={(text, record: Record<string, any>) => (
                        <div>
                            {
                                text ?
                                    <span className="default-btn">默认地址</span>
                                    :
                                    <span className="set-default-btn" onClick={() => { dispatch(InvoiceAction.updataInvoiceAddress(record.id, { default: 1 })) }}>设为默认</span>
                            }
                        </div>
                    )}></Table.Column>
                    <Table.Column title='操作' width={130} render={(_, record: Record<string, any>) => (
                        <div className="address_config">
                            <Button type="link" onClick={() => { dispatch(ModalAction.open(INVOICE_ADDRESS_MODAL, { isUpdata: true, ...record })) }}>修改</Button>
                            <Button type="link" onClick={() => { removeAddress(record.id) }}>删除</Button>
                        </div>
                    )}></Table.Column>
                </Table>
            </CardNav>
            {visible && <InvoiceAddress />}
        </div>
    )
}