import { Form, Radio, Select, Input, Checkbox } from 'antd'
import React, { useState, useImperativeHandle, useEffect } from 'react'
import { useUpdateEffect } from 'react-use';
import { State } from './../../../interfaces'
import { Link } from 'react-router-dom';
import { createTitleEnt, createCodeReg, createBankeReg, createAccountReg, createRegisteredAddress, createPhoneReg } from './../../../utils'
import { useSelector } from 'react-redux';
import './invoice-form.less'

interface Iprops {
    form: any;
    showCheck: boolean;
}
export const InvoiceForm = React.forwardRef((props: Iprops, ref) => {
    const { form, showCheck } = props;
    const { invo_info } = useSelector<State, any>(state => state.invoice);
    const [titleType, setTitleType] = useState('personal'); // 发票抬头
    const [invoiceType, setInvoiceType] = useState('vat_invoice'); // 发票类型
    useImperativeHandle(ref, () => ({ titleType, invoiceType })); // 用于父元素获取
    // 判断
    const isRequire = (type = 'invoice') => {
        if (type === 'invoice') {
            return titleType === 'enterprise' && invoiceType === 'commercial_invoice' // 抬头：企业 && 发票：专票
        } else {
            return titleType === 'enterprise'// 抬头：企业 
        }
    }
    // 判断
    const isChoosable = (type = 'invoice') => {
        if (type === 'invoice') {
            return isRequire() ? '（必填）' : '（选填）'  // 抬头：企业 && 发票：专票
        } else {
            return isRequire('enterprise') ? '（必填）' : '（选填）' // 抬头：企业 
        }
    }
    // 首次 判断如果是企业｜｜事业单位 设置发票抬头
    useEffect(() => {
        if (invo_info) {
            if (invo_info.titleType === 'personal') {
                setTimeout(() => {
                    form.setFieldsValue({ title_per: invo_info?.title ?? '个人' })
                    setInvoiceType('vat_invoice')
                })
            }
            if (invo_info.titleType !== 'personal') {
                setTimeout(() => {
                    form.setFieldsValue({ title_ent: invo_info?.title })
                    form.setFieldsValue({ title_per: '个人' })
                    setInvoiceType(invo_info.invoiceType)
                })
            }
            setTitleType(invo_info.titleType);
        }
    }, [invo_info])
    // 除去首次后 触发调用
    useUpdateEffect(() => {
        if (!isRequire())
            form.validateFields(['bank', 'account', 'registeredAddress', 'registeredTelephone']);
        if (!isRequire('enterprise'))
            form.validateFields(['code']);
    }, [titleType, invoiceType]);
    useUpdateEffect(() => {
        if (titleType === 'personal') {
            setInvoiceType('vat_invoice');
        }
    }, [titleType])
    return (
        <>
            <Form.Item label='抬头类型' required>
                <Radio.Group onChange={(e) => { setTitleType(e.target.value) }} value={titleType}>
                    <Radio value='personal'>个人</Radio>
                    <Radio value='enterprise' disabled={invo_info.content === 'institutions'}>企业</Radio>
                    <Radio value='institutions' disabled={invo_info.content === 'enterprise'}>事业单位</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="发票类型" required>
                <Select value={invoiceType} onChange={(val) => { setInvoiceType(val) }}>
                    <Select.Option value="vat_invoice">增值税普通发票</Select.Option>
                    <Select.Option value="commercial_invoice" disabled={titleType === 'personal'}>增值税专用发票</Select.Option>
                </Select>
            </Form.Item>
            {titleType === 'personal' ?
                <Form.Item label="发票抬头" name='title_per' rules={[
                    { validator: createTitleEnt, message: '不支持全英文/数字的抬头名称' },
                ]}
                    getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
                >
                    <Input placeholder='请输入发票抬头' />
                </Form.Item>
                :
                <>
                    <Form.Item label="发票抬头" name='title_ent' validateFirst={true} rules={[
                        { required: true, message: '填写信息有误，请核实后填写' },
                        { validator: createTitleEnt, message: '不支持全英文/数字的抬头名称' },
                    ]}
                        getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
                    >
                        <Input placeholder='请输入单位名称' />
                    </Form.Item>
                    <Form.Item label="统一社会信用代码" name='code' validateFirst={true} rules={[
                        { required: isRequire('enterprise'), message: '请输入统一社会信用代码' },
                        { validator: createCodeReg, message: '请仔细核对，输入正确的统一社会信用代码' }
                    ]}
                        getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
                    >
                        <Input placeholder={isChoosable('enterprise') + '请输入统一社会信用代码'} />
                    </Form.Item>
                    <Form.Item label="开户银行名称" name='bank' validateFirst={true} rules={[
                        { required: isRequire(), message: '请输入开户银行名称' },
                        { validator: createBankeReg, message: '开户行名称长度为1-30位' }
                    ]}
                        getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
                    >
                        <Input placeholder={isChoosable() + '请输入开户银行名称'} />
                    </Form.Item>
                    <Form.Item label="基本开户账号" name='account' validateFirst={true} rules={[
                        { required: isRequire(), message: '请输入基本开户账号' },
                        { validator: createAccountReg, message: '基本户开户账号长度为6至32位' },
                    ]}
                        getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
                    >
                        <Input placeholder={isChoosable() + '请输入基本开户账号'} />
                    </Form.Item>
                    <Form.Item label="注册场所地址" name='registeredAddress' validateFirst={true} rules={[
                        { required: isRequire(), message: '请输入注册场所地址' },
                        { validator: createRegisteredAddress, message: '注册场所地址最少为3个字符' },
                    ]}
                        getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
                    >
                        <Input placeholder={isChoosable() + '请输入注册场所地址'} />
                    </Form.Item>
                    <Form.Item label="注册固定电话" name='registeredTelephone' validateFirst={true} rules={[
                        { required: isRequire(), message: '请输入注册固定电话' },
                        { validator: createPhoneReg, message: '注册固定电话长度为5到17位' },
                    ]}>
                        <Input placeholder={isChoosable() + '请输入注册固定电话'} />
                    </Form.Item>
                    {
                        showCheck && isRequire('enterprise') &&  // 只有在企业时候才会有
                        <Form.Item name="agree" valuePropName="checked" wrapperCol={{ offset: 1 }} rules={[
                            { validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('请详细阅读并勾选《个人开具企业发票承诺条款》')) }
                        ]}>
                            <Checkbox defaultChecked={Boolean(invo_info.agree)}>
                                同意<Link to="/console/documents/invoice" target="_blank">《个人开具企业发票承诺条款》</Link>
                            </Checkbox>
                        </Form.Item>
                    }
                </>
            }
        </>
    )
})