import FileSaver from "file-saver";

interface HeaderType {
    label: string
    key: string
};

interface DataType {
    [key: string]: any
};
/**
     * 导出CSV文件
     * @param {*} header   表头信息 格式为[{lable:'名称', key: '对应key'}] 数组对象
     * @param {*} data     导出文件数据 格式为[{对应key: 'xxxx'}] 数组对象
     * @param {*} data     导出文件名称 string
     * @param {*} data     导出文件类型 默认csv  可选【csv，xlsx，log，txt】
*/
export const deriveCsv = (header: HeaderType[], data: DataType[] = [], fileName: string = 'fileName', type: string = 'csv') => {
    const prefix = "\uFEFF";
    const csvString = [
        header.map(head => head.label), // 表头
        ...data.map((item) =>
            header.map((head) => item[head.key])
        )
    ].join('\n');
    const blob = new Blob([prefix + csvString], { type: 'text/csv; charset = utf-8' });
    FileSaver.saveAs(blob, `${fileName + Date.now()}.${type}`);
}