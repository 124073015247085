import React from 'react';
import { Route } from 'react-router-dom';
import { CommonLayout } from '../components/common-layout.component';
import { ExperienceContainer } from './containers/experience.applying.container';
import { ExperienceFeedbackContainer } from './containers/experience.feedback.container';
import { GetBaidu } from '../components/baidu.statistics';

export const Experience = (props) => {
    return <CommonLayout path={props.location.pathname}>
        <GetBaidu>
        <Route path="/console/experience" exact component={ExperienceContainer} />
        <Route path="/console/experience/feedback" exact component={ExperienceFeedbackContainer} />
        </GetBaidu>
    </CommonLayout>
}