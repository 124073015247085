import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { State, Modal as ModalInterface, Fetch } from '../../../interfaces';
import { ModalAction } from '../../../redux';
import { InstanceAction } from '../actions/instance.action';
import { MODIFY_QUICKEN_MODAL } from '../constants/modal.constants';
import { MODIFY_NETWORK_QUICKEN } from '../constants/fetch.constants';
import './modify-quicken.modal.less'
import { CloseModalIcon } from '../../components/icons.component';

export const ModifyQuickenModal = () => {
    const dispatch = useDispatch();
    const { visible, isSubmit, ...instance } = useSelector<State, ModalInterface>(
        state => state.modals[MODIFY_QUICKEN_MODAL] ?? {} as any,
    );
    const { fetching } = useSelector<State, Fetch>(
        state => state.fetch[MODIFY_NETWORK_QUICKEN] ?? {} as any,
    );
    const submit = () => {
        if (isSubmit) {
            dispatch(ModalAction.close(MODIFY_QUICKEN_MODAL)); // 关闭性能加速
        } else {
            dispatch(InstanceAction.setPerformanceAtion(instance)); // 开启性能加速
        }
    };


    return <Modal
        visible={visible}
        title="性能加速"
        className="modafiy-quicken"
        onCancel={() => dispatch(ModalAction.close(MODIFY_QUICKEN_MODAL))}
        destroyOnClose={true}
        width={700}
        centered={true}
        closeIcon={<CloseModalIcon />}
        okText={isSubmit ? '我了解了' : '一键解锁极限性能'}
        onOk={() => submit()}
        confirmLoading={fetching}
    >
        <div className="modafiy-quicken-box">
            <div className='quicken-title'>什么是性能加速</div>
            <p>性能加速是焱融SaaS文件存储为用户提供的一项增值服务，开启性能加速后系统将不再对您的文件系统性能做限制，您可解锁文件系统的极限性能。您最高可获得1000MB/s的吞吐量来支撑您的工作负载。
                性能加速服务将产生额外的费用，请您谨慎开启。</p>
            <div className='quicken-title'>计费方式</div>
            <span>计费方式：按量计费</span>
            <p>焱融SaaS文件存储提供的性能加速采用更灵活的计费方式，系统会监控您实际使用的性能，根据您每小时使用的平均性能收费，避免性能浪费的同时为您节约大量成本。</p>
            <span>计费周期：小时</span>
            <span>价格：0.5元/MB/s/小时</span>
            <span>计费公式=（您每小时使用的平均性能-50MB/s）×单价</span>
            <p>假设您在11:00-12:00之间使用的平均性能为300MB/s,那么您在11:00-12:00产生的费用即为0.5×（300-50）=125元。如果您每小时使用的平均性能不足50MB/s时，性能加速服务不收取费用。</p>
        </div>
    </Modal>
}
